import { ChangeSetAuthorityType, ChangeSetType } from '~/db_types';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { ChangeSetAuthorityTypeDisplay, ChangeSetStatusDisplay, ChangeSetTypeDisplay, DocumentTypeDisplay } from '~/nasa_ui/types';
import { buildChangeSetStatusIcon } from '../../ui/buildIconAsString';
import { addAsBuiltNumberDisplay, addAuthorityCodeDisplay, addAuthorityNumberDisplay, addDateDisplays, addDescriptionDisplay, addDrawingNumberDisplay, addFailureModeDisplay, addItemNumberDisplay, addLotNumberDisplay, addSerialNumberDisplay, addSideDisplay, addSizeDisplay, addStatusIconDisplay, addSubTypeDisplay, addUserDisplays } from '../displayMappers';
import { booleanIconDisplay, contactDisplay } from '../displays';
import { markdownToHtml } from '../markdownToHtml';
import { transformDocuments, transformItemInstances, transformUserGroups } from '../transformEntityToTableFormat';
import { groupNameDisplay } from '../user';
// GQL version
export const transformChangeSets = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addAuthorityCodeDisplay)
        .map(addAuthorityNumberDisplay)
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addDrawingNumberDisplay)
        .map(addFailureModeDisplay)
        .map(addItemNumberDisplay)
        .map(addLotNumberDisplay)
        .map(addSerialNumberDisplay)
        .map(addSideDisplay)
        .map(addSizeDisplay)
        .map(addStatusIconDisplay)
        .map(addSubTypeDisplay(ChangeSetTypeDisplay))
        .map(addUserDisplays)
        .map((item) => {
        const _item = { ...item };
        _item._changeSetAuthorities = transformChangeSetAuthorities(item.changeSetAuthorities?.nodes || []);
        _item._createdByUser = contactDisplay(item.createdByUser);
        _item._document = item.document ? transformDocuments([item.document])[0] : null;
        _item._documentSubType = item.document?.subType ? DocumentTypeDisplay.get(item.document.subType) : DEFAULT_DASH;
        _item._hardwareLevel = item.hardwareLevel ? item.hardwareLevel.replace('_', ' ') : DEFAULT_DASH;
        _item._itemInstance = item.itemInstance ? transformItemInstances([_item.itemInstance])[0] : null;
        return _item;
    });
};
export const transformChangeSetAuthorityResponses = (items) => {
    return items
        .map(addDateDisplays)
        .map(addUserDisplays)
        .map((item) => {
        const _item = { ...item };
        _item._authorityType = _item.authorityType
            ? ChangeSetAuthorityTypeDisplay.get(_item.authorityType) || DEFAULT_DASH
            : DEFAULT_DASH;
        _item._status = _item.status ? ChangeSetStatusDisplay.get(_item.status) || DEFAULT_DASH : DEFAULT_DASH;
        return _item;
    });
};
// GQL version
export const transformChangeSetAuthorities = (items) => {
    return items
        .map(addSubTypeDisplay(ChangeSetTypeDisplay))
        .map(addUserDisplays)
        .map(addDateDisplays)
        .map((item) => {
        const _item = { ...item };
        _item._statusIcon = buildChangeSetStatusIcon(_item.status);
        return _item;
    })
        .map((item) => {
        const _item = { ...item };
        let mipExplanation = _item.manualInspectionPointExplanation || DEFAULT_DASH;
        let mipType = _item.manualInspectionPointType || DEFAULT_DASH;
        const _computedCommentText = markdownToHtml(_item.computedCommentText) || DEFAULT_DASH;
        let _authorityType = ChangeSetAuthorityTypeDisplay.get(_item.authorityType) || DEFAULT_DASH;
        if (_item.authorityType === ChangeSetAuthorityType.DEFAULT) {
            _authorityType = DEFAULT_DASH;
        }
        // if it's not a work order step authority, show N/A
        if (_item.subType !== ChangeSetType.WORK_ORDER_STEP) {
            _authorityType = NOT_APPLICABLE;
            mipType = NOT_APPLICABLE;
            mipExplanation = NOT_APPLICABLE;
        }
        _item._authorityType = _authorityType;
        _item._computedCommentText = _computedCommentText;
        _item._manualInspectionPointExplanation = mipExplanation;
        _item._manualInspectionPointType = mipType;
        _item._requiresComment = booleanIconDisplay(_item.requiresComment);
        _item._requiresQualityControlStamp = booleanIconDisplay(_item.requiresQualityControlStamp);
        return _item;
    })
        .map((item) => {
        const _item = { ...item };
        if (!_item.authorizedByUser) {
            _item._authorizedBy = DEFAULT_DASH;
            return _item;
        }
        if (_item.authorizedByUser?.firstName && _item.authorizedByUser?.lastName) {
            _item._authorizedBy = `${_item.authorizedByUser.firstName} ${_item.authorizedByUser.lastName}`;
            return _item;
        }
        if (_item.authorizedByUser.auid) {
            _item._authorizedBy = _item.authorizedByUser.auid;
        }
        return _item;
    })
        .map((item) => {
        const _item = { ...item };
        _item._userGroup = _item.userGroup ? transformUserGroups([_item.userGroup])[0] : null;
        _item._userGroupName = groupNameDisplay(_item);
        return _item;
    });
};
