import { EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
const getBlurbAboutSearch = (displayEntityType) => `Search, filter, view details of a single ${displayEntityType || ''}.`;
const getBlurbAboutManage = (displayEntityType) => `View details of a single ${displayEntityType || ''}.`;
export const ChangeSetSharedRoutes = [
    {
        path: `/approval_templates`,
        redirect: {
            name: 'ChangeSetTemplatesManageView'
        },
        meta: {
            displayName: 'Approval template manage',
            description: 'Aproval template manage',
            permissions: [UserPermission.CHANGE_SET_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/approval_templates/manage`,
        name: 'ChangeSetTemplatesManageView',
        component: () => import(
        /* webpackChunkName: "ChangeSets" */ '~/nasa_ui/views/ChangeSetTemplatesManageView/ChangeSetTemplatesManageView.vue'),
        meta: {
            displayName: `Approval Template manage`,
            description: 'Allows admin users to manage the approval queue for change set backed fields.',
            permissions: [UserPermission.CHANGE_SET_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CHANGE_SET}/search`,
        name: 'ChangeSetsSearchView',
        component: () => import(/* webpackChunkName: "ChangeSets" */ '../views/ChangeSetsSearchView/ChangeSetsSearchView.vue'),
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.CHANGE_SET)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.CHANGE_SET)),
            permissions: [UserPermission.CHANGE_SET_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CHANGE_SET}`,
        redirect: {
            name: 'ChangeSetsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.CHANGE_SET)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.CHANGE_SET)),
            permissions: [UserPermission.CHANGE_SET_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CHANGE_SET}/manage`,
        redirect: {
            name: 'ChangeSetsSearchView'
        },
        meta: {
            displayName: `${EntityTypeSingularDisplay.get(EntityType.CHANGE_SET)} search`,
            description: getBlurbAboutSearch(EntityTypeSingularDisplay.get(EntityType.CHANGE_SET)),
            permissions: [UserPermission.CHANGE_SET_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.CHANGE_SET}/manage/:id`,
        name: 'ChangeSetsManageView',
        component: () => import(/* webpackChunkName: "ChangeSets" */ '../views/ChangeSetsManageView/ChangeSetsManageView.vue'),
        meta: {
            displayName: 'Change Sets manage',
            description: getBlurbAboutManage(EntityTypeSingularDisplay.get(EntityType.CHANGE_SET)),
            permissions: [UserPermission.CHANGE_SET_TEMPLATE_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
