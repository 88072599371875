import { ChangeLogType, ChangeSetAuthorityType, ChangeSetStatus, ChangeSetType, DocumentType } from '~/db_types';
export const ChangeSetAuthorityTypeDisplay = new Map([
    [ChangeSetAuthorityType.DEFAULT, 'Default'],
    [ChangeSetAuthorityType.GOVERNMENT_MANUAL_INSPECTION_POINT, 'GMIP'],
    [ChangeSetAuthorityType.MANUAL_INSPECTION_POINT, 'MIP']
]);
export const ChangeSetTypeDisplay = new Map([
    [ChangeSetType.ACCESS_REQUEST, 'Access request'],
    [ChangeSetType.COSMIC_ACTION, 'Action'],
    [ChangeSetType.C_DISCREPANCY_REPORT_LINE_ITEM, 'cDR line item'],
    [ChangeSetType.C_EASY_TRANSFER_LINE_ITEM, 'cEZT line item'],
    [ChangeSetType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM_LINE_ITEM, 'cFRACAS line item'],
    [ChangeSetType.C_RCA_LINE_ITEM, 'CAP task'],
    [ChangeSetType.C_TPS_LINE_ITEM, 'cTPS line item'],
    [ChangeSetType.C_WORKFLOW_LINE_ITEM, 'Task'],
    [ChangeSetType.CONFIGURATION_CHANGE_ORDER_LINE_ITEM, 'CCO line item'],
    [ChangeSetType.COSMIC_DOCUMENT_INITIALIZATION, 'Document Initialization'],
    [ChangeSetType.DOCUMENT_FLAG_AS_DUPLICATE, 'Document marked as duplicate'],
    [ChangeSetType.DISCREPANCY_REPORT_LINE_ITEM, 'DR line item'],
    [ChangeSetType.DELIVERY_ORDER_LINE_ITEM, 'DO line item'],
    [ChangeSetType.EASY_TRANSFER_LINE_ITEM, 'EZT line item'],
    [ChangeSetType.ENGINEERING_CHANGE_LINE_ITEM, 'EC line item'],
    [ChangeSetType.FAILURE_INVESTIGATION_ANOMALY_REPORT_LINE_ITEM, 'FAIR line item'],
    [ChangeSetType.IRREGULAR_PARTS_AUTHORIZATION_REQUEST_LINE_ITEM, 'IPAR line item'],
    [ChangeSetType.ITEM_DRAWING_CODES_CHANGE, 'Drawing: Asset & Usage Classification code change'],
    [ChangeSetType.ITEM_DRAWING_VALID_SIZES_CHANGE, 'Drawing: Valid sizes change'],
    [ChangeSetType.ITEM_FOR_INVESTIGATION_LINE_ITEM, 'Audit: Item under inventory investigation'],
    [ChangeSetType.ITEM_INSTANCE_CONFIGURATION_CHANGE, 'Instance: Configuration change (Dash number roll)'],
    [ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL, 'Inv: Assembly install change'],
    [ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE, 'Inv: Assembly removal change'],
    [ChangeSetType.ITEM_INSTANCE_INVENTORY_CLASS_CHANGE, 'Inv: Class change'],
    [ChangeSetType.ITEM_INSTANCE_INVENTORY_COST_CHANGE, 'Inv: Cost change'],
    [ChangeSetType.ITEM_INSTANCE_INVENTORY_PROJECT_CODE_CHANGE, 'Inv: Project code change'],
    [ChangeSetType.ITEM_INSTANCE_LIMITED_LIFE_CHANGE, 'Instance: Limited life change'],
    [ChangeSetType.ITEM_INSTANCE_ON_ORBIT_FAILURE, 'Instance: On Orbit Failure'],
    [ChangeSetType.ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE, 'Instance: Serial or Lot Number change'],
    [ChangeSetType.ITEM_MASTER_APPROVAL_CHANGE, 'Master: Approval change'],
    [ChangeSetType.ITEM_MASTER_EQUIPMENT_GROUP_CHANGE, 'Master: Equipment group change'],
    [ChangeSetType.ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE, 'Master: FSC or Property class change'],
    [ChangeSetType.ITEM_MASTER_INITIALIZATION, 'Master: Initialization'],
    [ChangeSetType.ITEM_MASTER_INVENTORY_METHOD_CHANGE, 'Master: Inventory method change'],
    [ChangeSetType.ITEM_MASTER_LIMITED_LIFE_CHANGE, 'Master: Limited life defaults change'],
    [ChangeSetType.ITEM_MASTER_REFURB_CHANGE, 'Master: Expend/Refurb change'],
    [ChangeSetType.MATERIAL_USAGE_AGREEMENT_LINE_ITEM, 'MUA line item'],
    [ChangeSetType.NASA_SPACE_PART_AUTHORIZATION_REQUEST_LINE_ITEM, 'NSPAR line item'],
    [ChangeSetType.OTHER_DOCUMENT_LINE_ITEM, 'OD line item'],
    [ChangeSetType.OVER_DUE_TAG_LINE_ITEM, 'ODT line item'],
    [ChangeSetType.PROBLEM_REPORTING_AND_CORRECTIVE_ACTION_LINE_ITEM, 'PRACA line item'],
    [ChangeSetType.PROCEDURE_LINE_ITEM, 'Procedure line item'],
    [ChangeSetType.TPS_LINE_ITEM, 'TPS line item'],
    [ChangeSetType.WAIVER_LINE_ITEM, 'Waiver line item'],
    [ChangeSetType.WORK_ORDER_STEP, 'Work order step'],
    [ChangeSetType.YELLOW_TAG_NOTICE_LINE_ITEM, 'YTN line item']
]);
export const ChangeSetTypeDescriptionDisplay = new Map([
    [
        ChangeSetType.DOCUMENT_FLAG_AS_DUPLICATE,
        'This allows the selected Document to be marked as a duplicate of another Document.'
    ],
    [
        ChangeSetType.ITEM_DRAWING_CODES_CHANGE,
        'This is a Item Asset and Usage Classification Code Change Set used to track any changes applied to these codes at the Drawing number level'
    ],
    [ChangeSetType.ITEM_DRAWING_VALID_SIZES_CHANGE, 'This is a Item Drawing update to "valid sizes".'],
    [
        ChangeSetType.ITEM_MASTER_INITIALIZATION,
        'A Item Master Initialization Change Set is used to track and process the approvals as part of the data validation processing of a newly registered Item Masters by Equipment group.  Formerly in SARAH refered to as "Item Init"'
    ],
    [
        ChangeSetType.ITEM_MASTER_INVENTORY_METHOD_CHANGE,
        'This is a Item Master Inventory Method Change Set used to track and process any changes applied to these codes at the Drawing number/AsBuilt level'
    ],
    [
        ChangeSetType.ITEM_MASTER_LIMITED_LIFE_CHANGE,
        "This is a Item Master Limited Life Change Set used to track and process any changes to a Item Master's Limited Life default values"
    ],
    [
        ChangeSetType.ITEM_MASTER_EQUIPMENT_GROUP_CHANGE,
        "This is a Item Master Equipment group Change Set used to track and process any changes to a Item Master's Equipment group"
    ],
    [
        ChangeSetType.ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE,
        "This is a Item Master FSC or Property Class Change Set used to track and process any changes to a Item Master's Federal Supply Class or Property Class"
    ],
    [
        ChangeSetType.ITEM_MASTER_REFURB_CHANGE,
        'This is the Item Master Expendable / Refurbishable Change Set used to switch between types.'
    ],
    [
        ChangeSetType.ITEM_INSTANCE_INVENTORY_CLASS_CHANGE,
        "A Item Instance's inventory Class Change Set is used to track and process approval for changing a Item Instance inventory's class."
    ],
    [
        ChangeSetType.ITEM_INSTANCE_INVENTORY_COST_CHANGE,
        "A Item Instance Cost Change Set is used to track all changes to a specific component's costs"
    ],
    [
        ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE,
        `A Item Instance Assembly Remove Change Set is used to track all Assembly removals.`
    ],
    [
        ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL,
        `A Item Instance Assembly Remove Change Set is used to track all Assembly installations.`
    ],
    [
        ChangeSetType.ITEM_INSTANCE_CONFIGURATION_CHANGE,
        'An Item Instance configuration change also known as a dash number roll.'
    ],
    [
        ChangeSetType.DISCREPANCY_REPORT_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ],
    [
        ChangeSetType.ENGINEERING_CHANGE_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ],
    [
        ChangeSetType.FAILURE_INVESTIGATION_ANOMALY_REPORT_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ],
    [
        ChangeSetType.OTHER_DOCUMENT_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ],
    [
        ChangeSetType.OVER_DUE_TAG_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ],
    [
        ChangeSetType.YELLOW_TAG_NOTICE_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ],
    [
        ChangeSetType.PROCEDURE_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ],
    [
        ChangeSetType.IRREGULAR_PARTS_AUTHORIZATION_REQUEST_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ],
    [
        ChangeSetType.MATERIAL_USAGE_AGREEMENT_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ],
    [
        ChangeSetType.NASA_SPACE_PART_AUTHORIZATION_REQUEST_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ],
    [
        ChangeSetType.WAIVER_LINE_ITEM,
        'Used to track the open/closed status of a Item Instance against any line item documents'
    ]
]);
export const ChangeSetStatusDisplay = new Map([
    [ChangeSetStatus.APPROVED, 'Approved'],
    [ChangeSetStatus.REJECTED, 'Rejected'],
    [ChangeSetStatus.OPEN, 'Open']
]);
export const ChangeSetTypeShortDisplay = new Map([
    [ChangeSetType.DISCREPANCY_REPORT_LINE_ITEM, 'DRs'],
    [ChangeSetType.ENGINEERING_CHANGE_LINE_ITEM, 'ECs'],
    [ChangeSetType.FAILURE_INVESTIGATION_ANOMALY_REPORT_LINE_ITEM, 'FIARs'],
    [ChangeSetType.IRREGULAR_PARTS_AUTHORIZATION_REQUEST_LINE_ITEM, 'IPAR'],
    [ChangeSetType.MATERIAL_USAGE_AGREEMENT_LINE_ITEM, 'MUA'],
    [ChangeSetType.NASA_SPACE_PART_AUTHORIZATION_REQUEST_LINE_ITEM, 'NSPAR'],
    [ChangeSetType.OTHER_DOCUMENT_LINE_ITEM, 'ODs'],
    [ChangeSetType.OVER_DUE_TAG_LINE_ITEM, 'ODTs'],
    [ChangeSetType.WAIVER_LINE_ITEM, 'Waivers'],
    [ChangeSetType.YELLOW_TAG_NOTICE_LINE_ITEM, 'YTNs']
]);
export const ChangeSetTypeToDocumentType = new Map([
    [ChangeSetType.CONFIGURATION_CHANGE_ORDER_LINE_ITEM, DocumentType.CONFIGURATION_CHANGE_ORDER],
    [ChangeSetType.DISCREPANCY_REPORT_LINE_ITEM, DocumentType.DISCREPANCY_REPORT],
    [ChangeSetType.EASY_TRANSFER_LINE_ITEM, DocumentType.EASY_TRANSFER],
    [ChangeSetType.ENGINEERING_CHANGE_LINE_ITEM, DocumentType.ENGINEERING_CHANGE],
    [ChangeSetType.FAILURE_INVESTIGATION_ANOMALY_REPORT_LINE_ITEM, DocumentType.FAILURE_INVESTIGATION_ANOMALY_REPORT],
    [ChangeSetType.IRREGULAR_PARTS_AUTHORIZATION_REQUEST_LINE_ITEM, DocumentType.IRREGULAR_PARTS_AUTHORIZATION_REQUEST],
    [ChangeSetType.ITEM_DRAWING_CODES_CHANGE, null],
    [ChangeSetType.ITEM_DRAWING_VALID_SIZES_CHANGE, null],
    [ChangeSetType.ITEM_INSTANCE_CONFIGURATION_CHANGE, null],
    [ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL, null],
    [ChangeSetType.ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE, null],
    [ChangeSetType.ITEM_INSTANCE_INVENTORY_CLASS_CHANGE, null],
    [ChangeSetType.ITEM_INSTANCE_INVENTORY_COST_CHANGE, null],
    [ChangeSetType.ITEM_INSTANCE_INVENTORY_PROJECT_CODE_CHANGE, null],
    [ChangeSetType.ITEM_INSTANCE_LIMITED_LIFE_CHANGE, null],
    [ChangeSetType.ITEM_INSTANCE_ON_ORBIT_FAILURE, null],
    [ChangeSetType.ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE, null],
    [ChangeSetType.ITEM_MASTER_EQUIPMENT_GROUP_CHANGE, null],
    [ChangeSetType.ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE, null],
    [ChangeSetType.ITEM_MASTER_INITIALIZATION, null],
    [ChangeSetType.ITEM_MASTER_INVENTORY_METHOD_CHANGE, null],
    [ChangeSetType.ITEM_MASTER_LIMITED_LIFE_CHANGE, null],
    [ChangeSetType.ITEM_MASTER_REFURB_CHANGE, null],
    [ChangeSetType.MATERIAL_USAGE_AGREEMENT_LINE_ITEM, DocumentType.MATERIAL_USAGE_AGREEMENT],
    [ChangeSetType.NASA_SPACE_PART_AUTHORIZATION_REQUEST_LINE_ITEM, DocumentType.NASA_SPACE_PART_AUTHORIZATION_REQUEST],
    [ChangeSetType.OTHER_DOCUMENT_LINE_ITEM, DocumentType.OTHER_DOCUMENT],
    [ChangeSetType.OVER_DUE_TAG_LINE_ITEM, DocumentType.OVER_DUE_TAG],
    [
        ChangeSetType.PROBLEM_REPORTING_AND_CORRECTIVE_ACTION_LINE_ITEM,
        DocumentType.PROBLEM_REPORTING_AND_CORRECTIVE_ACTION
    ],
    [ChangeSetType.PROCEDURE_LINE_ITEM, DocumentType.PROCEDURE],
    [ChangeSetType.TPS_LINE_ITEM, DocumentType.TPS],
    [ChangeSetType.WAIVER_LINE_ITEM, DocumentType.WAIVER],
    [ChangeSetType.YELLOW_TAG_NOTICE_LINE_ITEM, DocumentType.YELLOW_TAG_NOTICE]
]);
export const ChangeLogTypeDisplay = new Map([
    [ChangeLogType.CHANGE_SET, 'Change set'],
    [ChangeLogType.ENTITY_UPDATE, 'Update'],
    [ChangeLogType.ITEM_INSTANCE_MERGE, 'Item instance merge']
]);
