var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { COSMIC_APPLICATION } from '~/application.config';
import { Maybe } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { UserPermission } from '~/nasa_ui/types/enums/user';
import { sortObjectBy } from '~/nasa_ui/utils';
import { getAssetPath } from '~/nasa_ui/utils/helpers/assets';
import { CosmicIcons } from '~/nasa_ui/utils/ui/CosmicIcons';
import { getCdocsUrl } from '~/nasa_ui/utils/url';
import pkg from '../../../package.json';
const ADMIN_MENU = {
    displayName: 'Admin',
    icon: CosmicIcons.cog,
    children: [
        {
            displayName: 'Approvals',
            to: '/approval_templates/manage',
            icon: CosmicIcons['change_sets'],
            textColor: `var(--v-${EntityType.CHANGE_SET}-base)`
        },
        {
            displayName: 'Admin area',
            to: '/admin',
            icon: CosmicIcons['ADMIN'],
            textColor: `var(--v-secondary-base)`
        },
        {
            displayName: 'Manage Users',
            to: `/${EntityType.USER}/search`,
            icon: CosmicIcons[EntityType.USER],
            textColor: `var(--v-${EntityType.USER}-base)`
        },
        {
            displayName: 'Manage User Groups',
            to: `/${EntityType.USER}/groups/search`,
            icon: CosmicIcons[EntityType.USER_GROUP],
            textColor: `var(--v-${EntityType.USER_GROUP}-base)`
        }
    ]
};
const INVENTORY_MENU = {
    displayName: 'Inventory',
    icon: CosmicIcons.application_property,
    children: [
        {
            displayName: 'Movement out',
            to: '/hardware/movement',
            icon: CosmicIcons.movement,
            textColor: `var(--v-movement-base)`
        },
        {
            displayName: 'Returns',
            to: '/hardware/return_inventory',
            icon: `${CosmicIcons.movement} fa-flip-horizontal`,
            textColor: `var(--v-movement-base)`
        },
        {
            displayName: 'Item Instances',
            // to: `/hardware/search?includeInstances=true`,
            to: `/hardware/item_instances/search`,
            icon: CosmicIcons[EntityType.ITEM_INSTANCE],
            textColor: `var(--v-${EntityType.INVENTORY}-base)`
        },
        {
            displayName: 'Locations',
            to: `/${EntityType.LOCATION}/search`,
            icon: CosmicIcons[EntityType.LOCATION],
            textColor: `var(--v-${EntityType.LOCATION}-base)`
        },
        {
            displayName: 'Audits',
            to: `/hardware/${EntityType.PHYSICAL_INVENTORY_AUDIT}/search`,
            icon: CosmicIcons[EntityType.PHYSICAL_INVENTORY_AUDIT],
            textColor: `var(--v-${EntityType.PHYSICAL_INVENTORY_AUDIT}-base)`
        }
        // {
        //   displayName: 'Barcode Scanning',
        //   to: `/hardware/${EntityType.ITEM_INSTANCE}/barcodes`,
        //   icon: CosmicIcons.scanner,
        //   textColor: `var(--v-${EntityType.INVENTORY}-base)`
        // }
    ]
};
const OPERATIONS_MENU = {
    displayName: 'Operations',
    to: '/operations',
    icon: CosmicIcons.application_operations,
    children: [
        {
            displayName: 'Activities',
            to: `/${EntityType.ACTIVITY}/search`,
            icon: CosmicIcons[EntityType.ACTIVITY],
            textColor: `var(--v-${EntityType.ACTIVITY}-base)`
        },
        {
            displayName: 'Events',
            to: `/${EntityType.EVENT}/search`,
            icon: CosmicIcons[EntityType.EVENT],
            textColor: `var(--v-${EntityType.EVENT}-base)`
        },
        {
            displayName: 'Tracking Documents',
            to: `/${EntityType.DOCUMENT}/search`,
            icon: CosmicIcons[EntityType.DOCUMENT],
            textColor: `var(--v-${EntityType.DOCUMENT}-base)`
        }
    ]
};
const PROPERTY_MENU = {
    displayName: 'Property',
    to: '/hardware/reporting/inventory',
    icon: CosmicIcons[EntityType.ITEM_MASTER],
    children: [
        {
            displayName: 'Item Masters',
            // to: `/hardware/search?includeMasters=true`,
            to: `/hardware/item_masters/search`,
            icon: CosmicIcons[EntityType.ITEM_MASTER],
            textColor: `var(--v-${EntityType.ITEM_MASTER}-base)`
        },
        {
            displayName: 'Item Drawings',
            // to: `/hardware/search?includeDrawings=true`,
            to: `/hardware/item_drawings/search`,
            icon: CosmicIcons[EntityType.ITEM_DRAWING],
            textColor: `var(--v-${EntityType.ITEM_DRAWING}-base)`
        },
        {
            to: `/hardware/${EntityType.ASSEMBLY}/search`,
            displayName: 'Assemblies',
            icon: CosmicIcons[EntityType.ASSEMBLY],
            textColor: `var(--v-${EntityType.ASSEMBLY}-base)`
        },
        /* {
          to: `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/search`,
          displayName: 'Assembly Templates',
          icon: CosmicIcons[EntityType.ASSEMBLY_TEMPLATE],
          textColor: `${EntityType.ASSEMBLY_TEMPLATE}--text`
        }, */
        {
            displayName: 'Hardware Lists',
            to: '/hardware/lists/search',
            icon: CosmicIcons[EntityType.HARDWARE_LIST],
            textColor: `var(--v-${EntityType.HARDWARE_LIST}-base)`
        },
        {
            displayName: 'Hardware List Templates',
            to: '/hardware/list_templates/search',
            icon: CosmicIcons[EntityType.HARDWARE_LIST_TEMPLATE],
            textColor: `var(--v-${EntityType.HARDWARE_LIST_TEMPLATE}-base)`
        },
        {
            displayName: 'Organizations',
            to: `/${EntityType.ORGANIZATION}/search`,
            icon: CosmicIcons[EntityType.ORGANIZATION],
            textColor: `var(--v-${EntityType.ORGANIZATION}-base)`
        },
        {
            displayName: 'Contracts',
            to: `/${EntityType.CONTRACT}/search`,
            icon: CosmicIcons[EntityType.CONTRACT],
            textColor: `var(--v-${EntityType.CONTRACT}-base)`
        }
    ]
};
const ADMIN_ONLY_ROUTES = ['Admin'];
let TheSideNav = class TheSideNav extends Mixins(CurrentUserMixin, BaseCosmic) {
    AlertType = AlertType;
    appVersion = pkg.version;
    filterMenu = null;
    showChangeLog = false;
    userIsMriDevTeam; // to show demo menu item
    value;
    outageAlertState;
    get cDocsUrl() {
        return getCdocsUrl();
    }
    /**
     * /src/static_resources/assets/esoc/cdocs/documents_app_logo_light.svg
     */
    get cosmicDocumentsLogoSrc() {
        return `${getAssetPath(COSMIC_APPLICATION)}/documents_app_logo_light.svg`;
    }
    get combinedRoutes() {
        const flattenedRoutes = this.$flatten(this.filteredTheSideNavigationMenu.map((menuItem) => {
            return menuItem.children;
        })).sort(sortObjectBy('displayName'));
        return flattenedRoutes;
    }
    get filteredTheSideNavigationMenu() {
        let filteredMenu = [INVENTORY_MENU, PROPERTY_MENU, OPERATIONS_MENU, ADMIN_MENU];
        // filter out any admin only routes if user role is not ADMIN
        if (!this.currentUserIsAdmin) {
            filteredMenu = this.filterOutAdminOnlyRoutes(filteredMenu);
        }
        return filteredMenu;
    }
    filterOutAdminOnlyRoutes(menuItems) {
        return menuItems.reduce((filterMenu, menuItem) => {
            if (menuItem.children) {
                menuItem.children = menuItem.children?.filter((child) => {
                    if (child.displayName === 'Approvals') {
                        return this.currentUserHasPermission(UserPermission.CHANGE_SET_TEMPLATE_ADMIN);
                    }
                    else {
                        return !ADMIN_ONLY_ROUTES.includes(child.displayName);
                    }
                });
            }
            filterMenu.push(menuItem);
            return filterMenu;
        }, []);
    }
    closeSideNav() {
        this.$message('closeTheSideNav');
    }
    onClickOfShowChangeLog() {
        this.showChangeLog = true;
    }
    emitInput(val) {
        return val;
    }
    focusEvent(event) { }
};
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], TheSideNav.prototype, "value", void 0);
__decorate([
    Prop({
        type: String,
        required: false,
        default: null
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], TheSideNav.prototype, "outageAlertState", void 0);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TheSideNav.prototype, "emitInput", null);
__decorate([
    Emit('openSideNav'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof FocusEvent !== "undefined" && FocusEvent) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], TheSideNav.prototype, "focusEvent", null);
TheSideNav = __decorate([
    Component
], TheSideNav);
export default TheSideNav;
