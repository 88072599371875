var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "icon_auto_hardware_list"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": _vm.computedBracketIconSize,
      "icon": "fa-regular fa-bracket-square",
      "icon-color": "var(--cosmic-text)"
    },
    domProps: {
      "fixedWidth": false
    }
  }), _c('c-icon-fa', {
    attrs: {
      "icon-size": _vm.computedPartReferenceIconSize,
      "icon": "fa-light fa-grid",
      "icon-color": "var(--v-item_instances-base)"
    },
    domProps: {
      "fixedWidth": false
    }
  }), _c('c-icon-fa', {
    attrs: {
      "icon-size": _vm.computedBracketIconSize,
      "icon": "fa-regular fa-bracket-square-right",
      "icon-color": "var(--cosmic-text)"
    },
    domProps: {
      "fixedWidth": false
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };