/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
export var AccessDomain;
(function (AccessDomain) {
    AccessDomain["DIGITAL_LIBRARY"] = "DIGITAL_LIBRARY";
    AccessDomain["HORIZONS"] = "HORIZONS";
})(AccessDomain || (AccessDomain = {}));
export var ActivityType;
(function (ActivityType) {
    ActivityType["AUTOCYCLE"] = "AUTOCYCLE";
    ActivityType["BATTERY_MAINTENANCE"] = "BATTERY_MAINTENANCE";
    ActivityType["CALIBRATION"] = "CALIBRATION";
    ActivityType["CHARGE"] = "CHARGE";
    ActivityType["CHECK_OUT"] = "CHECK_OUT";
    ActivityType["CYCLE"] = "CYCLE";
    ActivityType["DISCHARGE"] = "DISCHARGE";
    ActivityType["DRY_OUT"] = "DRY_OUT";
    ActivityType["DUMP"] = "DUMP";
    ActivityType["EMU_H20_DUMP_AND_FILL"] = "EMU_H20_DUMP_AND_FILL";
    ActivityType["EMU_H20_RECHARGE"] = "EMU_H20_RECHARGE";
    ActivityType["EVA_ACTIVITY"] = "EVA_ACTIVITY";
    ActivityType["FILL"] = "FILL";
    ActivityType["FLIGHT"] = "FLIGHT";
    ActivityType["GMIP"] = "GMIP";
    ActivityType["GREASE_AND_CIRCULATE"] = "GREASE_AND_CIRCULATE";
    ActivityType["GSE_CALIBRATION"] = "GSE_CALIBRATION";
    ActivityType["INSPECTION"] = "INSPECTION";
    ActivityType["INTERVAL"] = "INTERVAL";
    ActivityType["IODINATE_LOOP_SCRUB"] = "IODINATE_LOOP_SCRUB";
    ActivityType["ITEM_146_CYCLE"] = "ITEM_146_CYCLE";
    ActivityType["MAINTENANCE"] = "MAINTENANCE";
    ActivityType["MIP"] = "MIP";
    ActivityType["O2_POPPET_VERIFICATION"] = "O2_POPPET_VERIFICATION";
    ActivityType["OPERATION"] = "OPERATION";
    ActivityType["OTHER"] = "OTHER";
    ActivityType["PRESSURIZATION"] = "PRESSURIZATION";
    ActivityType["RECEIVING_INSPECTION"] = "RECEIVING_INSPECTION";
    ActivityType["REGENERATION"] = "REGENERATION";
    ActivityType["RESIZE_OR_DRY_RUN"] = "RESIZE_OR_DRY_RUN";
    ActivityType["SERVICE"] = "SERVICE";
    ActivityType["TAK_DATA_COLLECTION"] = "TAK_DATA_COLLECTION";
    ActivityType["TEN_POINT_TWO_OPS"] = "TEN_POINT_TWO_OPS";
    ActivityType["TEST"] = "TEST";
    ActivityType["TETHER_INSPECTION"] = "TETHER_INSPECTION";
    ActivityType["VENT"] = "VENT";
    ActivityType["WETTING_OR_IODINATING"] = "WETTING_OR_IODINATING";
    ActivityType["WLVTA_REPLACEMENT"] = "WLVTA_REPLACEMENT";
})(ActivityType || (ActivityType = {}));
export var AssemblyTemplateType;
(function (AssemblyTemplateType) {
    AssemblyTemplateType["ASSEMBLY_TEMPLATE"] = "ASSEMBLY_TEMPLATE";
    AssemblyTemplateType["EVENT_ASSEMBLY"] = "EVENT_ASSEMBLY";
    AssemblyTemplateType["MEGA"] = "MEGA";
    AssemblyTemplateType["PRODUCT_BREAKDOWN_STRUCTURE"] = "PRODUCT_BREAKDOWN_STRUCTURE";
    AssemblyTemplateType["SEMU"] = "SEMU";
    AssemblyTemplateType["SUPER"] = "SUPER";
})(AssemblyTemplateType || (AssemblyTemplateType = {}));
export var AttachmentType;
(function (AttachmentType) {
    AttachmentType["CLOSEOUT"] = "CLOSEOUT";
    AttachmentType["CORRECTIVE_ACTION"] = "CORRECTIVE_ACTION";
    AttachmentType["DRAWING"] = "DRAWING";
    AttachmentType["GENERAL"] = "GENERAL";
    AttachmentType["INCIDENT_ANALYSIS"] = "INCIDENT_ANALYSIS";
    AttachmentType["PROCEDURE"] = "PROCEDURE";
    AttachmentType["PROCEDURE_EXECUTED_COPY"] = "PROCEDURE_EXECUTED_COPY";
    AttachmentType["REFERENCE_DOCUMENT"] = "REFERENCE_DOCUMENT";
    AttachmentType["WORK_ORDER_STEP"] = "WORK_ORDER_STEP";
})(AttachmentType || (AttachmentType = {}));
export var CalibrationType;
(function (CalibrationType) {
    CalibrationType["ANNUAL_CERTIFICATION"] = "ANNUAL_CERTIFICATION";
    CalibrationType["ANNUAL_LEAKAGE"] = "ANNUAL_LEAKAGE";
    CalibrationType["ANNUAL_SAMPLING"] = "ANNUAL_SAMPLING";
    CalibrationType["CALIBRATION"] = "CALIBRATION";
    CalibrationType["CLASS_I_INSPECTION"] = "CLASS_I_INSPECTION";
    CalibrationType["CLASS_II_INSPECTION"] = "CLASS_II_INSPECTION";
    CalibrationType["CLASS_III_INSPECTION"] = "CLASS_III_INSPECTION";
    CalibrationType["DOT_CERTIFICATION"] = "DOT_CERTIFICATION";
    CalibrationType["FILTER_CHANGE_OUT"] = "FILTER_CHANGE_OUT";
    CalibrationType["FUNCTIONAL_CHECK_OUT"] = "FUNCTIONAL_CHECK_OUT";
    CalibrationType["GENERAL"] = "GENERAL";
    CalibrationType["H2O_IODINE_TREATMENT"] = "H2O_IODINE_TREATMENT";
    CalibrationType["HYDROSTATIC_TEST"] = "HYDROSTATIC_TEST";
    CalibrationType["IN_HOUSE_CALIBRATION"] = "IN_HOUSE_CALIBRATION";
    CalibrationType["LOAD_CERTIFICATION"] = "LOAD_CERTIFICATION";
    CalibrationType["MONTHLY_SAMPLING"] = "MONTHLY_SAMPLING";
    CalibrationType["PNEUMATIC_TEST"] = "PNEUMATIC_TEST";
    CalibrationType["PREVENTATIVE_MAINTENANCE"] = "PREVENTATIVE_MAINTENANCE";
    CalibrationType["PROOF_PRESSURE_TEST"] = "PROOF_PRESSURE_TEST";
    CalibrationType["QUARTERLY_CERTIFICATION"] = "QUARTERLY_CERTIFICATION";
    CalibrationType["RADIATION_SAFETY_CERTIFICATE"] = "RADIATION_SAFETY_CERTIFICATE";
    CalibrationType["RELIEF_VALVE_CERTIFICATION"] = "RELIEF_VALVE_CERTIFICATION";
})(CalibrationType || (CalibrationType = {}));
export var ChangeLogType;
(function (ChangeLogType) {
    ChangeLogType["CHANGE_SET"] = "CHANGE_SET";
    ChangeLogType["ITEM_INSTANCE_MERGE"] = "ITEM_INSTANCE_MERGE";
    ChangeLogType["ENTITY_UPDATE"] = "ENTITY_UPDATE";
})(ChangeLogType || (ChangeLogType = {}));
export var ChangeSetAuthorityType;
(function (ChangeSetAuthorityType) {
    ChangeSetAuthorityType["DEFAULT"] = "DEFAULT";
    ChangeSetAuthorityType["MANUAL_INSPECTION_POINT"] = "MANUAL_INSPECTION_POINT";
    ChangeSetAuthorityType["GOVERNMENT_MANUAL_INSPECTION_POINT"] = "GOVERNMENT_MANUAL_INSPECTION_POINT";
})(ChangeSetAuthorityType || (ChangeSetAuthorityType = {}));
export var ChangeSetStatus;
(function (ChangeSetStatus) {
    ChangeSetStatus["APPROVED"] = "APPROVED";
    ChangeSetStatus["OPEN"] = "OPEN";
    ChangeSetStatus["REJECTED"] = "REJECTED";
})(ChangeSetStatus || (ChangeSetStatus = {}));
export var ChangeSetType;
(function (ChangeSetType) {
    ChangeSetType["ACCESS_REQUEST"] = "ACCESS_REQUEST";
    ChangeSetType["COSMIC_ACTION"] = "COSMIC_ACTION";
    ChangeSetType["COSMIC_DOCUMENT_INITIALIZATION"] = "COSMIC_DOCUMENT_INITIALIZATION";
    ChangeSetType["DOCUMENT_FLAG_AS_DUPLICATE"] = "DOCUMENT_FLAG_AS_DUPLICATE";
    ChangeSetType["ITEM_DRAWING_CODES_CHANGE"] = "ITEM_DRAWING_CODES_CHANGE";
    ChangeSetType["ITEM_DRAWING_VALID_SIZES_CHANGE"] = "ITEM_DRAWING_VALID_SIZES_CHANGE";
    ChangeSetType["ITEM_INSTANCE_CONFIGURATION_CHANGE"] = "ITEM_INSTANCE_CONFIGURATION_CHANGE";
    ChangeSetType["ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL"] = "ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL";
    ChangeSetType["ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE"] = "ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE";
    ChangeSetType["ITEM_INSTANCE_INVENTORY_CLASS_CHANGE"] = "ITEM_INSTANCE_INVENTORY_CLASS_CHANGE";
    ChangeSetType["ITEM_INSTANCE_INVENTORY_COST_CHANGE"] = "ITEM_INSTANCE_INVENTORY_COST_CHANGE";
    ChangeSetType["ITEM_INSTANCE_INVENTORY_PROJECT_CODE_CHANGE"] = "ITEM_INSTANCE_INVENTORY_PROJECT_CODE_CHANGE";
    ChangeSetType["ITEM_INSTANCE_LIMITED_LIFE_CHANGE"] = "ITEM_INSTANCE_LIMITED_LIFE_CHANGE";
    ChangeSetType["ITEM_INSTANCE_ON_ORBIT_FAILURE"] = "ITEM_INSTANCE_ON_ORBIT_FAILURE";
    ChangeSetType["ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE"] = "ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE";
    ChangeSetType["ITEM_MASTER_APPROVAL_CHANGE"] = "ITEM_MASTER_APPROVAL_CHANGE";
    ChangeSetType["ITEM_MASTER_EQUIPMENT_GROUP_CHANGE"] = "ITEM_MASTER_EQUIPMENT_GROUP_CHANGE";
    ChangeSetType["ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE"] = "ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE";
    ChangeSetType["ITEM_MASTER_INITIALIZATION"] = "ITEM_MASTER_INITIALIZATION";
    ChangeSetType["ITEM_MASTER_INVENTORY_METHOD_CHANGE"] = "ITEM_MASTER_INVENTORY_METHOD_CHANGE";
    ChangeSetType["ITEM_MASTER_LIMITED_LIFE_CHANGE"] = "ITEM_MASTER_LIMITED_LIFE_CHANGE";
    ChangeSetType["ITEM_MASTER_REFURB_CHANGE"] = "ITEM_MASTER_REFURB_CHANGE";
    ChangeSetType["WORK_ORDER_STEP"] = "WORK_ORDER_STEP";
    ChangeSetType["ACCEPTANCE_DATA_PACK_LINE_ITEM"] = "ACCEPTANCE_DATA_PACK_LINE_ITEM";
    ChangeSetType["BARCODE_DECLARATION_LINE_ITEM"] = "BARCODE_DECLARATION_LINE_ITEM";
    ChangeSetType["C_DISCREPANCY_REPORT_LINE_ITEM"] = "C_DISCREPANCY_REPORT_LINE_ITEM";
    ChangeSetType["C_EASY_TRANSFER_LINE_ITEM"] = "C_EASY_TRANSFER_LINE_ITEM";
    ChangeSetType["C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM_LINE_ITEM"] = "C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM_LINE_ITEM";
    ChangeSetType["C_RCA_LINE_ITEM"] = "C_RCA_LINE_ITEM";
    ChangeSetType["C_TPS_LINE_ITEM"] = "C_TPS_LINE_ITEM";
    ChangeSetType["C_WORKFLOW_LINE_ITEM"] = "C_WORKFLOW_LINE_ITEM";
    ChangeSetType["CONFIGURATION_CHANGE_ORDER_LINE_ITEM"] = "CONFIGURATION_CHANGE_ORDER_LINE_ITEM";
    ChangeSetType["CR_LINE_ITEM"] = "CR_LINE_ITEM";
    ChangeSetType["DELIVERY_ORDER_LINE_ITEM"] = "DELIVERY_ORDER_LINE_ITEM";
    ChangeSetType["DISCREPANCY_REPORT_LINE_ITEM"] = "DISCREPANCY_REPORT_LINE_ITEM";
    ChangeSetType["DRAWING_LINE_ITEM"] = "DRAWING_LINE_ITEM";
    ChangeSetType["EASY_TRANSFER_LINE_ITEM"] = "EASY_TRANSFER_LINE_ITEM";
    ChangeSetType["ELABEL_LINE_ITEM"] = "ELABEL_LINE_ITEM";
    ChangeSetType["ELROD_LINE_ITEM"] = "ELROD_LINE_ITEM";
    ChangeSetType["ENGINEERING_CHANGE_LINE_ITEM"] = "ENGINEERING_CHANGE_LINE_ITEM";
    ChangeSetType["FAILURE_INVESTIGATION_ANOMALY_REPORT_LINE_ITEM"] = "FAILURE_INVESTIGATION_ANOMALY_REPORT_LINE_ITEM";
    ChangeSetType["GCAR_LINE_ITEM"] = "GCAR_LINE_ITEM";
    ChangeSetType["HAZARD_RESPONSE_LEVEL_LINE_ITEM"] = "HAZARD_RESPONSE_LEVEL_LINE_ITEM";
    ChangeSetType["IRREGULAR_PARTS_AUTHORIZATION_REQUEST_LINE_ITEM"] = "IRREGULAR_PARTS_AUTHORIZATION_REQUEST_LINE_ITEM";
    ChangeSetType["ITEM_FOR_INVESTIGATION_LINE_ITEM"] = "ITEM_FOR_INVESTIGATION_LINE_ITEM";
    ChangeSetType["MATERIAL_USAGE_AGREEMENT_LINE_ITEM"] = "MATERIAL_USAGE_AGREEMENT_LINE_ITEM";
    ChangeSetType["NASA_SPACE_PART_AUTHORIZATION_REQUEST_LINE_ITEM"] = "NASA_SPACE_PART_AUTHORIZATION_REQUEST_LINE_ITEM";
    ChangeSetType["NINE_ZERO_SIX_LINE_ITEM"] = "NINE_ZERO_SIX_LINE_ITEM";
    ChangeSetType["OPS_NOM_DECLARATION_LINE_ITEM"] = "OPS_NOM_DECLARATION_LINE_ITEM";
    ChangeSetType["OTHER_DOCUMENT_LINE_ITEM"] = "OTHER_DOCUMENT_LINE_ITEM";
    ChangeSetType["OVER_DUE_TAG_LINE_ITEM"] = "OVER_DUE_TAG_LINE_ITEM";
    ChangeSetType["PROBLEM_REPORTING_AND_CORRECTIVE_ACTION_LINE_ITEM"] = "PROBLEM_REPORTING_AND_CORRECTIVE_ACTION_LINE_ITEM";
    ChangeSetType["PROCEDURE_LINE_ITEM"] = "PROCEDURE_LINE_ITEM";
    ChangeSetType["RSA_LINE_ITEM"] = "RSA_LINE_ITEM";
    ChangeSetType["TPS_LINE_ITEM"] = "TPS_LINE_ITEM";
    ChangeSetType["VEHICLE_CERTIFICATION_LINE_ITEM"] = "VEHICLE_CERTIFICATION_LINE_ITEM";
    ChangeSetType["WAIVER_LINE_ITEM"] = "WAIVER_LINE_ITEM";
    ChangeSetType["YELLOW_TAG_NOTICE_LINE_ITEM"] = "YELLOW_TAG_NOTICE_LINE_ITEM";
})(ChangeSetType || (ChangeSetType = {}));
export var CommentType;
(function (CommentType) {
    CommentType["ACTIVITY"] = "ACTIVITY";
    CommentType["AUDIT"] = "AUDIT";
    CommentType["AUTHORITY_COMMENT"] = "AUTHORITY_COMMENT";
    CommentType["CANCEL_REASON"] = "CANCEL_REASON";
    CommentType["CHANGE_NOTE"] = "CHANGE_NOTE";
    CommentType["CLOSURE"] = "CLOSURE";
    CommentType["DISPOSITION"] = "DISPOSITION";
    CommentType["EFFECTIVITY"] = "EFFECTIVITY";
    CommentType["ESEL"] = "ESEL";
    CommentType["EVENT_MILESTONE"] = "EVENT_MILESTONE";
    CommentType["FINAL_DISPOSITION"] = "FINAL_DISPOSITION";
    CommentType["GENERAL"] = "GENERAL";
    CommentType["INITIALIZATION_COMMENT"] = "INITIALIZATION_COMMENT";
    CommentType["INITIATOR_CONCURRENCE"] = "INITIATOR_CONCURRENCE";
    CommentType["LEAKAGE_HISTORY"] = "LEAKAGE_HISTORY";
    CommentType["LIMITED_LIFE"] = "LIMITED_LIFE";
    CommentType["LOGISTICS"] = "LOGISTICS";
    CommentType["MAINTENANCE"] = "MAINTENANCE";
    CommentType["MEETING_MINUTE"] = "MEETING_MINUTE";
    CommentType["NASA_APPROVAL"] = "NASA_APPROVAL";
    CommentType["NASA_RECLAMA"] = "NASA_RECLAMA";
    CommentType["NOTE"] = "NOTE";
    CommentType["OEM"] = "OEM";
    CommentType["ORB_NOTE"] = "ORB_NOTE";
    CommentType["PROCESSOR"] = "PROCESSOR";
    CommentType["REVIEW_DISPOSITION"] = "REVIEW_DISPOSITION";
    CommentType["SIZING"] = "SIZING";
    CommentType["STATUS"] = "STATUS";
    CommentType["SYSTEM"] = "SYSTEM";
    CommentType["TRANSACTION"] = "TRANSACTION";
    CommentType["TREND_NOTE"] = "TREND_NOTE";
})(CommentType || (CommentType = {}));
export var CosmicRole;
(function (CosmicRole) {
    CosmicRole["DIGITAL_LIBRARY_USER"] = "DIGITAL_LIBRARY_USER";
    CosmicRole["DIGITAL_LIBRARY_ADMIN"] = "DIGITAL_LIBRARY_ADMIN";
    CosmicRole["HORIZONS_USER"] = "HORIZONS_USER";
    CosmicRole["HORIZONS_ADMIN"] = "HORIZONS_ADMIN";
})(CosmicRole || (CosmicRole = {}));
export var DataImportType;
(function (DataImportType) {
    DataImportType["DIGITAL_LIBRARY_REVIEW_ITEMS"] = "DIGITAL_LIBRARY_REVIEW_ITEMS";
    DataImportType["NON_ACTUALIZED_ITEM_INSTANCES"] = "NON_ACTUALIZED_ITEM_INSTANCES";
    DataImportType["OBJECT_CLASS_ITEMS"] = "OBJECT_CLASS_ITEMS";
})(DataImportType || (DataImportType = {}));
export var DeliveryOrderLineItemStatus;
(function (DeliveryOrderLineItemStatus) {
    DeliveryOrderLineItemStatus["IN_PROCESS"] = "IN_PROCESS";
    DeliveryOrderLineItemStatus["PROPOSAL"] = "PROPOSAL";
    DeliveryOrderLineItemStatus["NOT_STARTED"] = "NOT_STARTED";
    DeliveryOrderLineItemStatus["VOID"] = "VOID";
    DeliveryOrderLineItemStatus["PROBABLE"] = "PROBABLE";
    DeliveryOrderLineItemStatus["READY_FOR_COMPLETION"] = "READY_FOR_COMPLETION";
})(DeliveryOrderLineItemStatus || (DeliveryOrderLineItemStatus = {}));
export var DeliveryOrderLineItemType;
(function (DeliveryOrderLineItemType) {
    DeliveryOrderLineItemType["FL"] = "FL";
    DeliveryOrderLineItemType["NEW_BUILD"] = "NEW_BUILD";
    DeliveryOrderLineItemType["PROCURE"] = "PROCURE";
    DeliveryOrderLineItemType["REFURB"] = "REFURB";
})(DeliveryOrderLineItemType || (DeliveryOrderLineItemType = {}));
export var DigitalLibraryRecordRelationType;
(function (DigitalLibraryRecordRelationType) {
    DigitalLibraryRecordRelationType["OTHER"] = "OTHER";
    DigitalLibraryRecordRelationType["OTHER_FOR"] = "OTHER_FOR";
    DigitalLibraryRecordRelationType["PARENT"] = "PARENT";
    DigitalLibraryRecordRelationType["PARENT_FOR"] = "PARENT_FOR";
    DigitalLibraryRecordRelationType["CHILD"] = "CHILD";
    DigitalLibraryRecordRelationType["CHILD_FOR"] = "CHILD_FOR";
})(DigitalLibraryRecordRelationType || (DigitalLibraryRecordRelationType = {}));
export var DigitalLibraryRecordReleaseType;
(function (DigitalLibraryRecordReleaseType) {
    DigitalLibraryRecordReleaseType["TYPE_1"] = "TYPE_1";
    DigitalLibraryRecordReleaseType["TYPE_2"] = "TYPE_2";
    DigitalLibraryRecordReleaseType["TYPE_3"] = "TYPE_3";
    DigitalLibraryRecordReleaseType["TYPE_4"] = "TYPE_4";
})(DigitalLibraryRecordReleaseType || (DigitalLibraryRecordReleaseType = {}));
export var DigitalLibraryRecordType;
(function (DigitalLibraryRecordType) {
    DigitalLibraryRecordType["ANALYSIS"] = "ANALYSIS";
    DigitalLibraryRecordType["ARCHITECTURE"] = "ARCHITECTURE";
    DigitalLibraryRecordType["CERTIFICATION"] = "CERTIFICATION";
    DigitalLibraryRecordType["DOCUMENT"] = "DOCUMENT";
    DigitalLibraryRecordType["DRAWING"] = "DRAWING";
    DigitalLibraryRecordType["GUIDE_HANDBOOK"] = "GUIDE_HANDBOOK";
    DigitalLibraryRecordType["IMAGE"] = "IMAGE";
    DigitalLibraryRecordType["INTERFACE"] = "INTERFACE";
    DigitalLibraryRecordType["MODEL"] = "MODEL";
    DigitalLibraryRecordType["OPERATIONS_CONCEPT"] = "OPERATIONS_CONCEPT";
    DigitalLibraryRecordType["PLAN"] = "PLAN";
    DigitalLibraryRecordType["PROCEDURE"] = "PROCEDURE";
    DigitalLibraryRecordType["REPORT"] = "REPORT";
    DigitalLibraryRecordType["SCHEDULE"] = "SCHEDULE";
    DigitalLibraryRecordType["SPECIFICATION"] = "SPECIFICATION";
    DigitalLibraryRecordType["TECHNICAL_DOCUMENTATION"] = "TECHNICAL_DOCUMENTATION";
    DigitalLibraryRecordType["VERIFICATION"] = "VERIFICATION";
})(DigitalLibraryRecordType || (DigitalLibraryRecordType = {}));
export var DigitalLibraryReviewItemDisposition;
(function (DigitalLibraryReviewItemDisposition) {
    DigitalLibraryReviewItemDisposition["ACCEPT"] = "ACCEPT";
    DigitalLibraryReviewItemDisposition["ACCEPT_WITH_MODIFICATION"] = "ACCEPT_WITH_MODIFICATION";
    DigitalLibraryReviewItemDisposition["DUPLICATE"] = "DUPLICATE";
    DigitalLibraryReviewItemDisposition["REJECT"] = "REJECT";
    DigitalLibraryReviewItemDisposition["FORWARD_WORK"] = "FORWARD_WORK";
})(DigitalLibraryReviewItemDisposition || (DigitalLibraryReviewItemDisposition = {}));
export var DigitalLibraryReviewItemStatus;
(function (DigitalLibraryReviewItemStatus) {
    DigitalLibraryReviewItemStatus["DRAFT"] = "DRAFT";
    DigitalLibraryReviewItemStatus["DISPOSITIONED"] = "DISPOSITIONED";
    DigitalLibraryReviewItemStatus["PENDING_NASA_REVIEW"] = "PENDING_NASA_REVIEW";
    DigitalLibraryReviewItemStatus["NASA_APPROVED"] = "NASA_APPROVED";
    DigitalLibraryReviewItemStatus["NASA_RECLAMA"] = "NASA_RECLAMA";
})(DigitalLibraryReviewItemStatus || (DigitalLibraryReviewItemStatus = {}));
export var DigitalLibraryReviewItemType;
(function (DigitalLibraryReviewItemType) {
    DigitalLibraryReviewItemType["UNKNOWN"] = "UNKNOWN";
    DigitalLibraryReviewItemType["BUDGET"] = "BUDGET";
    DigitalLibraryReviewItemType["EDITORIAL"] = "EDITORIAL";
    DigitalLibraryReviewItemType["RISK"] = "RISK";
    DigitalLibraryReviewItemType["TECHNICAL"] = "TECHNICAL";
    DigitalLibraryReviewItemType["SCHEDULE"] = "SCHEDULE";
})(DigitalLibraryReviewItemType || (DigitalLibraryReviewItemType = {}));
export var DigitalLibraryRevisionReleaseStatus;
(function (DigitalLibraryRevisionReleaseStatus) {
    DigitalLibraryRevisionReleaseStatus["DRAFT"] = "DRAFT";
    DigitalLibraryRevisionReleaseStatus["PUBLISHED"] = "PUBLISHED";
})(DigitalLibraryRevisionReleaseStatus || (DigitalLibraryRevisionReleaseStatus = {}));
export var DocumentDefectCode;
(function (DocumentDefectCode) {
    DocumentDefectCode["DA1"] = "DA1";
    DocumentDefectCode["DC1"] = "DC1";
    DocumentDefectCode["DC2"] = "DC2";
    DocumentDefectCode["DD1"] = "DD1";
    DocumentDefectCode["DD2"] = "DD2";
    DocumentDefectCode["DD3"] = "DD3";
    DocumentDefectCode["DD4"] = "DD4";
    DocumentDefectCode["DD5"] = "DD5";
    DocumentDefectCode["DD6"] = "DD6";
    DocumentDefectCode["DD7"] = "DD7";
    DocumentDefectCode["DD8"] = "DD8";
    DocumentDefectCode["DE"] = "DE";
    DocumentDefectCode["DE1"] = "DE1";
    DocumentDefectCode["DE2"] = "DE2";
    DocumentDefectCode["DE3"] = "DE3";
    DocumentDefectCode["DE4"] = "DE4";
    DocumentDefectCode["DE5"] = "DE5";
    DocumentDefectCode["DE6"] = "DE6";
    DocumentDefectCode["DE7"] = "DE7";
    DocumentDefectCode["DL1"] = "DL1";
    DocumentDefectCode["DM1"] = "DM1";
    DocumentDefectCode["DM2"] = "DM2";
    DocumentDefectCode["DM3"] = "DM3";
    DocumentDefectCode["DM4"] = "DM4";
    DocumentDefectCode["DM5"] = "DM5";
    DocumentDefectCode["DM6"] = "DM6";
    DocumentDefectCode["DM7"] = "DM7";
    DocumentDefectCode["DM8"] = "DM8";
    DocumentDefectCode["DP1"] = "DP1";
    DocumentDefectCode["DP2"] = "DP2";
    DocumentDefectCode["DP3"] = "DP3";
    DocumentDefectCode["DP4"] = "DP4";
    DocumentDefectCode["DP5"] = "DP5";
    DocumentDefectCode["DR1"] = "DR1";
    DocumentDefectCode["DR2"] = "DR2";
    DocumentDefectCode["DR3"] = "DR3";
    DocumentDefectCode["DR4"] = "DR4";
    DocumentDefectCode["DR5"] = "DR5";
    DocumentDefectCode["DR6"] = "DR6";
    DocumentDefectCode["DS1"] = "DS1";
    DocumentDefectCode["DS2"] = "DS2";
    DocumentDefectCode["DS3"] = "DS3";
    DocumentDefectCode["DS4"] = "DS4";
    DocumentDefectCode["DS5"] = "DS5";
    DocumentDefectCode["DS6"] = "DS6";
    DocumentDefectCode["DS7"] = "DS7";
    DocumentDefectCode["DT"] = "DT";
    DocumentDefectCode["DT1"] = "DT1";
    DocumentDefectCode["DT2"] = "DT2";
    DocumentDefectCode["N_A"] = "N_A";
})(DocumentDefectCode || (DocumentDefectCode = {}));
export var DocumentReleaseStatus;
(function (DocumentReleaseStatus) {
    DocumentReleaseStatus["AVAILABLE"] = "AVAILABLE";
    DocumentReleaseStatus["CANCELLED"] = "CANCELLED";
    DocumentReleaseStatus["INACTIVE"] = "INACTIVE";
    DocumentReleaseStatus["IN_WORK"] = "IN_WORK";
    DocumentReleaseStatus["OBSOLETE"] = "OBSOLETE";
    DocumentReleaseStatus["RELEASED"] = "RELEASED";
    DocumentReleaseStatus["UNKNOWN"] = "UNKNOWN";
})(DocumentReleaseStatus || (DocumentReleaseStatus = {}));
export var DocumentType;
(function (DocumentType) {
    DocumentType["ACCEPTANCE_DATA_PACK"] = "ACCEPTANCE_DATA_PACK";
    DocumentType["BARCODE_DECLARATION"] = "BARCODE_DECLARATION";
    DocumentType["CONFIGURATION_CHANGE_ORDER"] = "CONFIGURATION_CHANGE_ORDER";
    DocumentType["CR"] = "CR";
    DocumentType["DELIVERY_ORDER"] = "DELIVERY_ORDER";
    DocumentType["DISCREPANCY_REPORT"] = "DISCREPANCY_REPORT";
    DocumentType["DRAWING"] = "DRAWING";
    DocumentType["EASY_TRANSFER"] = "EASY_TRANSFER";
    DocumentType["ELABEL"] = "ELABEL";
    DocumentType["ELROD"] = "ELROD";
    DocumentType["ENGINEERING_CHANGE"] = "ENGINEERING_CHANGE";
    DocumentType["FAILURE_INVESTIGATION_ANOMALY_REPORT"] = "FAILURE_INVESTIGATION_ANOMALY_REPORT";
    DocumentType["GCAR"] = "GCAR";
    DocumentType["HAZARD_RESPONSE_LEVEL"] = "HAZARD_RESPONSE_LEVEL";
    DocumentType["IRREGULAR_PARTS_AUTHORIZATION_REQUEST"] = "IRREGULAR_PARTS_AUTHORIZATION_REQUEST";
    DocumentType["ITEM_FOR_INVESTIGATION"] = "ITEM_FOR_INVESTIGATION";
    DocumentType["MATERIAL_USAGE_AGREEMENT"] = "MATERIAL_USAGE_AGREEMENT";
    DocumentType["NASA_SPACE_PART_AUTHORIZATION_REQUEST"] = "NASA_SPACE_PART_AUTHORIZATION_REQUEST";
    DocumentType["NINE_ZERO_SIX"] = "NINE_ZERO_SIX";
    DocumentType["OPS_NOM_DECLARATION"] = "OPS_NOM_DECLARATION";
    DocumentType["OTHER_DOCUMENT"] = "OTHER_DOCUMENT";
    DocumentType["OVER_DUE_TAG"] = "OVER_DUE_TAG";
    DocumentType["PROBLEM_REPORTING_AND_CORRECTIVE_ACTION"] = "PROBLEM_REPORTING_AND_CORRECTIVE_ACTION";
    DocumentType["PROCEDURE"] = "PROCEDURE";
    DocumentType["RSA"] = "RSA";
    DocumentType["TPS"] = "TPS";
    DocumentType["VEHICLE_CERTIFICATION"] = "VEHICLE_CERTIFICATION";
    DocumentType["WAIVER"] = "WAIVER";
    DocumentType["YELLOW_TAG_NOTICE"] = "YELLOW_TAG_NOTICE";
    DocumentType["C_DIGITAL_RECORD_REVIEW_ITEM"] = "C_DIGITAL_RECORD_REVIEW_ITEM";
    DocumentType["C_DISCREPANCY_REPORT"] = "C_DISCREPANCY_REPORT";
    DocumentType["C_EASY_TRANSFER"] = "C_EASY_TRANSFER";
    DocumentType["C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM"] = "C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM";
    DocumentType["C_RCA"] = "C_RCA";
    DocumentType["C_TPS"] = "C_TPS";
    DocumentType["C_WORKFLOW"] = "C_WORKFLOW";
})(DocumentType || (DocumentType = {}));
export var DrDocumentClosureRationale;
(function (DrDocumentClosureRationale) {
    DrDocumentClosureRationale["NON_MRB"] = "NON_MRB";
    DrDocumentClosureRationale["MAJOR_MRB"] = "MAJOR_MRB";
    DrDocumentClosureRationale["UNDETERMINED"] = "UNDETERMINED";
    DrDocumentClosureRationale["MINOR_MRB"] = "MINOR_MRB";
    DrDocumentClosureRationale["PMRB"] = "PMRB";
    DrDocumentClosureRationale["WRITTEN_IN_ERROR_NOT_VALID"] = "WRITTEN_IN_ERROR_NOT_VALID";
})(DrDocumentClosureRationale || (DrDocumentClosureRationale = {}));
export var EntityType;
(function (EntityType) {
    EntityType["ACTIVITY"] = "ACTIVITY";
    EntityType["ASSEMBLY_TEMPLATE"] = "ASSEMBLY_TEMPLATE";
    EntityType["CHANGE_SET"] = "CHANGE_SET";
    EntityType["CREW"] = "CREW";
    EntityType["DELIVERY_MATRIX_ITEM"] = "DELIVERY_MATRIX_ITEM";
    EntityType["DIGITAL_LIBRARY_RECORD"] = "DIGITAL_LIBRARY_RECORD";
    EntityType["DIGITAL_LIBRARY_REVISION"] = "DIGITAL_LIBRARY_REVISION";
    EntityType["DOCUMENT"] = "DOCUMENT";
    EntityType["EVENT"] = "EVENT";
    EntityType["HARDWARE_LIST"] = "HARDWARE_LIST";
    EntityType["ITEM_DRAWING"] = "ITEM_DRAWING";
    EntityType["ITEM_INSTANCE"] = "ITEM_INSTANCE";
    EntityType["ITEM_MASTER"] = "ITEM_MASTER";
    EntityType["MEETING"] = "MEETING";
    EntityType["MEETING_ITEM"] = "MEETING_ITEM";
    EntityType["MEETING_SERIES"] = "MEETING_SERIES";
    EntityType["NON_ACTUALIZED_ITEM_INSTANCE"] = "NON_ACTUALIZED_ITEM_INSTANCE";
    EntityType["ORGANIZATION"] = "ORGANIZATION";
    EntityType["PHYSICAL_INVENTORY_AUDIT"] = "PHYSICAL_INVENTORY_AUDIT";
    EntityType["PHYSICAL_INVENTORY_AUDIT_ITEM"] = "PHYSICAL_INVENTORY_AUDIT_ITEM";
    EntityType["RISK"] = "RISK";
    EntityType["RISK_MITIGATION"] = "RISK_MITIGATION";
    EntityType["TRANSACTION"] = "TRANSACTION";
})(EntityType || (EntityType = {}));
export var EventRecurrenceType;
(function (EventRecurrenceType) {
    EventRecurrenceType["NONE"] = "NONE";
    EventRecurrenceType["MONTHLY"] = "MONTHLY";
    EventRecurrenceType["ANNUALLY"] = "ANNUALLY";
    EventRecurrenceType["AS_REQUIRED"] = "AS_REQUIRED";
})(EventRecurrenceType || (EventRecurrenceType = {}));
export var EventType;
(function (EventType) {
    EventType["ALLOCATION_WINDOW"] = "ALLOCATION_WINDOW";
    EventType["CHAMBER"] = "CHAMBER";
    EventType["DIGITAL_RECORD_REVIEW"] = "DIGITAL_RECORD_REVIEW";
    EventType["DISPOSAL"] = "DISPOSAL";
    EventType["EVA"] = "EVA";
    EventType["FIT_CHECK"] = "FIT_CHECK";
    EventType["GENERAL"] = "GENERAL";
    EventType["GLOVE_FIT_CHECK"] = "GLOVE_FIT_CHECK";
    EventType["LAUNCH"] = "LAUNCH";
    EventType["MISHAP"] = "MISHAP";
    EventType["RETURN"] = "RETURN";
    EventType["TRAINING"] = "TRAINING";
    EventType["WAIVER"] = "WAIVER";
})(EventType || (EventType = {}));
export var FederalSupplyClass;
(function (FederalSupplyClass) {
    FederalSupplyClass["ABRASIVE_MATERIALS"] = "ABRASIVE_MATERIALS";
    FederalSupplyClass["ACCESSORIES_FOR_SECONDARY_METALWORKING"] = "ACCESSORIES_FOR_SECONDARY_METALWORKING";
    FederalSupplyClass["ACCOUNTING_AND_CALCULATING_MACHINES"] = "ACCOUNTING_AND_CALCULATING_MACHINES";
    FederalSupplyClass["ADDITIVE_METAL_MATERIALS_AND_MASTER_ALLOYS"] = "ADDITIVE_METAL_MATERIALS_AND_MASTER_ALLOYS";
    FederalSupplyClass["ADHESIVES"] = "ADHESIVES";
    FederalSupplyClass["ADPE_SYSTEMS_CONFIGURATION"] = "ADPE_SYSTEMS_CONFIGURATION";
    FederalSupplyClass["ADP_CENTRAL_PROCESSING_UNIT_CC_ANALOG"] = "ADP_CENTRAL_PROCESSING_UNIT_CC_ANALOG";
    FederalSupplyClass["ADP_CENTRAL_PROCESSING_UNIT_CC_DIGITAL"] = "ADP_CENTRAL_PROCESSING_UNIT_CC_DIGITAL";
    FederalSupplyClass["ADP_CENTRAL_PROCESSING_UNIT_CC_HYBRID"] = "ADP_CENTRAL_PROCESSING_UNIT_CC_HYBRID";
    FederalSupplyClass["ADP_COMPONENTS"] = "ADP_COMPONENTS";
    FederalSupplyClass["ADP_INPUT_OUTPUT_AND_STORAGE_DEVICES"] = "ADP_INPUT_OUTPUT_AND_STORAGE_DEVICES";
    FederalSupplyClass["ADP_SOFTWARE"] = "ADP_SOFTWARE";
    FederalSupplyClass["ADP_SUPPLIES"] = "ADP_SUPPLIES";
    FederalSupplyClass["ADP_SUPPORT_EQUIPMENT"] = "ADP_SUPPORT_EQUIPMENT";
    FederalSupplyClass["AIRCRAFT_ALARM_AND_SIGNAL_SYSTEMS"] = "AIRCRAFT_ALARM_AND_SIGNAL_SYSTEMS";
    FederalSupplyClass["AIRCRAFT_MAINTENANCE_AND_REPAIR_SHOP"] = "AIRCRAFT_MAINTENANCE_AND_REPAIR_SHOP";
    FederalSupplyClass["AIR_CONDITIONING_EQUIPMENT"] = "AIR_CONDITIONING_EQUIPMENT";
    FederalSupplyClass["AIR_PURIFICATION_EQUIPMENT"] = "AIR_PURIFICATION_EQUIPMENT";
    FederalSupplyClass["AMIMAL_DRAWN_VEHICLES_AND_FARM_TRAILERS"] = "AMIMAL_DRAWN_VEHICLES_AND_FARM_TRAILERS";
    FederalSupplyClass["AMMUNITION_AND_NUCLEAR_ORDNANCE_BOXES"] = "AMMUNITION_AND_NUCLEAR_ORDNANCE_BOXES";
    FederalSupplyClass["AMMUNITION_MAINTENANCE_REPAIR_AND_CHECKOUT"] = "AMMUNITION_MAINTENANCE_REPAIR_AND_CHECKOUT";
    FederalSupplyClass["ANTENNAS_WAVEGUIDE_AND_RELATED_EQUIPMENT"] = "ANTENNAS_WAVEGUIDE_AND_RELATED_EQUIPMENT";
    FederalSupplyClass["ARMAMENT_TRAINING_DEVICES"] = "ARMAMENT_TRAINING_DEVICES";
    FederalSupplyClass["ARMOR_PERSONAL"] = "ARMOR_PERSONAL";
    FederalSupplyClass["ATHLETIC_AND_SPORTING_EQUIPMENT"] = "ATHLETIC_AND_SPORTING_EQUIPMENT";
    FederalSupplyClass["ATTENUATORS"] = "ATTENUATORS";
    FederalSupplyClass["AUTOMATIC_PILOT_MECHANISMS_AND_AIRBORNE"] = "AUTOMATIC_PILOT_MECHANISMS_AND_AIRBORNE";
    FederalSupplyClass["BADGES_AND_INSIGNIA"] = "BADGES_AND_INSIGNIA";
    FederalSupplyClass["BAGS_AND_SACKS"] = "BAGS_AND_SACKS";
    FederalSupplyClass["BAKERY_AND_CEREAL_PRODUCTS"] = "BAKERY_AND_CEREAL_PRODUCTS";
    FederalSupplyClass["BALLASTS_LAMPHOLDERS_AND_STARTERS"] = "BALLASTS_LAMPHOLDERS_AND_STARTERS";
    FederalSupplyClass["BARS_AND_RODS_IRON_AND_STEEL"] = "BARS_AND_RODS_IRON_AND_STEEL";
    FederalSupplyClass["BARS_AND_RODS_NONFERROUS_BASE_METAL"] = "BARS_AND_RODS_NONFERROUS_BASE_METAL";
    FederalSupplyClass["BATTERIES_NONRECHARGEABLE"] = "BATTERIES_NONRECHARGEABLE";
    FederalSupplyClass["BATTERIES_RECHARGEABLE"] = "BATTERIES_RECHARGEABLE";
    FederalSupplyClass["BEARINGS_ANTIFRICTION_UNMOUNTED"] = "BEARINGS_ANTIFRICTION_UNMOUNTED";
    FederalSupplyClass["BEARINGS_PLAIN_UNMOUNTED"] = "BEARINGS_PLAIN_UNMOUNTED";
    FederalSupplyClass["BEARINGS_UNMOUNTED"] = "BEARINGS_UNMOUNTED";
    FederalSupplyClass["BELTING_DRIVE_BELTS_FAN_BELTS_AND_ACCESSORIES"] = "BELTING_DRIVE_BELTS_FAN_BELTS_AND_ACCESSORIES";
    FederalSupplyClass["BENDING_AND_FORMING_MACHINES"] = "BENDING_AND_FORMING_MACHINES";
    FederalSupplyClass["BEVERAGES_ALCOHOLIC"] = "BEVERAGES_ALCOHOLIC";
    FederalSupplyClass["BEVERAGES_NONALCOHOLIC_H20_WATER"] = "BEVERAGES_NONALCOHOLIC_H20_WATER";
    FederalSupplyClass["BLOCKS_TACKLE_RIGGING_AND_SLINGS"] = "BLOCKS_TACKLE_RIGGING_AND_SLINGS";
    FederalSupplyClass["BOLTS"] = "BOLTS";
    FederalSupplyClass["BOOKS_AND_PAMPHLETS"] = "BOOKS_AND_PAMPHLETS";
    FederalSupplyClass["BORING_MACHINES"] = "BORING_MACHINES";
    FederalSupplyClass["BOTTLES_AND_JARS"] = "BOTTLES_AND_JARS";
    FederalSupplyClass["BOXES_CARTONS_AND_CRATES"] = "BOXES_CARTONS_AND_CRATES";
    FederalSupplyClass["BRACKETS"] = "BRACKETS";
    FederalSupplyClass["BRIDGES_FIXED_AND_FLOATING"] = "BRIDGES_FIXED_AND_FLOATING";
    FederalSupplyClass["BROACHING_MACHINES"] = "BROACHING_MACHINES";
    FederalSupplyClass["BROOMS_BRUSHES_MOPS_AND_SPONGES"] = "BROOMS_BRUSHES_MOPS_AND_SPONGES";
    FederalSupplyClass["BUILDING_COMPONENTS_PREFABRICATED"] = "BUILDING_COMPONENTS_PREFABRICATED";
    FederalSupplyClass["BUILDING_GLASS_TILE_BRICK_AND_BLOCK"] = "BUILDING_GLASS_TILE_BRICK_AND_BLOCK";
    FederalSupplyClass["BUSHINGS_RINGS_SHIMS_AND_SPACERS"] = "BUSHINGS_RINGS_SHIMS_AND_SPACERS";
    FederalSupplyClass["CABINETS_LOCKERS_BINS_AND_SHELVING"] = "CABINETS_LOCKERS_BINS_AND_SHELVING";
    FederalSupplyClass["CABLE_CORD_AND_WIRE_ASSEMBLIES_COMMUNICATION"] = "CABLE_CORD_AND_WIRE_ASSEMBLIES_COMMUNICATION";
    FederalSupplyClass["CAMERAS_MOTION_PICTURE"] = "CAMERAS_MOTION_PICTURE";
    FederalSupplyClass["CAMERAS_STILL_PICTURE"] = "CAMERAS_STILL_PICTURE";
    FederalSupplyClass["CAMOUFLAGE_AND_DECEPTION_EQUIPMENT"] = "CAMOUFLAGE_AND_DECEPTION_EQUIPMENT";
    FederalSupplyClass["CAPACITORS"] = "CAPACITORS";
    FederalSupplyClass["CARTRIDGE_AND_PROPELLANT_ACTUATED_DEVICES"] = "CARTRIDGE_AND_PROPELLANT_ACTUATED_DEVICES";
    FederalSupplyClass["CENTRIFUGALS_SEPARATORS_H20_FILTER_AND_PRESSURE"] = "CENTRIFUGALS_SEPARATORS_H20_FILTER_AND_PRESSURE";
    FederalSupplyClass["CHAIN_AND_WIRE_ROPE"] = "CHAIN_AND_WIRE_ROPE";
    FederalSupplyClass["CHEMICALS"] = "CHEMICALS";
    FederalSupplyClass["CHEMICAL_ANALYSIS_INSTRUMENTS"] = "CHEMICAL_ANALYSIS_INSTRUMENTS";
    FederalSupplyClass["CHEMICAL_AND_PHARMACEUTICAL_PRODUCTS"] = "CHEMICAL_AND_PHARMACEUTICAL_PRODUCTS";
    FederalSupplyClass["CHILDRENS_AND_INFANTS_APPAREL_ACCESSORIES"] = "CHILDRENS_AND_INFANTS_APPAREL_ACCESSORIES";
    FederalSupplyClass["CIRCUIT_BREAKERS"] = "CIRCUIT_BREAKERS";
    FederalSupplyClass["CLAY_AND_CONCRETE_PRODUCTS_INDUSTRIES"] = "CLAY_AND_CONCRETE_PRODUCTS_INDUSTRIES";
    FederalSupplyClass["CLEANING_AND_POLISHING_COMPOUNDS"] = "CLEANING_AND_POLISHING_COMPOUNDS";
    FederalSupplyClass["CLEAN_WORK_STATIONS_FLOWBENCH_CONTROLLED_ENVIR"] = "CLEAN_WORK_STATIONS_FLOWBENCH_CONTROLLED_ENVIR";
    FederalSupplyClass["CLOTHING_SPECIAL_PURPOSE"] = "CLOTHING_SPECIAL_PURPOSE";
    FederalSupplyClass["COFFEE_TEA_AND_COCOA"] = "COFFEE_TEA_AND_COCOA";
    FederalSupplyClass["COILS_AND_TRANSFORMERS"] = "COILS_AND_TRANSFORMERS";
    FederalSupplyClass["COILFLAT_AND_WIRE_SPRINGS"] = "COILFLAT_AND_WIRE_SPRINGS";
    FederalSupplyClass["COIL_FLAT_AND_WIRE_SPRINGS"] = "COIL_FLAT_AND_WIRE_SPRINGS";
    FederalSupplyClass["COLLECTORS_AND_OR_HISTORICAL_ITEMS"] = "COLLECTORS_AND_OR_HISTORICAL_ITEMS";
    FederalSupplyClass["COMBINATION_AND_MISCELLANEOUS_INSTRUMENTS"] = "COMBINATION_AND_MISCELLANEOUS_INSTRUMENTS";
    FederalSupplyClass["COMMERCIAL_AND_INDUSTIAL_GAS_CYLINDERS"] = "COMMERCIAL_AND_INDUSTIAL_GAS_CYLINDERS";
    FederalSupplyClass["COMMUNICATION_TRAINING_DEVICES"] = "COMMUNICATION_TRAINING_DEVICES";
    FederalSupplyClass["COMPOSITE_FOOD_PACKAGES"] = "COMPOSITE_FOOD_PACKAGES";
    FederalSupplyClass["COMPRESSORS_AND_VACUUM_PUMPS"] = "COMPRESSORS_AND_VACUUM_PUMPS";
    FederalSupplyClass["CONDIMENTS_AND_RELATED_PRODUCTS"] = "CONDIMENTS_AND_RELATED_PRODUCTS";
    FederalSupplyClass["CONNECTORS_ELECTRICAL"] = "CONNECTORS_ELECTRICAL";
    FederalSupplyClass["CONVERTERS_ELECTRICAL_NONROTATING"] = "CONVERTERS_ELECTRICAL_NONROTATING";
    FederalSupplyClass["CONVERTERS_ELECTRICAL_ROTATING_RESIDUE"] = "CONVERTERS_ELECTRICAL_ROTATING_RESIDUE";
    FederalSupplyClass["CONVEYORS"] = "CONVEYORS";
    FederalSupplyClass["COUPLERS_SPLITTERS_AND_MIXERS"] = "COUPLERS_SPLITTERS_AND_MIXERS";
    FederalSupplyClass["CRANES_AND_CRANE_SHOVELS"] = "CRANES_AND_CRANE_SHOVELS";
    FederalSupplyClass["CRANE_AND_CRANE_SHOVEL_ATTACHMENTS"] = "CRANE_AND_CRANE_SHOVEL_ATTACHMENTS";
    FederalSupplyClass["CRUDE_GRADES_OF_PLANT_MATERIALS"] = "CRUDE_GRADES_OF_PLANT_MATERIALS";
    FederalSupplyClass["CRYSTAL_AND_GLASS_INDUSTRIES_MACHINERY"] = "CRYSTAL_AND_GLASS_INDUSTRIES_MACHINERY";
    FederalSupplyClass["COMMUNICATIONS_SECURITY_EQUIPMENT"] = "COMMUNICATIONS_SECURITY_EQUIPMENT";
    FederalSupplyClass["CUTLERY_AND_FLATWARE"] = "CUTLERY_AND_FLATWARE";
    FederalSupplyClass["CUTTING_AND_FORMING_TOOLS_FOR_SECONDARY"] = "CUTTING_AND_FORMING_TOOLS_FOR_SECONDARY";
    FederalSupplyClass["CUTTING_TOOLS_AND_MACHINE_TOOLS"] = "CUTTING_TOOLS_AND_MACHINE_TOOLS";
    FederalSupplyClass["DAIRY_FOODS_AND_EGGS"] = "DAIRY_FOODS_AND_EGGS";
    FederalSupplyClass["DAIRY_POULTRY_AND_LIVESTOCK_EQUIPMENT"] = "DAIRY_POULTRY_AND_LIVESTOCK_EQUIPMENT";
    FederalSupplyClass["DECONTAMINATING_AND_IMPREGNATING_EQUIPMENT"] = "DECONTAMINATING_AND_IMPREGNATING_EQUIPMENT";
    FederalSupplyClass["DENTAL_INSTRUMENTS_EQUIPMENT_AND_SUPPLIES"] = "DENTAL_INSTRUMENTS_EQUIPMENT_AND_SUPPLIES";
    FederalSupplyClass["DEPTH_CHARGES_AND_UNDERWATER_MINES_MAINTENANCE"] = "DEPTH_CHARGES_AND_UNDERWATER_MINES_MAINTENANCE";
    FederalSupplyClass["DISKS_AND_STONES_ABRASIVE"] = "DISKS_AND_STONES_ABRASIVE";
    FederalSupplyClass["DRAFTING_SURVEYING_AND_MAPPING_INSTRUMENTS"] = "DRAFTING_SURVEYING_AND_MAPPING_INSTRUMENTS";
    FederalSupplyClass["DRAPERIES_AWNINGS_AND_SHADES"] = "DRAPERIES_AWNINGS_AND_SHADES";
    FederalSupplyClass["DRAWINGS_AND_SPECIFICATIONS"] = "DRAWINGS_AND_SPECIFICATIONS";
    FederalSupplyClass["DRIERS_DEHYDRATORS_AND_ANHYDRATORS"] = "DRIERS_DEHYDRATORS_AND_ANHYDRATORS";
    FederalSupplyClass["DRILLING_AND_TAPPING_MACHINES"] = "DRILLING_AND_TAPPING_MACHINES";
    FederalSupplyClass["DRILL_BITS_COUNTERBORES_AND_COUNTERSINKS"] = "DRILL_BITS_COUNTERBORES_AND_COUNTERSINKS";
    FederalSupplyClass["DRUGS_AND_BIOLOGICALS"] = "DRUGS_AND_BIOLOGICALS";
    FederalSupplyClass["DRUMS_AND_CANS"] = "DRUMS_AND_CANS";
    FederalSupplyClass["DYES"] = "DYES";
    FederalSupplyClass["EARTH_MOVING_AND_EXCAVATING_EQUIPMENT"] = "EARTH_MOVING_AND_EXCAVATING_EQUIPMENT";
    FederalSupplyClass["ECCLESIASTICAL_EQUIPMENT_FURNISHINGS"] = "ECCLESIASTICAL_EQUIPMENT_FURNISHINGS";
    FederalSupplyClass["ELECTRICAL_AND_ELECTRONIC_ASSEMBLIES_BOARDS"] = "ELECTRICAL_AND_ELECTRONIC_ASSEMBLIES_BOARDS";
    FederalSupplyClass["ELECTRICAL_AND_ELECTRONIC_PROPERTIES"] = "ELECTRICAL_AND_ELECTRONIC_PROPERTIES";
    FederalSupplyClass["ELECTRICAL_AND_ULTRASONIC_EROSION_MACHINES"] = "ELECTRICAL_AND_ULTRASONIC_EROSION_MACHINES";
    FederalSupplyClass["ELECTRICAL_CONTACT_BRUSHES_AND_ELECTRODES"] = "ELECTRICAL_CONTACT_BRUSHES_AND_ELECTRODES";
    FederalSupplyClass["ELECTRICAL_CONTROL_EQUIPMENT"] = "ELECTRICAL_CONTROL_EQUIPMENT";
    FederalSupplyClass["ELECTRICAL_HARDWARE_AND_SUPPLIES"] = "ELECTRICAL_HARDWARE_AND_SUPPLIES";
    FederalSupplyClass["ELECTRICAL_INSULATORS_AND_INSULATING_MATERIALS"] = "ELECTRICAL_INSULATORS_AND_INSULATING_MATERIALS";
    FederalSupplyClass["ELECTRIC_ARC_WELDING_EQUIPMENT"] = "ELECTRIC_ARC_WELDING_EQUIPMENT";
    FederalSupplyClass["ELECTRIC_LAMPS"] = "ELECTRIC_LAMPS";
    FederalSupplyClass["ELECTRIC_PORTABLE_AND_HAND_LIGHTING_EQUIPMENT"] = "ELECTRIC_PORTABLE_AND_HAND_LIGHTING_EQUIPMENT";
    FederalSupplyClass["ELECTRIC_RESISTANCE_WELDING_EQUIPMENT"] = "ELECTRIC_RESISTANCE_WELDING_EQUIPMENT";
    FederalSupplyClass["ELECTRIC_VEHICULAR_LIGHTS_AND_FIXTURES"] = "ELECTRIC_VEHICULAR_LIGHTS_AND_FIXTURES";
    FederalSupplyClass["ELECTRONIC_COUNTERMEASURES_COUNTER"] = "ELECTRONIC_COUNTERMEASURES_COUNTER";
    FederalSupplyClass["ELECTRONIC_MODULES"] = "ELECTRONIC_MODULES";
    FederalSupplyClass["ELECTRON_TUBES_ASSOCIATED_HARDWARE"] = "ELECTRON_TUBES_ASSOCIATED_HARDWARE";
    FederalSupplyClass["ELEVATORS_AND_ESCALATORS"] = "ELEVATORS_AND_ESCALATORS";
    FederalSupplyClass["ENGINE_AIR_AND_OIL_FILTERS_STRAINERS_NONAIRCRAFT"] = "ENGINE_AIR_AND_OIL_FILTERS_STRAINERS_NONAIRCRAFT";
    FederalSupplyClass["ENGINE_COOLING_SYSTEM_COMPONENTS_NONAIRCRAFT"] = "ENGINE_COOLING_SYSTEM_COMPONENTS_NONAIRCRAFT";
    FederalSupplyClass["ENGINE_ELECTRICAL_SYSTEM_COMPONENTS_NONAIRCRAFT"] = "ENGINE_ELECTRICAL_SYSTEM_COMPONENTS_NONAIRCRAFT";
    FederalSupplyClass["ENGINE_INSTRUMENTS"] = "ENGINE_INSTRUMENTS";
    FederalSupplyClass["ENVIRONMENTAL_CHAMBERS_AND_RELATED_EQUIPMENT"] = "ENVIRONMENTAL_CHAMBERS_AND_RELATED_EQUIPMENT";
    FederalSupplyClass["EXPLOSIVE_AND_PYROTECHNIC_COMPONENTS"] = "EXPLOSIVE_AND_PYROTECHNIC_COMPONENTS";
    FederalSupplyClass["FANS_AIR_CIRCULATORS_AND_BLOWER_EQUIPMENT"] = "FANS_AIR_CIRCULATORS_AND_BLOWER_EQUIPMENT";
    FederalSupplyClass["FASTENING_DEVICES"] = "FASTENING_DEVICES";
    FederalSupplyClass["FENCING_FENCES_AND_GATES"] = "FENCING_FENCES_AND_GATES";
    FederalSupplyClass["FERTILIZERS"] = "FERTILIZERS";
    FederalSupplyClass["FIBERS_VEGETABLE_ANIMAL_AND_SYNTHETIC"] = "FIBERS_VEGETABLE_ANIMAL_AND_SYNTHETIC";
    FederalSupplyClass["FIBER_OPIC_LIGHT_TRANSFER_AND_IMAGE_TRANSFER"] = "FIBER_OPIC_LIGHT_TRANSFER_AND_IMAGE_TRANSFER";
    FederalSupplyClass["FIBER_OPTIC_ACCESSORIES_AND_SUPPLIES"] = "FIBER_OPTIC_ACCESSORIES_AND_SUPPLIES";
    FederalSupplyClass["FIBER_OPTIC_CABLES"] = "FIBER_OPTIC_CABLES";
    FederalSupplyClass["FIBER_OPTIC_CABLE_ASSEMBLIES_AND_HARNESSES"] = "FIBER_OPTIC_CABLE_ASSEMBLIES_AND_HARNESSES";
    FederalSupplyClass["FIBER_OPTIC_CONDUCTORS"] = "FIBER_OPTIC_CONDUCTORS";
    FederalSupplyClass["FIBER_OPTIC_DEVICES"] = "FIBER_OPTIC_DEVICES";
    FederalSupplyClass["FIBER_OPTIC_INTERCONNECTORS"] = "FIBER_OPTIC_INTERCONNECTORS";
    FederalSupplyClass["FIBER_OPTIC_KITS_AND_SETS"] = "FIBER_OPTIC_KITS_AND_SETS";
    FederalSupplyClass["FIBER_OPTIC_LIGHT_SOURCES"] = "FIBER_OPTIC_LIGHT_SOURCES";
    FederalSupplyClass["FIBER_OPTIC_MODULATORS_DEMODULATORS"] = "FIBER_OPTIC_MODULATORS_DEMODULATORS";
    FederalSupplyClass["FIBER_OPTIC_PASSIVE_DEVICES"] = "FIBER_OPTIC_PASSIVE_DEVICES";
    FederalSupplyClass["FIBER_OPTIC_PHOTO_DETECTORS"] = "FIBER_OPTIC_PHOTO_DETECTORS";
    FederalSupplyClass["FIBER_OPTIC_RECEIVERS"] = "FIBER_OPTIC_RECEIVERS";
    FederalSupplyClass["FIBER_OPTIC_SENSORS"] = "FIBER_OPTIC_SENSORS";
    FederalSupplyClass["FIBER_OPTIC_SWITCHES"] = "FIBER_OPTIC_SWITCHES";
    FederalSupplyClass["FIBER_OPTIC_TRANSMITTERS"] = "FIBER_OPTIC_TRANSMITTERS";
    FederalSupplyClass["FIBER_ROPE_CORDAGE_AND_TWINE"] = "FIBER_ROPE_CORDAGE_AND_TWINE";
    FederalSupplyClass["FILM_PROCESSED"] = "FILM_PROCESSED";
    FederalSupplyClass["FILTERS"] = "FILTERS";
    FederalSupplyClass["FILTERS_AND_NETWORKS"] = "FILTERS_AND_NETWORKS";
    FederalSupplyClass["FIRE_CONTROL_MAINTENANCE_AND_REPAIR_SHOP"] = "FIRE_CONTROL_MAINTENANCE_AND_REPAIR_SHOP";
    FederalSupplyClass["FIRE_FIGHTING_EQUIPMENT"] = "FIRE_FIGHTING_EQUIPMENT";
    FederalSupplyClass["FITTINGS_AND_SPECIALTIES_HOSE_PIPEAND_TUBE"] = "FITTINGS_AND_SPECIALTIES_HOSE_PIPEAND_TUBE";
    FederalSupplyClass["FITTINGS_FOR_ROPE_CABLE_AND_CHAIN"] = "FITTINGS_FOR_ROPE_CABLE_AND_CHAIN";
    FederalSupplyClass["FLAGS_AND_PENNANTS"] = "FLAGS_AND_PENNANTS";
    FederalSupplyClass["FLIGHT_INSTRUMENTS"] = "FLIGHT_INSTRUMENTS";
    FederalSupplyClass["FLOOR_COVERINGS"] = "FLOOR_COVERINGS";
    FederalSupplyClass["FLOOR_POLISHERS_AND_VACUUM_CLEANING_EQUIPMENT"] = "FLOOR_POLISHERS_AND_VACUUM_CLEANING_EQUIPMENT";
    FederalSupplyClass["FOOD_COOKING_BAKING_AND_SERVING_EQUIPMENT"] = "FOOD_COOKING_BAKING_AND_SERVING_EQUIPMENT";
    FederalSupplyClass["FOOD_OILS_AND_FATS"] = "FOOD_OILS_AND_FATS";
    FederalSupplyClass["FOOD_PRODUCTS_MACHINERY_AND_EQUIPMENT"] = "FOOD_PRODUCTS_MACHINERY_AND_EQUIPMENT";
    FederalSupplyClass["FOOTWEAR_MENS"] = "FOOTWEAR_MENS";
    FederalSupplyClass["FOOTWEAR_WOMENS"] = "FOOTWEAR_WOMENS";
    FederalSupplyClass["FORAGE_AND_FEED"] = "FORAGE_AND_FEED";
    FederalSupplyClass["FORGING_MACHINERY_AND_HAMMERS"] = "FORGING_MACHINERY_AND_HAMMERS";
    FederalSupplyClass["FOUNDRY_MACHINERY_RELATED_EQUIPMENT_AND_SUPPLIES"] = "FOUNDRY_MACHINERY_RELATED_EQUIPMENT_AND_SUPPLIES";
    FederalSupplyClass["FRUITS_AND_VEGETABLES"] = "FRUITS_AND_VEGETABLES";
    FederalSupplyClass["FUELS_SOLID"] = "FUELS_SOLID";
    FederalSupplyClass["FUEL_BURNING_EQUIPMENT_UNITS"] = "FUEL_BURNING_EQUIPMENT_UNITS";
    FederalSupplyClass["FUEL_CELL_POWER_UNITS_COMPONENTS"] = "FUEL_CELL_POWER_UNITS_COMPONENTS";
    FederalSupplyClass["FUEL_OILS"] = "FUEL_OILS";
    FederalSupplyClass["FUR_MATERIALS"] = "FUR_MATERIALS";
    FederalSupplyClass["FUSES_ARRESTERS_ABSORBERS_AND_PROTECTORS"] = "FUSES_ARRESTERS_ABSORBERS_AND_PROTECTORS";
    FederalSupplyClass["GAMES_TOYS_AND_WHEELED_GOODS"] = "GAMES_TOYS_AND_WHEELED_GOODS";
    FederalSupplyClass["GARDENING_IMPLEMENTS_AND_TOOLS"] = "GARDENING_IMPLEMENTS_AND_TOOLS";
    FederalSupplyClass["GASES_COMPRESSED_AND_LIQUEFIED"] = "GASES_COMPRESSED_AND_LIQUEFIED";
    FederalSupplyClass["GAS_GENERATING_AND_DISPENSING_SYSTEMS_FIXED"] = "GAS_GENERATING_AND_DISPENSING_SYSTEMS_FIXED";
    FederalSupplyClass["GAS_WELDING_HEAT_CUTTING_AND_METALIZING"] = "GAS_WELDING_HEAT_CUTTING_AND_METALIZING";
    FederalSupplyClass["GEARS_PULLEYS_SPROCKETS_AND_TRANSMISSION"] = "GEARS_PULLEYS_SPROCKETS_AND_TRANSMISSION";
    FederalSupplyClass["GEAR_CUTTING_AND_FINISHING_MACHINES"] = "GEAR_CUTTING_AND_FINISHING_MACHINES";
    FederalSupplyClass["GENERATORS_AND_GENERATOR_SETS_ELECTRICAL"] = "GENERATORS_AND_GENERATOR_SETS_ELECTRICAL";
    FederalSupplyClass["GEOPHYSICAL_INSTRUMENTS"] = "GEOPHYSICAL_INSTRUMENTS";
    FederalSupplyClass["GLASS_FABRICATED_MATERIALS"] = "GLASS_FABRICATED_MATERIALS";
    FederalSupplyClass["GRINDING_MACHINES"] = "GRINDING_MACHINES";
    FederalSupplyClass["GROUP_10_WEAPONS"] = "GROUP_10_WEAPONS";
    FederalSupplyClass["GROUP_11_NUCLEAR_ORDNANCE"] = "GROUP_11_NUCLEAR_ORDNANCE";
    FederalSupplyClass["GROUP_12_FIRE_CONTROL_EQUIPMENT"] = "GROUP_12_FIRE_CONTROL_EQUIPMENT";
    FederalSupplyClass["GROUP_13_AMMUNITION_AND_EXPLOSIVES"] = "GROUP_13_AMMUNITION_AND_EXPLOSIVES";
    FederalSupplyClass["GROUP_14_GUIDED_MISSLES"] = "GROUP_14_GUIDED_MISSLES";
    FederalSupplyClass["GROUP_15_AIRCRAFT_AND_AIRFRAME_STRUCTURAL_EQUIP"] = "GROUP_15_AIRCRAFT_AND_AIRFRAME_STRUCTURAL_EQUIP";
    FederalSupplyClass["GROUP_16_AIRCRAFT_COMPONENTS_AND_ACCESSORIES"] = "GROUP_16_AIRCRAFT_COMPONENTS_AND_ACCESSORIES";
    FederalSupplyClass["GROUP_17_AIRCRAFT_LAUNCH_LAND_AND_GROUND_EQUIP"] = "GROUP_17_AIRCRAFT_LAUNCH_LAND_AND_GROUND_EQUIP";
    FederalSupplyClass["GROUP_19_SHIPS_SM_CRAFTS_PONTOONS_FLOATING_DOCKS"] = "GROUP_19_SHIPS_SM_CRAFTS_PONTOONS_FLOATING_DOCKS";
    FederalSupplyClass["GROUP_20_SHIP_AND_MARINE_EQUIPMENT"] = "GROUP_20_SHIP_AND_MARINE_EQUIPMENT";
    FederalSupplyClass["GROUP_22_RAILWAY_EQUIPMENT"] = "GROUP_22_RAILWAY_EQUIPMENT";
    FederalSupplyClass["GROUP_23_GROUND_AND_MOTOR_VEHICLES_TRAILERS_CYCLES"] = "GROUP_23_GROUND_AND_MOTOR_VEHICLES_TRAILERS_CYCLES";
    FederalSupplyClass["GROUP_24_TRACTORS"] = "GROUP_24_TRACTORS";
    FederalSupplyClass["GROUP_25_VEHICULAR_EQUIPMENT_COMPONENTS"] = "GROUP_25_VEHICULAR_EQUIPMENT_COMPONENTS";
    FederalSupplyClass["GROUP_26_TIRES_AND_TUBES"] = "GROUP_26_TIRES_AND_TUBES";
    FederalSupplyClass["GROUP_28_ENGINES_TURBINES_AND_COMPONENTS"] = "GROUP_28_ENGINES_TURBINES_AND_COMPONENTS";
    FederalSupplyClass["GROUP_29_ENGINE_ACCESSORIES"] = "GROUP_29_ENGINE_ACCESSORIES";
    FederalSupplyClass["GROUP_30_MECHANICAL_POWERED_TRANSMISSION_EQUIP"] = "GROUP_30_MECHANICAL_POWERED_TRANSMISSION_EQUIP";
    FederalSupplyClass["GROUP_31_BEARINGS"] = "GROUP_31_BEARINGS";
    FederalSupplyClass["GROUP_32_WOODWORKING_MACHINERY_AND_EQUIPMENT"] = "GROUP_32_WOODWORKING_MACHINERY_AND_EQUIPMENT";
    FederalSupplyClass["GROUP_34_METALWORKING_MACHINERY"] = "GROUP_34_METALWORKING_MACHINERY";
    FederalSupplyClass["GROUP_35_SERVICE_AND_TRADE_EQUIPMENT"] = "GROUP_35_SERVICE_AND_TRADE_EQUIPMENT";
    FederalSupplyClass["GROUP_36_SPECIAL_INDUSTRY_MACHINERY"] = "GROUP_36_SPECIAL_INDUSTRY_MACHINERY";
    FederalSupplyClass["GROUP_37_AGRICULTURAL_MACHINERY_AND_EQUIPMENT"] = "GROUP_37_AGRICULTURAL_MACHINERY_AND_EQUIPMENT";
    FederalSupplyClass["GROUP_38_CONSTRUCTION_MINING_EXCAVATING_ROAD_EQUIP"] = "GROUP_38_CONSTRUCTION_MINING_EXCAVATING_ROAD_EQUIP";
    FederalSupplyClass["GROUP_39_MATERIAL_HANDLING_EQUIPMENT"] = "GROUP_39_MATERIAL_HANDLING_EQUIPMENT";
    FederalSupplyClass["GROUP_40_ROPE_CABLE_CHAIN_AND_FITTINGS"] = "GROUP_40_ROPE_CABLE_CHAIN_AND_FITTINGS";
    FederalSupplyClass["GROUP_41_REFRIGERATION_AIRCONDITIONING_CIRC_EQUIP"] = "GROUP_41_REFRIGERATION_AIRCONDITIONING_CIRC_EQUIP";
    FederalSupplyClass["GROUP_42_FIRE_FIGHTING_RESCUE_AND_SAFETY_EQUIP"] = "GROUP_42_FIRE_FIGHTING_RESCUE_AND_SAFETY_EQUIP";
    FederalSupplyClass["GROUP_43_PUMPS_AND_COMPRESSORS"] = "GROUP_43_PUMPS_AND_COMPRESSORS";
    FederalSupplyClass["GROUP_44_FURNANCE_STEAM_DRYING_EQUIP_NUC_REACTOR"] = "GROUP_44_FURNANCE_STEAM_DRYING_EQUIP_NUC_REACTOR";
    FederalSupplyClass["GROUP_45_PLUMBING_HEATING_AND_SANITATION_EQUIP"] = "GROUP_45_PLUMBING_HEATING_AND_SANITATION_EQUIP";
    FederalSupplyClass["GROUP_46_WATER_PURIFICATION_EQUIPMENT"] = "GROUP_46_WATER_PURIFICATION_EQUIPMENT";
    FederalSupplyClass["GROUP_47_PIPE_TUBING_HOSE_AND_FITTINGS"] = "GROUP_47_PIPE_TUBING_HOSE_AND_FITTINGS";
    FederalSupplyClass["GROUP_48_VALVES"] = "GROUP_48_VALVES";
    FederalSupplyClass["GROUP_49_MAINTENANCE_AND_REPAIR_SHOP_EQUIP"] = "GROUP_49_MAINTENANCE_AND_REPAIR_SHOP_EQUIP";
    FederalSupplyClass["GROUP_51_HAND_TOOLS"] = "GROUP_51_HAND_TOOLS";
    FederalSupplyClass["GROUP_52_MEASURING_TOOLS"] = "GROUP_52_MEASURING_TOOLS";
    FederalSupplyClass["GROUP_53_HARDWARE_AND_ABRASIVES"] = "GROUP_53_HARDWARE_AND_ABRASIVES";
    FederalSupplyClass["GROUP_54_PREFABRICATED_STRUCTURES_AND_SCAFFOLDING"] = "GROUP_54_PREFABRICATED_STRUCTURES_AND_SCAFFOLDING";
    FederalSupplyClass["GROUP_55_LUMBER_MILLWORK_PLYWOOD_AND_VANEER"] = "GROUP_55_LUMBER_MILLWORK_PLYWOOD_AND_VANEER";
    FederalSupplyClass["GROUP_56_CONSTRUCTION_AND_BUILDING_MATERIAL"] = "GROUP_56_CONSTRUCTION_AND_BUILDING_MATERIAL";
    FederalSupplyClass["GROUP_58_COMMUNICATION_DETECTION_CO_RADIATION_EQUP"] = "GROUP_58_COMMUNICATION_DETECTION_CO_RADIATION_EQUP";
    FederalSupplyClass["GROUP_59_ELECTRICAL_ELECTRONIC_EQUIP_COMPONENTS"] = "GROUP_59_ELECTRICAL_ELECTRONIC_EQUIP_COMPONENTS";
    FederalSupplyClass["GROUP_60_FIBER_OPTIC_MAT_COMPONENTS_ASSY_ACCESSARY"] = "GROUP_60_FIBER_OPTIC_MAT_COMPONENTS_ASSY_ACCESSARY";
    FederalSupplyClass["GROUP_61_ELECTRIC_WIRE_POWER_DISTRIBUTION_EQUIP"] = "GROUP_61_ELECTRIC_WIRE_POWER_DISTRIBUTION_EQUIP";
    FederalSupplyClass["GROUP_62_LIGHTING_FIXTURES_AND_LAMPS"] = "GROUP_62_LIGHTING_FIXTURES_AND_LAMPS";
    FederalSupplyClass["GROUP_63_ALARM_SIGNAL_SECURITY_DETECTION_SYS"] = "GROUP_63_ALARM_SIGNAL_SECURITY_DETECTION_SYS";
    FederalSupplyClass["GROUP_65_MEDICAL_DENTAL_AND_VET_EQUIP_SUPPLIES"] = "GROUP_65_MEDICAL_DENTAL_AND_VET_EQUIP_SUPPLIES";
    FederalSupplyClass["GROUP_66_INSTRUMENTS_AND_LABRATORY_EQUIPMENT"] = "GROUP_66_INSTRUMENTS_AND_LABRATORY_EQUIPMENT";
    FederalSupplyClass["GROUP_67_PHOTOGRAPHIC_EQUIPMENT"] = "GROUP_67_PHOTOGRAPHIC_EQUIPMENT";
    FederalSupplyClass["GROUP_68_CHEMICALS_AND_CHEMICAL_PRODUCTS"] = "GROUP_68_CHEMICALS_AND_CHEMICAL_PRODUCTS";
    FederalSupplyClass["GROUP_69_TRAINING_AIDS_AND_DEVICES"] = "GROUP_69_TRAINING_AIDS_AND_DEVICES";
    FederalSupplyClass["GROUP_70_ADPE_EQUIP_SOFTWARE_SUPPLIES_AND_SUPPORT"] = "GROUP_70_ADPE_EQUIP_SOFTWARE_SUPPLIES_AND_SUPPORT";
    FederalSupplyClass["GROUP_71_FURNITURE"] = "GROUP_71_FURNITURE";
    FederalSupplyClass["GROUP_72_HOUSE_COMMERCIAL_FURNISHING_APPLIANCES"] = "GROUP_72_HOUSE_COMMERCIAL_FURNISHING_APPLIANCES";
    FederalSupplyClass["GROUP_73_FOOD_PREPARATION_AND_SERVICE_EQUIPMENT"] = "GROUP_73_FOOD_PREPARATION_AND_SERVICE_EQUIPMENT";
    FederalSupplyClass["GROUP_74_OFFICE_MACH_TEXT_VISIBLE_RECORD_EQUIP"] = "GROUP_74_OFFICE_MACH_TEXT_VISIBLE_RECORD_EQUIP";
    FederalSupplyClass["GROUP_75_OFFICE_SUPPLIES_AND_DEVICES"] = "GROUP_75_OFFICE_SUPPLIES_AND_DEVICES";
    FederalSupplyClass["GROUP_76_BOOKS_MAPS_AND_OTHER_PUBLICATIONS"] = "GROUP_76_BOOKS_MAPS_AND_OTHER_PUBLICATIONS";
    FederalSupplyClass["GROUP_77_MUSIC_INSTRUMENT_PHONOGRAPH_HOME_RADIO"] = "GROUP_77_MUSIC_INSTRUMENT_PHONOGRAPH_HOME_RADIO";
    FederalSupplyClass["GROUP_78_RECREATIONAL_AND_ATHLETIC_EQUIPMENT"] = "GROUP_78_RECREATIONAL_AND_ATHLETIC_EQUIPMENT";
    FederalSupplyClass["GROUP_79_CLEANING_EQUIPMENT_AND_SUPPLIES"] = "GROUP_79_CLEANING_EQUIPMENT_AND_SUPPLIES";
    FederalSupplyClass["GROUP_80_BRUSHES_PAINTS_SEALERS_AND_ADHESIVES"] = "GROUP_80_BRUSHES_PAINTS_SEALERS_AND_ADHESIVES";
    FederalSupplyClass["GROUP_81_CONTAINERS_PACKAGES_PACKING_SUPPLIES"] = "GROUP_81_CONTAINERS_PACKAGES_PACKING_SUPPLIES";
    FederalSupplyClass["GROUP_83_TEXTILESFURSAPPARELSHOESTENTSFLAGS"] = "GROUP_83_TEXTILESFURSAPPARELSHOESTENTSFLAGS";
    FederalSupplyClass["GROUP_84_CLOTHING_INDIVIUAL_EQUIPMENT_AND_INSIGNIA"] = "GROUP_84_CLOTHING_INDIVIUAL_EQUIPMENT_AND_INSIGNIA";
    FederalSupplyClass["GROUP_85_TOILETRIES"] = "GROUP_85_TOILETRIES";
    FederalSupplyClass["GROUP_87_AGRICULTURE_SUPPLIES"] = "GROUP_87_AGRICULTURE_SUPPLIES";
    FederalSupplyClass["GROUP_88_LIVE_ANIMALS"] = "GROUP_88_LIVE_ANIMALS";
    FederalSupplyClass["GROUP_89_SUBSISTENCE"] = "GROUP_89_SUBSISTENCE";
    FederalSupplyClass["GROUP_91_FUELS_LUBRICATIONS_OILS_AND_WAXES"] = "GROUP_91_FUELS_LUBRICATIONS_OILS_AND_WAXES";
    FederalSupplyClass["GROUP_93_NON_METALLIC_FABRICATION_MATERIAL"] = "GROUP_93_NON_METALLIC_FABRICATION_MATERIAL";
    FederalSupplyClass["GROUP_94_NON_METALLIC_RUDE_MATERIAL"] = "GROUP_94_NON_METALLIC_RUDE_MATERIAL";
    FederalSupplyClass["GROUP_95_METAL_BARS_SHEETS_AND_SHAPES"] = "GROUP_95_METAL_BARS_SHEETS_AND_SHAPES";
    FederalSupplyClass["GROUP_96_ORES_MINERALS_THEIR_PRIMARY_PRODUCTS"] = "GROUP_96_ORES_MINERALS_THEIR_PRIMARY_PRODUCTS";
    FederalSupplyClass["GROUP_99_MISCELLANEOUS"] = "GROUP_99_MISCELLANEOUS";
    FederalSupplyClass["GUIDED_MISSILE_MAINTENANCE_REPAIR"] = "GUIDED_MISSILE_MAINTENANCE_REPAIR";
    FederalSupplyClass["GUNS_OVER_125MM_THROUGH_150MM"] = "GUNS_OVER_125MM_THROUGH_150MM";
    FederalSupplyClass["HAND_TOOLS_EDGED_NONPOWERED"] = "HAND_TOOLS_EDGED_NONPOWERED";
    FederalSupplyClass["HAND_TOOLS_NONEDGED_NONPOWERED"] = "HAND_TOOLS_NONEDGED_NONPOWERED";
    FederalSupplyClass["HAND_TOOLS_POWER_DRIVEN"] = "HAND_TOOLS_POWER_DRIVEN";
    FederalSupplyClass["HARVESTING_EQUIPMENT"] = "HARVESTING_EQUIPMENT";
    FederalSupplyClass["HAZARD_DETECTING_INSTRUMENTS_AND_APPARATUS"] = "HAZARD_DETECTING_INSTRUMENTS_AND_APPARATUS";
    FederalSupplyClass["HEADSETS_HANDSETS_MICROPHONES_AND_SPEAKERS"] = "HEADSETS_HANDSETS_MICROPHONES_AND_SPEAKERS";
    FederalSupplyClass["HEAT_EXCHANGERS_AND_STEAM_CONDENSERS"] = "HEAT_EXCHANGERS_AND_STEAM_CONDENSERS";
    FederalSupplyClass["HOSE_AND_TUBING_FLEXIBLE"] = "HOSE_AND_TUBING_FLEXIBLE";
    FederalSupplyClass["HOSIERY_HANDWEAR_AND_CLOTHING_MENS"] = "HOSIERY_HANDWEAR_AND_CLOTHING_MENS";
    FederalSupplyClass["HOSIERY_HANDWEAR_BADGES_AND_CLOTHING_WOMENS"] = "HOSIERY_HANDWEAR_BADGES_AND_CLOTHING_WOMENS";
    FederalSupplyClass["HOSPITAL_AND_SURGICAL_CLOTHING_AND_RELATED"] = "HOSPITAL_AND_SURGICAL_CLOTHING_AND_RELATED";
    FederalSupplyClass["HOSPITAL_FURNITURE_EQUIPMENT_UTENSILS"] = "HOSPITAL_FURNITURE_EQUIPMENT_UTENSILS";
    FederalSupplyClass["HOUSEHOLD_AND_COMMERCIAL_UTILITY_CONTAINERS"] = "HOUSEHOLD_AND_COMMERCIAL_UTILITY_CONTAINERS";
    FederalSupplyClass["HOUSEHOLD_FURNISHINGS"] = "HOUSEHOLD_FURNISHINGS";
    FederalSupplyClass["HOUSEHOLD_FURNITURE"] = "HOUSEHOLD_FURNITURE";
    FederalSupplyClass["HYDRAULIC_AND_PNEUMATIC_PRESSES_POWER"] = "HYDRAULIC_AND_PNEUMATIC_PRESSES_POWER";
    FederalSupplyClass["INDIVIDUAL_EQUIPMENT_SUNGLASSES"] = "INDIVIDUAL_EQUIPMENT_SUNGLASSES";
    FederalSupplyClass["INDOOR_AND_OUTDOOR_ELECTRIC_LIGHTING_FIXTURES"] = "INDOOR_AND_OUTDOOR_ELECTRIC_LIGHTING_FIXTURES";
    FederalSupplyClass["INDUSTRIAL_ASSEMBLY_MACHINES"] = "INDUSTRIAL_ASSEMBLY_MACHINES";
    FederalSupplyClass["INDUSTRIAL_BOILERS"] = "INDUSTRIAL_BOILERS";
    FederalSupplyClass["INDUSTRIAL_FURNACES_KILNS_LEHRS_AND_OVENS"] = "INDUSTRIAL_FURNACES_KILNS_LEHRS_AND_OVENS";
    FederalSupplyClass["INDUSTRIAL_MARKING_MACHINES"] = "INDUSTRIAL_MARKING_MACHINES";
    FederalSupplyClass["INDUSTRIAL_SEWING_MACHINES_MOBILE_TEXTILE"] = "INDUSTRIAL_SEWING_MACHINES_MOBILE_TEXTILE";
    FederalSupplyClass["INDUSTRIAL_SIZE_REDUCTION_MACHINERY"] = "INDUSTRIAL_SIZE_REDUCTION_MACHINERY";
    FederalSupplyClass["INSPECTION_GAGES_AND_PRECISION_LAYOUT_TOOLS"] = "INSPECTION_GAGES_AND_PRECISION_LAYOUT_TOOLS";
    FederalSupplyClass["INTEGRATED_OPTICAL_CIRCUITS"] = "INTEGRATED_OPTICAL_CIRCUITS";
    FederalSupplyClass["INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_AIRBORNE"] = "INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_AIRBORNE";
    FederalSupplyClass["INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_EXCEPT"] = "INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_EXCEPT";
    FederalSupplyClass["IN_VITRO_DIAGNOSTIC_SUBSTANCES_REAGENTS_TEST"] = "IN_VITRO_DIAGNOSTIC_SUBSTANCES_REAGENTS_TEST";
    FederalSupplyClass["IRON_AND_STEEL_PRIMARY_AND_SEMIFINISHED_PRODUCTS"] = "IRON_AND_STEEL_PRIMARY_AND_SEMIFINISHED_PRODUCTS";
    FederalSupplyClass["IRON_AND_STEEL_SCRAP"] = "IRON_AND_STEEL_SCRAP";
    FederalSupplyClass["JAMS_JELLIES_AND_PRESERVES"] = "JAMS_JELLIES_AND_PRESERVES";
    FederalSupplyClass["JEWELRY"] = "JEWELRY";
    FederalSupplyClass["KITCHEN_EQUIPMENT_AND_APPLIANCES"] = "KITCHEN_EQUIPMENT_AND_APPLIANCES";
    FederalSupplyClass["KITCHEN_HAND_TOOLS_AND_UTENSILS"] = "KITCHEN_HAND_TOOLS_AND_UTENSILS";
    FederalSupplyClass["KNOBS_AND_POINTERS"] = "KNOBS_AND_POINTERS";
    FederalSupplyClass["LABORATORY_EQUIPMENT_AND_SUPPLIES"] = "LABORATORY_EQUIPMENT_AND_SUPPLIES";
    FederalSupplyClass["LATHES"] = "LATHES";
    FederalSupplyClass["LAUNDRY_AND_DRY_CLEANING_EQUIPMENT"] = "LAUNDRY_AND_DRY_CLEANING_EQUIPMENT";
    FederalSupplyClass["LEATHER"] = "LEATHER";
    FederalSupplyClass["LEATHER_TANNING_AND_LEATHER_WORKING"] = "LEATHER_TANNING_AND_LEATHER_WORKING";
    FederalSupplyClass["LIQUID_AND_GAS_FLOW_LIQUID_LEVEL"] = "LIQUID_AND_GAS_FLOW_LIQUID_LEVEL";
    FederalSupplyClass["LIQUID_PROPELLANTS_AND_FUELS_PETROLEUM_BASE"] = "LIQUID_PROPELLANTS_AND_FUELS_PETROLEUM_BASE";
    FederalSupplyClass["LIQUID_PROPELLANTS_AND_OXIDIZERS"] = "LIQUID_PROPELLANTS_AND_OXIDIZERS";
    FederalSupplyClass["LIVE_ANIMALS_NOT_RAISED_FOR_FOOD"] = "LIVE_ANIMALS_NOT_RAISED_FOR_FOOD";
    FederalSupplyClass["LIVE_ANIMALS_RAISED_FOR_FOOD"] = "LIVE_ANIMALS_RAISED_FOR_FOOD";
    FederalSupplyClass["LUBRICATION_AND_FUEL_DISPENSING_EQUIPMENT"] = "LUBRICATION_AND_FUEL_DISPENSING_EQUIPMENT";
    FederalSupplyClass["LUGGAGE"] = "LUGGAGE";
    FederalSupplyClass["LUGS_TERMINALS_AND_TERMINAL_STRIPS"] = "LUGS_TERMINALS_AND_TERMINAL_STRIPS";
    FederalSupplyClass["LUMBER_AND_RELATED_BASIC_WOOD_MATERIALS"] = "LUMBER_AND_RELATED_BASIC_WOOD_MATERIALS";
    FederalSupplyClass["MACHINE_SHOP_SETS_KITS_AND_OUTFITS"] = "MACHINE_SHOP_SETS_KITS_AND_OUTFITS";
    FederalSupplyClass["MACHINE_TOOLS_PORTABLE"] = "MACHINE_TOOLS_PORTABLE";
    FederalSupplyClass["MACHINE_TOOL_ACCESSORIES"] = "MACHINE_TOOL_ACCESSORIES";
    FederalSupplyClass["MACHINING_CENTERS_AND_WAY_TYPE_MACHINES"] = "MACHINING_CENTERS_AND_WAY_TYPE_MACHINES";
    FederalSupplyClass["MANUAL_PRESSES"] = "MANUAL_PRESSES";
    FederalSupplyClass["MAPS_ATLASES_CHARTS_AND_GLOBES"] = "MAPS_ATLASES_CHARTS_AND_GLOBES";
    FederalSupplyClass["MARINE_LIFESAVING_AND_DIVING_EQUIPMENT"] = "MARINE_LIFESAVING_AND_DIVING_EQUIPMENT";
    FederalSupplyClass["MATERIALS_FEEDERS"] = "MATERIALS_FEEDERS";
    FederalSupplyClass["MATERIALS_HANDLING_EQUIPMENT_NONSELFPROPELLED"] = "MATERIALS_HANDLING_EQUIPMENT_NONSELFPROPELLED";
    FederalSupplyClass["MEASURING_TOOLS_CRAFTSMENS"] = "MEASURING_TOOLS_CRAFTSMENS";
    FederalSupplyClass["MEAT_POULTRY_AND_FISH"] = "MEAT_POULTRY_AND_FISH";
    FederalSupplyClass["MECHANICAL_POWER_TRANSMISSION_EQUIPMENT"] = "MECHANICAL_POWER_TRANSMISSION_EQUIPMENT";
    FederalSupplyClass["MECHANICAL_PRESSES_POWER_DRIVEN"] = "MECHANICAL_PRESSES_POWER_DRIVEN";
    FederalSupplyClass["MEDICAL_AND_SURGICAL_INSTRUMENTS_EQUIPMENT"] = "MEDICAL_AND_SURGICAL_INSTRUMENTS_EQUIPMENT";
    FederalSupplyClass["MEDICATED_COSMETICS_AND_TOILETRIES"] = "MEDICATED_COSMETICS_AND_TOILETRIES";
    FederalSupplyClass["MEMORIALS_CEMETERIAL_AND_MORTUARY_EQUIPMENT"] = "MEMORIALS_CEMETERIAL_AND_MORTUARY_EQUIPMENT";
    FederalSupplyClass["METAL_FINISHING_EQUIPMENT"] = "METAL_FINISHING_EQUIPMENT";
    FederalSupplyClass["METAL_HEAT_TREATING_AND_NON_THERMAL_TREATING"] = "METAL_HEAT_TREATING_AND_NON_THERMAL_TREATING";
    FederalSupplyClass["METAL_SCREENING"] = "METAL_SCREENING";
    FederalSupplyClass["METEROLOGICAL_INSTRUMENTS_AND_APPARATUS"] = "METEROLOGICAL_INSTRUMENTS_AND_APPARATUS";
    FederalSupplyClass["MICROCIRCUITS_ELECTRONIC"] = "MICROCIRCUITS_ELECTRONIC";
    FederalSupplyClass["MICROFILM_PROCESSED"] = "MICROFILM_PROCESSED";
    FederalSupplyClass["MILLING_MACHINES"] = "MILLING_MACHINES";
    FederalSupplyClass["MILLWORK"] = "MILLWORK";
    FederalSupplyClass["MINERALS_NATURAL_AND_SYNTHETIC"] = "MINERALS_NATURAL_AND_SYNTHETIC";
    FederalSupplyClass["MINERAL_CONSTRUCTION_MATERIALS_BULK"] = "MINERAL_CONSTRUCTION_MATERIALS_BULK";
    FederalSupplyClass["MINING_ROCK_DRILLING_EARTH_BORING"] = "MINING_ROCK_DRILLING_EARTH_BORING";
    FederalSupplyClass["MINI_AND_MICRO_COMPUTER_CONTROL_DEVICES"] = "MINI_AND_MICRO_COMPUTER_CONTROL_DEVICES";
    FederalSupplyClass["MISCELLANEOUS_ALARM_SIGNAL_AND_SECURITY"] = "MISCELLANEOUS_ALARM_SIGNAL_AND_SECURITY";
    FederalSupplyClass["MISCELLANEOUS_BATTERY_RETAINING_FIXTURES_LINERS"] = "MISCELLANEOUS_BATTERY_RETAINING_FIXTURES_LINERS";
    FederalSupplyClass["MISCELLANEOUS_CHEMICAL_SPECIALTIES"] = "MISCELLANEOUS_CHEMICAL_SPECIALTIES";
    FederalSupplyClass["MISCELLANEOUS_COMMUNICATION_EQUIPMENT"] = "MISCELLANEOUS_COMMUNICATION_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_CONSTRUCTION_EQUIPMENT"] = "MISCELLANEOUS_CONSTRUCTION_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_CONSTRUCTION_MATERIALS"] = "MISCELLANEOUS_CONSTRUCTION_MATERIALS";
    FederalSupplyClass["MISCELLANEOUS_CRUDE_AGRICULTURAL_AND_FORESTRY"] = "MISCELLANEOUS_CRUDE_AGRICULTURAL_AND_FORESTRY";
    FederalSupplyClass["MISCELLANEOUS_CRUDE_ANIMAL_PRODUCTS"] = "MISCELLANEOUS_CRUDE_ANIMAL_PRODUCTS";
    FederalSupplyClass["MISCELLANEOUS_ELECTRICAL_AND_ELECTRONIC_COMPONENT"] = "MISCELLANEOUS_ELECTRICAL_AND_ELECTRONIC_COMPONENT";
    FederalSupplyClass["MISCELLANEOUS_ELECTRIC_POWER_AND_DISTRIBUTION"] = "MISCELLANEOUS_ELECTRIC_POWER_AND_DISTRIBUTION";
    FederalSupplyClass["MISCELLANEOUS_ENGINES_AND_COMPONENTS"] = "MISCELLANEOUS_ENGINES_AND_COMPONENTS";
    FederalSupplyClass["MISCELLANEOUS_ENGINE_ACCESSORIES_NONAIRCRAFT"] = "MISCELLANEOUS_ENGINE_ACCESSORIES_NONAIRCRAFT";
    FederalSupplyClass["MISCELLANEOUS_FABRICATED_NONMETALLIC"] = "MISCELLANEOUS_FABRICATED_NONMETALLIC";
    FederalSupplyClass["MISCELLANEOUS_FABRICATED_STRUCTURES"] = "MISCELLANEOUS_FABRICATED_STRUCTURES";
    FederalSupplyClass["MISCELLANEOUS_FIBER_OPTIC_COMPONENTS"] = "MISCELLANEOUS_FIBER_OPTIC_COMPONENTS";
    FederalSupplyClass["MISCELLANEOUS_FIRE_CONTROL_EQUIPMENT"] = "MISCELLANEOUS_FIRE_CONTROL_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_FURNITURE_AND_FIXTURES"] = "MISCELLANEOUS_FURNITURE_AND_FIXTURES";
    FederalSupplyClass["MISCELLANEOUS_HARDWARE"] = "MISCELLANEOUS_HARDWARE";
    FederalSupplyClass["MISCELLANEOUS_HOUSEHOLD_AND_COMMERCIAL"] = "MISCELLANEOUS_HOUSEHOLD_AND_COMMERCIAL";
    FederalSupplyClass["MISCELLANEOUS_ITEMS"] = "MISCELLANEOUS_ITEMS";
    FederalSupplyClass["MISCELLANEOUS_MACHINE_TOOLS"] = "MISCELLANEOUS_MACHINE_TOOLS";
    FederalSupplyClass["MISCELLANEOUS_MAINTENANCE_AND_REPAIR_SHOP"] = "MISCELLANEOUS_MAINTENANCE_AND_REPAIR_SHOP";
    FederalSupplyClass["MISCELLANEOUS_MATERIALS_HANDLING_EQUIPMENT"] = "MISCELLANEOUS_MATERIALS_HANDLING_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_OFFICE_MACHINES"] = "MISCELLANEOUS_OFFICE_MACHINES";
    FederalSupplyClass["MISCELLANEOUS_PLUMBING_HEATING_AND_SANITAION"] = "MISCELLANEOUS_PLUMBING_HEATING_AND_SANITAION";
    FederalSupplyClass["MISCELLANEOUS_POWER_TRANSMISSION_EQUIPMENT"] = "MISCELLANEOUS_POWER_TRANSMISSION_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_PRINTED_MATTER"] = "MISCELLANEOUS_PRINTED_MATTER";
    FederalSupplyClass["MISCELLANEOUS_SECONDARY_METAL_FORMING"] = "MISCELLANEOUS_SECONDARY_METAL_FORMING";
    FederalSupplyClass["MISCELLANEOUS_SERVICE_AND_TRADE_EQUIPMENT"] = "MISCELLANEOUS_SERVICE_AND_TRADE_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_SPECIAL_INDUSTRY_MACHINERY"] = "MISCELLANEOUS_SPECIAL_INDUSTRY_MACHINERY";
    FederalSupplyClass["MISCELLANEOUS_VEHICULAR_COMPONENTS"] = "MISCELLANEOUS_VEHICULAR_COMPONENTS";
    FederalSupplyClass["MISCELLANEOUS_WAXES_OILS_AND_FATS"] = "MISCELLANEOUS_WAXES_OILS_AND_FATS";
    FederalSupplyClass["MISCELLANEOUS_WELDING_EQUIPMENT"] = "MISCELLANEOUS_WELDING_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_WELDING_SOLDERING_AND_BRAZING"] = "MISCELLANEOUS_WELDING_SOLDERING_AND_BRAZING";
    FederalSupplyClass["MOTORS_ELECTRICAL"] = "MOTORS_ELECTRICAL";
    FederalSupplyClass["MOTOR_VEHICLE_MAINTENANCE_AND_REPAIR_SHOP"] = "MOTOR_VEHICLE_MAINTENANCE_AND_REPAIR_SHOP";
    FederalSupplyClass["MUSICAL_INSTRUMENTS"] = "MUSICAL_INSTRUMENTS";
    FederalSupplyClass["MUSICAL_INSTRUMENT_PARTS_AND_ACCESSORIES"] = "MUSICAL_INSTRUMENT_PARTS_AND_ACCESSORIES";
    FederalSupplyClass["NAILS_KEYS_AND_PINS"] = "NAILS_KEYS_AND_PINS";
    FederalSupplyClass["NAVIGATIONAL_INSTRUMENTS"] = "NAVIGATIONAL_INSTRUMENTS";
    FederalSupplyClass["NEWSPAPERS_AND_PERIODICALS"] = "NEWSPAPERS_AND_PERIODICALS";
    FederalSupplyClass["NIGHT_VISION_EQUIPMENT_EMITTED_AND_REFLECTED"] = "NIGHT_VISION_EQUIPMENT_EMITTED_AND_REFLECTED";
    FederalSupplyClass["NONELECTRICAL_LIGHTING_FIXTURES"] = "NONELECTRICAL_LIGHTING_FIXTURES";
    FederalSupplyClass["NONFERROUS_BASE_METAL_REFINERY"] = "NONFERROUS_BASE_METAL_REFINERY";
    FederalSupplyClass["NONFERROUS_METAL_SCRAP"] = "NONFERROUS_METAL_SCRAP";
    FederalSupplyClass["NONMETALLIC_SCRAP_EXCEPT_TEXTILE"] = "NONMETALLIC_SCRAP_EXCEPT_TEXTILE";
    FederalSupplyClass["NOTIONS_AND_APPAREL_FINDINGS"] = "NOTIONS_AND_APPAREL_FINDINGS";
    FederalSupplyClass["NUTS_AND_WASHERS"] = "NUTS_AND_WASHERS";
    FederalSupplyClass["OFFICE_DEVICES_AND_ACCESSORIES"] = "OFFICE_DEVICES_AND_ACCESSORIES";
    FederalSupplyClass["OFFICE_FURNITURE"] = "OFFICE_FURNITURE";
    FederalSupplyClass["OFFICE_INFORMATION_SYSTEM_EQUIPMENT"] = "OFFICE_INFORMATION_SYSTEM_EQUIPMENT";
    FederalSupplyClass["OFFICE_SUPPLIES"] = "OFFICE_SUPPLIES";
    FederalSupplyClass["OFFICE_TYPE_SOUND_RECORDING_AND_REPRODUCING"] = "OFFICE_TYPE_SOUND_RECORDING_AND_REPRODUCING";
    FederalSupplyClass["OILS_AND_GREASES_CUTTING_LUBRICATING"] = "OILS_AND_GREASES_CUTTING_LUBRICATING";
    FederalSupplyClass["OPERATION_TRAINING_DEVICES"] = "OPERATION_TRAINING_DEVICES";
    FederalSupplyClass["OPHTHALMIC_INSTRUMENT_EQUIPMENT"] = "OPHTHALMIC_INSTRUMENT_EQUIPMENT";
    FederalSupplyClass["OPTICAL_INSTRUMENTS_TEST_EQUIPMENT_COMPONENTS"] = "OPTICAL_INSTRUMENTS_TEST_EQUIPMENT_COMPONENTS";
    FederalSupplyClass["OPTICAL_MULTIPLEXERS_DEMULTIPLEXERS"] = "OPTICAL_MULTIPLEXERS_DEMULTIPLEXERS";
    FederalSupplyClass["OPTICAL_REPEATERS"] = "OPTICAL_REPEATERS";
    FederalSupplyClass["OPTICAL_SIGHTING_AND_RANGING_EQUIPMENT"] = "OPTICAL_SIGHTING_AND_RANGING_EQUIPMENT";
    FederalSupplyClass["OPTOELECTRONIC_DEVICES_AND_ASSOCIATED_HARDWARE"] = "OPTOELECTRONIC_DEVICES_AND_ASSOCIATED_HARDWARE";
    FederalSupplyClass["ORES"] = "ORES";
    FederalSupplyClass["OSCILLATORS_AND_PIEZOELECTRIC_CRYSTALS"] = "OSCILLATORS_AND_PIEZOELECTRIC_CRYSTALS";
    FederalSupplyClass["OTHER_CRYPTOLOGIC_EQUIPMENT_AND_COMPONENTS"] = "OTHER_CRYPTOLOGIC_EQUIPMENT_AND_COMPONENTS";
    FederalSupplyClass["OUTERWEAR_MEN_S"] = "OUTERWEAR_MEN_S";
    FederalSupplyClass["OUTERWEAR_WOMEN_S"] = "OUTERWEAR_WOMEN_S";
    FederalSupplyClass["O_RINGS_RINGS_SHIMS_AND_SPACERS"] = "O_RINGS_RINGS_SHIMS_AND_SPACERS";
    FederalSupplyClass["PACKAGING_AND_PACKING_BULK_MATERIALS"] = "PACKAGING_AND_PACKING_BULK_MATERIALS";
    FederalSupplyClass["PACKING_AND_GASKET_MATERIALS"] = "PACKING_AND_GASKET_MATERIALS";
    FederalSupplyClass["PADDING_AND_STUFFING_MATERIALS"] = "PADDING_AND_STUFFING_MATERIALS";
    FederalSupplyClass["PAINTS_DOPES_VARNISHES_AND_RELATED_PRODUCTS"] = "PAINTS_DOPES_VARNISHES_AND_RELATED_PRODUCTS";
    FederalSupplyClass["PAINT_AND_ARTISTS_BRUSHES"] = "PAINT_AND_ARTISTS_BRUSHES";
    FederalSupplyClass["PAPER_AND_PAPERBOARD"] = "PAPER_AND_PAPERBOARD";
    FederalSupplyClass["PARACHUTES_AERIAL_PICK_UP_DELIVERY_RECOVERY"] = "PARACHUTES_AERIAL_PICK_UP_DELIVERY_RECOVERY";
    FederalSupplyClass["PERFUMES_TOILET_PREPARATIONS_AND_POWDERS"] = "PERFUMES_TOILET_PREPARATIONS_AND_POWDERS";
    FederalSupplyClass["PERSONAL_TOILETRY_ARTICLES"] = "PERSONAL_TOILETRY_ARTICLES";
    FederalSupplyClass["PEST_CONTROL_AGENTS_AND_DISINFECTANTS"] = "PEST_CONTROL_AGENTS_AND_DISINFECTANTS";
    FederalSupplyClass["PEST_DISEASE_AND_FROST_CONTROL_EQUIPMENT"] = "PEST_DISEASE_AND_FROST_CONTROL_EQUIPMENT";
    FederalSupplyClass["PETROLEUM_PRODUCTION_AND_DISTRIBUTION"] = "PETROLEUM_PRODUCTION_AND_DISTRIBUTION";
    FederalSupplyClass["PHONOGRAPHS_RADIOS_AND_TELEVISION_SETS"] = "PHONOGRAPHS_RADIOS_AND_TELEVISION_SETS";
    FederalSupplyClass["PHONOGRAPH_RECORDS"] = "PHONOGRAPH_RECORDS";
    FederalSupplyClass["PHOTOGRAPHIC_DEVELOPING_AND_FINISHING"] = "PHOTOGRAPHIC_DEVELOPING_AND_FINISHING";
    FederalSupplyClass["PHOTOGRAPHIC_EQUIPMENT_AND_ACCESSORIES"] = "PHOTOGRAPHIC_EQUIPMENT_AND_ACCESSORIES";
    FederalSupplyClass["PHOTOGRAPHIC_PROJECTION_EQUIPMENT"] = "PHOTOGRAPHIC_PROJECTION_EQUIPMENT";
    FederalSupplyClass["PHOTOGRAPHIC_SETS_KITS_AND_OUTFITS"] = "PHOTOGRAPHIC_SETS_KITS_AND_OUTFITS";
    FederalSupplyClass["PHOTOGRAPHIC_SUPPLIES"] = "PHOTOGRAPHIC_SUPPLIES";
    FederalSupplyClass["PHYSICAL_PROPERTIES_TESTING_EQUIPMENT"] = "PHYSICAL_PROPERTIES_TESTING_EQUIPMENT";
    FederalSupplyClass["PIPE_AND_CONDUIT_NONMETALLIC"] = "PIPE_AND_CONDUIT_NONMETALLIC";
    FederalSupplyClass["PIPE_AND_TUBE"] = "PIPE_AND_TUBE";
    FederalSupplyClass["PLANERS_AND_SHAPERS"] = "PLANERS_AND_SHAPERS";
    FederalSupplyClass["PLASTICS_FABRICATED_MATERIALS"] = "PLASTICS_FABRICATED_MATERIALS";
    FederalSupplyClass["PLATE_SHEET_STRIP_AND_FOIL"] = "PLATE_SHEET_STRIP_AND_FOIL";
    FederalSupplyClass["PLATE_SHEET_STRIP_AND_FOIL_IRON_AND_STEEL"] = "PLATE_SHEET_STRIP_AND_FOIL_IRON_AND_STEEL";
    FederalSupplyClass["PLATE_SHEET_STRIP_FOIL_AND_WIRE"] = "PLATE_SHEET_STRIP_FOIL_AND_WIRE";
    FederalSupplyClass["PLUMBING_FIXTURES_TOWELS_AND_ACCESSORIES"] = "PLUMBING_FIXTURES_TOWELS_AND_ACCESSORIES";
    FederalSupplyClass["PLYWOOD_AND_VENEER"] = "PLYWOOD_AND_VENEER";
    FederalSupplyClass["POWER_AND_HAND_PUMPS"] = "POWER_AND_HAND_PUMPS";
    FederalSupplyClass["PRECIOUS_METALS_PRIMARY_FORMS"] = "PRECIOUS_METALS_PRIMARY_FORMS";
    FederalSupplyClass["PREFABRICATED_AND_PORTABLE_BUILDINGS"] = "PREFABRICATED_AND_PORTABLE_BUILDINGS";
    FederalSupplyClass["PREFABRICATED_TOWER_STRUCTURES"] = "PREFABRICATED_TOWER_STRUCTURES";
    FederalSupplyClass["PRESERVATIVE_AND_SEALING_COMPOUNDS"] = "PRESERVATIVE_AND_SEALING_COMPOUNDS";
    FederalSupplyClass["PRESSURE_TEMPERATURE_AND_HUMIDITY_MEASURING"] = "PRESSURE_TEMPERATURE_AND_HUMIDITY_MEASURING";
    FederalSupplyClass["PRINTING_DUPLICATING_AND_BOOKBINDING"] = "PRINTING_DUPLICATING_AND_BOOKBINDING";
    FederalSupplyClass["PRODUCTION_JIGS_FIXTURES_AND_TEMPLATES"] = "PRODUCTION_JIGS_FIXTURES_AND_TEMPLATES";
    FederalSupplyClass["PULP_AND_PAPER_INDUSTRIES_MACHINERY"] = "PULP_AND_PAPER_INDUSTRIES_MACHINERY";
    FederalSupplyClass["PUNCHED_CARD_EQUIPMENT"] = "PUNCHED_CARD_EQUIPMENT";
    FederalSupplyClass["PUNCHING_AND_SHEARING_MACHINES"] = "PUNCHING_AND_SHEARING_MACHINES";
    FederalSupplyClass["PYROTECHNICS"] = "PYROTECHNICS";
    FederalSupplyClass["RADAR_EQUIPMENT_AIRBORNE"] = "RADAR_EQUIPMENT_AIRBORNE";
    FederalSupplyClass["RADAR_EQUIPMENT_EXCEPT_AIRBORNE"] = "RADAR_EQUIPMENT_EXCEPT_AIRBORNE";
    FederalSupplyClass["RADIO_AND_TELEVISION_COMMUNICATION_AIRBORNE"] = "RADIO_AND_TELEVISION_COMMUNICATION_AIRBORNE";
    FederalSupplyClass["RADIO_AND_TELEVISION_COMMUNICATION_EXCEPT"] = "RADIO_AND_TELEVISION_COMMUNICATION_EXCEPT";
    FederalSupplyClass["RADIO_NAVIGATION_EQUIPMENT_AIRBORNE"] = "RADIO_NAVIGATION_EQUIPMENT_AIRBORNE";
    FederalSupplyClass["RADIO_NAVIGATION_EQUIPMENT_EXCEPT_AIRBORNE"] = "RADIO_NAVIGATION_EQUIPMENT_EXCEPT_AIRBORNE";
    FederalSupplyClass["RAILROAD_SIGNAL_AND_WARNING_DEVICES"] = "RAILROAD_SIGNAL_AND_WARNING_DEVICES";
    FederalSupplyClass["RECREATIONAL_AND_GYMNASTIC_EQUIPMENT"] = "RECREATIONAL_AND_GYMNASTIC_EQUIPMENT";
    FederalSupplyClass["REELS_AND_SPOOLS"] = "REELS_AND_SPOOLS";
    FederalSupplyClass["REFRACTORIES_AND_FIRE_SURFACING_MATERIALS"] = "REFRACTORIES_AND_FIRE_SURFACING_MATERIALS";
    FederalSupplyClass["REFRIGERATION_AND_AIR_CONDITIONING_COMPONENTS"] = "REFRIGERATION_AND_AIR_CONDITIONING_COMPONENTS";
    FederalSupplyClass["REFRIGERATION_EQUIPMENT"] = "REFRIGERATION_EQUIPMENT";
    FederalSupplyClass["RELAYS_AND_SOLENOIDS"] = "RELAYS_AND_SOLENOIDS";
    FederalSupplyClass["REPLENISHABLE_FIELD_MEDICAL_SETS_KITS"] = "REPLENISHABLE_FIELD_MEDICAL_SETS_KITS";
    FederalSupplyClass["RESISTORS"] = "RESISTORS";
    FederalSupplyClass["RIGID_WALL_SHELTERS"] = "RIGID_WALL_SHELTERS";
    FederalSupplyClass["RIVETING_MACHINES"] = "RIVETING_MACHINES";
    FederalSupplyClass["RIVETS"] = "RIVETS";
    FederalSupplyClass["ROAD_CLEARING_AND_CLEANING_EQUIPMENT"] = "ROAD_CLEARING_AND_CLEANING_EQUIPMENT";
    FederalSupplyClass["ROCKETS_ROCKET_AMMUNITION_AND_ROCKET_COMPONENTS"] = "ROCKETS_ROCKET_AMMUNITION_AND_ROCKET_COMPONENTS";
    FederalSupplyClass["ROCKET_MAINTENANCE_REPAIR_AND_CHECKOUT"] = "ROCKET_MAINTENANCE_REPAIR_AND_CHECKOUT";
    FederalSupplyClass["ROLLING_MILLS_AND_DRAWING_MACHINES"] = "ROLLING_MILLS_AND_DRAWING_MACHINES";
    FederalSupplyClass["ROOFING_AND_SIDING_MATERIALS"] = "ROOFING_AND_SIDING_MATERIALS";
    FederalSupplyClass["ROTARY_JOINTS"] = "ROTARY_JOINTS";
    FederalSupplyClass["RUBBER_AND_PLASTICS_WORKING_MACHINERY"] = "RUBBER_AND_PLASTICS_WORKING_MACHINERY";
    FederalSupplyClass["RUBBER_FABRICATED_MATERIALS_CUSHIONS"] = "RUBBER_FABRICATED_MATERIALS_CUSHIONS";
    FederalSupplyClass["SADDLERY_HARNESS_WHIPS_AND_RELATED_ANIMAL"] = "SADDLERY_HARNESS_WHIPS_AND_RELATED_ANIMAL";
    FederalSupplyClass["SAFETY_AND_RESCUE_EQUIPMENT"] = "SAFETY_AND_RESCUE_EQUIPMENT";
    FederalSupplyClass["SAWMILL_AND_PLANING_MILL_MACHINERY"] = "SAWMILL_AND_PLANING_MILL_MACHINERY";
    FederalSupplyClass["SAWS_AND_FILING_MACHINES"] = "SAWS_AND_FILING_MACHINES";
    FederalSupplyClass["SCAFFOLDING_EQUIPMENT_AND_CONCRETE_FORMS"] = "SCAFFOLDING_EQUIPMENT_AND_CONCRETE_FORMS";
    FederalSupplyClass["SCALES_AND_BALANCES"] = "SCALES_AND_BALANCES";
    FederalSupplyClass["SCREWS"] = "SCREWS";
    FederalSupplyClass["SEEDS_AND_NURSERY_STOCK"] = "SEEDS_AND_NURSERY_STOCK";
    FederalSupplyClass["SEMICONDUCTOR_DEVICES_AND_ASSOCIATED_HARDWARE"] = "SEMICONDUCTOR_DEVICES_AND_ASSOCIATED_HARDWARE";
    FederalSupplyClass["SETS_KITS_AND_OUTFITS_FOOD_PREPARATION"] = "SETS_KITS_AND_OUTFITS_FOOD_PREPARATION";
    FederalSupplyClass["SETS_KITS_AND_OUTFITS_OF_HAND_TOOLS"] = "SETS_KITS_AND_OUTFITS_OF_HAND_TOOLS";
    FederalSupplyClass["SETS_KITS_AND_OUTFITS_OF_MEASURING_TOOLS"] = "SETS_KITS_AND_OUTFITS_OF_MEASURING_TOOLS";
    FederalSupplyClass["SEWAGE_TREATMENT_EQUIPMENT"] = "SEWAGE_TREATMENT_EQUIPMENT";
    FederalSupplyClass["SHEET_AND_BOOK_MUSIC"] = "SHEET_AND_BOOK_MUSIC";
    FederalSupplyClass["SHIPBOARD_ALARM_AND_SIGNAL_SYSTEMS"] = "SHIPBOARD_ALARM_AND_SIGNAL_SYSTEMS";
    FederalSupplyClass["SHOE_FINDINGS_AND_SOLING_MATERIALS"] = "SHOE_FINDINGS_AND_SOLING_MATERIALS";
    FederalSupplyClass["SHOE_REPAIRING_EQUIPMENT"] = "SHOE_REPAIRING_EQUIPMENT";
    FederalSupplyClass["SIGNS_ADVERTISING_DISPLAYS_AND_IDENTIFICATION"] = "SIGNS_ADVERTISING_DISPLAYS_AND_IDENTIFICATION";
    FederalSupplyClass["SMALL_CRAFT_POWERED_AND_UNPOWERED_SMALL_CRAFT"] = "SMALL_CRAFT_POWERED_AND_UNPOWERED_SMALL_CRAFT";
    FederalSupplyClass["SMOKERS_ARTICLES_AND_MATCHES"] = "SMOKERS_ARTICLES_AND_MATCHES";
    FederalSupplyClass["SOIL_PREPARATION_EQUIPMENT"] = "SOIL_PREPARATION_EQUIPMENT";
    FederalSupplyClass["SOLAR_ELECTRIC_POWER_SYSTEMS"] = "SOLAR_ELECTRIC_POWER_SYSTEMS";
    FederalSupplyClass["SOUND_RECORDING_AND_REPRODUCING_EQUIPMENT"] = "SOUND_RECORDING_AND_REPRODUCING_EQUIPMENT";
    FederalSupplyClass["SOUPS_AND_BOUILLONS"] = "SOUPS_AND_BOUILLONS";
    FederalSupplyClass["SPACE_HEATING_EQUIPMENT_AND_DOMESTIC"] = "SPACE_HEATING_EQUIPMENT_AND_DOMESTIC";
    FederalSupplyClass["SPACE_VEHICLE_COMPONENTS"] = "SPACE_VEHICLE_COMPONENTS";
    FederalSupplyClass["SPACE_VEHICLE_HANDLING_AND_SERVICING_EQUIPMENT"] = "SPACE_VEHICLE_HANDLING_AND_SERVICING_EQUIPMENT";
    FederalSupplyClass["SPACE_VEHICLE_MAINTENANCE_REPAIR_AND_CHECKOUT"] = "SPACE_VEHICLE_MAINTENANCE_REPAIR_AND_CHECKOUT";
    FederalSupplyClass["SPECIALIZED_AMMUNITION_AND_ORDNANCE_MACHINERY"] = "SPECIALIZED_AMMUNITION_AND_ORDNANCE_MACHINERY";
    FederalSupplyClass["SPECIALIZED_FLIGHT_CLOTHING_ACCESSORIES"] = "SPECIALIZED_FLIGHT_CLOTHING_ACCESSORIES";
    FederalSupplyClass["SPECIALIZED_METAL_CONTAINER_MANUFACTURING"] = "SPECIALIZED_METAL_CONTAINER_MANUFACTURING";
    FederalSupplyClass["SPECIALIZED_SEMICONDUCTOR_MICROCIRCUIT"] = "SPECIALIZED_SEMICONDUCTOR_MICROCIRCUIT";
    FederalSupplyClass["SPECIALIZED_SHIPPING_AND_STORAGE_CONTAINERS"] = "SPECIALIZED_SHIPPING_AND_STORAGE_CONTAINERS";
    FederalSupplyClass["SPECIALIZED_TEST_AND_HANDLING_EQUIPMENT"] = "SPECIALIZED_TEST_AND_HANDLING_EQUIPMENT";
    FederalSupplyClass["SPECIAL_DIETARY_FOODS_AND_FOOD_SPECIALTY"] = "SPECIAL_DIETARY_FOODS_AND_FOOD_SPECIALTY";
    FederalSupplyClass["STANDARD_FORMS"] = "STANDARD_FORMS";
    FederalSupplyClass["STATIONERY_AND_RECORD_FORMS"] = "STATIONERY_AND_RECORD_FORMS";
    FederalSupplyClass["STIMULATED_COHERENT_RADIATION_DEVICES_COMPONENTS"] = "STIMULATED_COHERENT_RADIATION_DEVICES_COMPONENTS";
    FederalSupplyClass["STORAGE_TANKS"] = "STORAGE_TANKS";
    FederalSupplyClass["STRUCTURAL_SHAPES_IRON_AND_STEEL"] = "STRUCTURAL_SHAPES_IRON_AND_STEEL";
    FederalSupplyClass["STRUCTURAL_SHAPES_NONFERROUS_BASE_METAL"] = "STRUCTURAL_SHAPES_NONFERROUS_BASE_METAL";
    FederalSupplyClass["STUDS"] = "STUDS";
    FederalSupplyClass["SUGAR_CONFECTIONERY_AND_NUTS"] = "SUGAR_CONFECTIONERY_AND_NUTS";
    FederalSupplyClass["SURGICAL_DRESSING_MATERIALS"] = "SURGICAL_DRESSING_MATERIALS";
    FederalSupplyClass["SWITCHES"] = "SWITCHES";
    FederalSupplyClass["SYNCHROS_AND_RESOLVERS"] = "SYNCHROS_AND_RESOLVERS";
    FederalSupplyClass["TABLEWARE"] = "TABLEWARE";
    FederalSupplyClass["TAPS_DIES_AND_COLLETS_HAND_AND_MACHINE"] = "TAPS_DIES_AND_COLLETS_HAND_AND_MACHINE";
    FederalSupplyClass["TELEPHONE_AND_TELEGRAPH_EQUIPMENT"] = "TELEPHONE_AND_TELEGRAPH_EQUIPMENT";
    FederalSupplyClass["TELETYPE_AND_FACSIMILE_EQUIPMENT"] = "TELETYPE_AND_FACSIMILE_EQUIPMENT";
    FederalSupplyClass["TENTS_AND_TARPAULILNS"] = "TENTS_AND_TARPAULILNS";
    FederalSupplyClass["TEXTILE_FABRICS"] = "TEXTILE_FABRICS";
    FederalSupplyClass["TEXTILE_INDUSTRIES_MACHINERY"] = "TEXTILE_INDUSTRIES_MACHINERY";
    FederalSupplyClass["TIME_MEASURING_INSTRUMENTS"] = "TIME_MEASURING_INSTRUMENTS";
    FederalSupplyClass["TIRES_SOLID_AND_CUSHION"] = "TIRES_SOLID_AND_CUSHION";
    FederalSupplyClass["TOBACCO_MANUFACTURING_MACHINERY"] = "TOBACCO_MANUFACTURING_MACHINERY";
    FederalSupplyClass["TOBACCO_PRODUCTS"] = "TOBACCO_PRODUCTS";
    FederalSupplyClass["TOILETRY_PAPER_PRODUCTS"] = "TOILETRY_PAPER_PRODUCTS";
    FederalSupplyClass["TOILET_SOAP_SHAVING_PREPARATIONS_DENTIFRICES"] = "TOILET_SOAP_SHAVING_PREPARATIONS_DENTIFRICES";
    FederalSupplyClass["TOOLS_AND_ATTACHMENTS_FOR_WOODWORKING_MACHINERY"] = "TOOLS_AND_ATTACHMENTS_FOR_WOODWORKING_MACHINERY";
    FederalSupplyClass["TOOL_AND_HARDWARE_BOXES"] = "TOOL_AND_HARDWARE_BOXES";
    FederalSupplyClass["TORPEDO_MAINTENANCE_REPAIR_AND_CHECKOUT"] = "TORPEDO_MAINTENANCE_REPAIR_AND_CHECKOUT";
    FederalSupplyClass["TRAFFIC_AND_TRANSIT_SIGNAL_SYSTEMS"] = "TRAFFIC_AND_TRANSIT_SIGNAL_SYSTEMS";
    FederalSupplyClass["TRAINING_AIDS"] = "TRAINING_AIDS";
    FederalSupplyClass["TRANSFORMERS_DISTRIBUTION_AND_POWER_STATION"] = "TRANSFORMERS_DISTRIBUTION_AND_POWER_STATION";
    FederalSupplyClass["TRUCKS_TRUCK_TRACTORS_WHEELED"] = "TRUCKS_TRUCK_TRACTORS_WHEELED";
    FederalSupplyClass["TRUCK_AND_TRACTOR_ATTACHMENTS"] = "TRUCK_AND_TRACTOR_ATTACHMENTS";
    FederalSupplyClass["TURBOSUPERCHARGERS"] = "TURBOSUPERCHARGERS";
    FederalSupplyClass["TYPEWRITERS_AND_OFFICE_TYPE_COMPOSING"] = "TYPEWRITERS_AND_OFFICE_TYPE_COMPOSING";
    FederalSupplyClass["UNDERWATER_SOUND_EQUIPMENT"] = "UNDERWATER_SOUND_EQUIPMENT";
    FederalSupplyClass["UNDERWEAR_AND_NIGHTWEAR_MEN_S"] = "UNDERWEAR_AND_NIGHTWEAR_MEN_S";
    FederalSupplyClass["UNDERWEAR_AND_NIGHTWEAR_WOMEN_S"] = "UNDERWEAR_AND_NIGHTWEAR_WOMEN_S";
    FederalSupplyClass["UNKNOWN"] = "UNKNOWN";
    FederalSupplyClass["VALVES_NONPOWERED"] = "VALVES_NONPOWERED";
    FederalSupplyClass["VALVES_POWERED"] = "VALVES_POWERED";
    FederalSupplyClass["VENDING_AND_COIN_OPERATED_MACHINES"] = "VENDING_AND_COIN_OPERATED_MACHINES";
    FederalSupplyClass["VIDEO_RECORDING_AND_REPRODUCING_EQUIPMENT"] = "VIDEO_RECORDING_AND_REPRODUCING_EQUIPMENT";
    FederalSupplyClass["VISIBLE_AND_INVISIBLE_LIGHT_COMMUNICATION"] = "VISIBLE_AND_INVISIBLE_LIGHT_COMMUNICATION";
    FederalSupplyClass["VISIBLE_RECORD_EQUIPMENT"] = "VISIBLE_RECORD_EQUIPMENT";
    FederalSupplyClass["WALLBOARD_BUILDING_PAPER_AND_THERMAL"] = "WALLBOARD_BUILDING_PAPER_AND_THERMAL";
    FederalSupplyClass["WAREHOUSE_TRUCKS_AND_TRACTORS_SELFPROPELLED"] = "WAREHOUSE_TRUCKS_AND_TRACTORS_SELFPROPELLED";
    FederalSupplyClass["WATER_DISTILLATION_EQUIPMENT_MARINE"] = "WATER_DISTILLATION_EQUIPMENT_MARINE";
    FederalSupplyClass["WATER_PURIFICATION_EQUIPMENT"] = "WATER_PURIFICATION_EQUIPMENT";
    FederalSupplyClass["WEAPONS_MAINTENANCE_AND_REPAIR_SHOP"] = "WEAPONS_MAINTENANCE_AND_REPAIR_SHOP";
    FederalSupplyClass["WELDING_POSITIONERS_AND_MANIPULATORS"] = "WELDING_POSITIONERS_AND_MANIPULATORS";
    FederalSupplyClass["WINCHES_HOISTS_CRANES_AND_DERRICKS"] = "WINCHES_HOISTS_CRANES_AND_DERRICKS";
    FederalSupplyClass["WIRE_AND_CABLE_ELECTRICAL"] = "WIRE_AND_CABLE_ELECTRICAL";
    FederalSupplyClass["WIRE_AND_METAL_RIBBON_FORMING_MACHINES"] = "WIRE_AND_METAL_RIBBON_FORMING_MACHINES";
    FederalSupplyClass["WIRE_NONELECTRICAL_IRON_AND_STEEL"] = "WIRE_NONELECTRICAL_IRON_AND_STEEL";
    FederalSupplyClass["WIRE_NONELECTRICAL_NONFERROUS_BASE_METAL"] = "WIRE_NONELECTRICAL_NONFERROUS_BASE_METAL";
    FederalSupplyClass["WOODWORKING_MACHINES"] = "WOODWORKING_MACHINES";
    FederalSupplyClass["WRAPPING_AND_PACKAGING_MACHINERY"] = "WRAPPING_AND_PACKAGING_MACHINERY";
    FederalSupplyClass["X_RAY_EQUIPMENT_AND_SUPPLIES_MEDICAL_DENTAL"] = "X_RAY_EQUIPMENT_AND_SUPPLIES_MEDICAL_DENTAL";
    FederalSupplyClass["YARN_AND_THREAD"] = "YARN_AND_THREAD";
    FederalSupplyClass["SPACE_SURVIVAL_EQUIPMENT"] = "SPACE_SURVIVAL_EQUIPMENT";
})(FederalSupplyClass || (FederalSupplyClass = {}));
export var GroundSupportEquipmentType;
(function (GroundSupportEquipmentType) {
    GroundSupportEquipmentType["AIR_FLOW_MONITOR"] = "AIR_FLOW_MONITOR";
    GroundSupportEquipmentType["ALARM_PANEL"] = "ALARM_PANEL";
    GroundSupportEquipmentType["AMPLIFIER"] = "AMPLIFIER";
    GroundSupportEquipmentType["ANALYZER"] = "ANALYZER";
    GroundSupportEquipmentType["APFR_GSE_TETHER_ASSY"] = "APFR_GSE_TETHER_ASSY";
    GroundSupportEquipmentType["ATTENUATOR"] = "ATTENUATOR";
    GroundSupportEquipmentType["BATTERY_CHARGER_DISCHARGER"] = "BATTERY_CHARGER_DISCHARGER";
    GroundSupportEquipmentType["BATTERY_CHARGER_EMU_LIB"] = "BATTERY_CHARGER_EMU_LIB";
    GroundSupportEquipmentType["BATTERY_HITESTER"] = "BATTERY_HITESTER";
    GroundSupportEquipmentType["BLEED_FIXTURE"] = "BLEED_FIXTURE";
    GroundSupportEquipmentType["BREAKOUT_BOX"] = "BREAKOUT_BOX";
    GroundSupportEquipmentType["BUBBLE_FLOWMETER_KIT"] = "BUBBLE_FLOWMETER_KIT";
    GroundSupportEquipmentType["CABLE"] = "CABLE";
    GroundSupportEquipmentType["CALIBRATED_LEAK"] = "CALIBRATED_LEAK";
    GroundSupportEquipmentType["CALIPER"] = "CALIPER";
    GroundSupportEquipmentType["CARRYING_FRAME"] = "CARRYING_FRAME";
    GroundSupportEquipmentType["CCC_SIMULATOR"] = "CCC_SIMULATOR";
    GroundSupportEquipmentType["CLEAN_ROOM_LSS"] = "CLEAN_ROOM_LSS";
    GroundSupportEquipmentType["CLEAN_ROOM_SOP"] = "CLEAN_ROOM_SOP";
    GroundSupportEquipmentType["CONTACT_TOOL"] = "CONTACT_TOOL";
    GroundSupportEquipmentType["COOLER"] = "COOLER";
    GroundSupportEquipmentType["COUNTER"] = "COUNTER";
    GroundSupportEquipmentType["CRIMP_TOOL"] = "CRIMP_TOOL";
    GroundSupportEquipmentType["CURRENT_METER"] = "CURRENT_METER";
    GroundSupportEquipmentType["DATA_ACQUISITION_UNIT"] = "DATA_ACQUISITION_UNIT";
    GroundSupportEquipmentType["DECADE_RESISTOR"] = "DECADE_RESISTOR";
    GroundSupportEquipmentType["DESKTOP_COMPUTER"] = "DESKTOP_COMPUTER";
    GroundSupportEquipmentType["DIA_PUMP"] = "DIA_PUMP";
    GroundSupportEquipmentType["DIAL_CHAMFER_GAUGE"] = "DIAL_CHAMFER_GAUGE";
    GroundSupportEquipmentType["DIGITAL_LEVEL"] = "DIGITAL_LEVEL";
    GroundSupportEquipmentType["DIT_MCO_2_CABLE_TEST_SYSTEM_ASSEMBLY"] = "DIT_MCO_2_CABLE_TEST_SYSTEM_ASSEMBLY";
    GroundSupportEquipmentType["ELECTRICAL_GLOVES"] = "ELECTRICAL_GLOVES";
    GroundSupportEquipmentType["ELECTRONIC_LOAD"] = "ELECTRONIC_LOAD";
    GroundSupportEquipmentType["EMU_DISPLAY_STAND_ASSY"] = "EMU_DISPLAY_STAND_ASSY";
    GroundSupportEquipmentType["EMU_GROUND_TEST_FIXTURE"] = "EMU_GROUND_TEST_FIXTURE";
    GroundSupportEquipmentType["EMU_GROUND_TEST_FIXTURE_FRAME"] = "EMU_GROUND_TEST_FIXTURE_FRAME";
    GroundSupportEquipmentType["EMU_LIFTING_FIXTURE"] = "EMU_LIFTING_FIXTURE";
    GroundSupportEquipmentType["EMU_OFFSET_HOLDING_FIXTURE"] = "EMU_OFFSET_HOLDING_FIXTURE";
    GroundSupportEquipmentType["EQUIPMENT_TBD"] = "EQUIPMENT_TBD";
    GroundSupportEquipmentType["FACILITY_B_A_SYSTEM"] = "FACILITY_B_A_SYSTEM";
    GroundSupportEquipmentType["FACILITY_BA_SYSTEM"] = "FACILITY_BA_SYSTEM";
    GroundSupportEquipmentType["FACILITY_DA_SYSTEM"] = "FACILITY_DA_SYSTEM";
    GroundSupportEquipmentType["FACILITY_H20_SYSTEM"] = "FACILITY_H20_SYSTEM";
    GroundSupportEquipmentType["FACILITY_H2O_SYSTEM"] = "FACILITY_H2O_SYSTEM";
    GroundSupportEquipmentType["FACILITY_N2_SYSTEM"] = "FACILITY_N2_SYSTEM";
    GroundSupportEquipmentType["FACILITY_O2_SYSTEM"] = "FACILITY_O2_SYSTEM";
    GroundSupportEquipmentType["FC_FLOW_CONTROLLER"] = "FC_FLOW_CONTROLLER";
    GroundSupportEquipmentType["FCD_FLOW_CONTROL_DISPLAY"] = "FCD_FLOW_CONTROL_DISPLAY";
    GroundSupportEquipmentType["FILTER"] = "FILTER";
    GroundSupportEquipmentType["FIXTURE"] = "FIXTURE";
    GroundSupportEquipmentType["FLEXHOSE"] = "FLEXHOSE";
    GroundSupportEquipmentType["FLOW_BENCH"] = "FLOW_BENCH";
    GroundSupportEquipmentType["FLOWMETER"] = "FLOWMETER";
    GroundSupportEquipmentType["FORCE_GAUGE"] = "FORCE_GAUGE";
    GroundSupportEquipmentType["FORK_HOOK"] = "FORK_HOOK";
    GroundSupportEquipmentType["FORKLIFT"] = "FORKLIFT";
    GroundSupportEquipmentType["FT_FLOW_TRANSDUCER"] = "FT_FLOW_TRANSDUCER";
    GroundSupportEquipmentType["FTD_FLOW_TRANSDUCER_DISPLAY"] = "FTD_FLOW_TRANSDUCER_DISPLAY";
    GroundSupportEquipmentType["FUME_HOOD"] = "FUME_HOOD";
    GroundSupportEquipmentType["FUNCTION_GENERATOR"] = "FUNCTION_GENERATOR";
    GroundSupportEquipmentType["GAGE_ACTIVE_WIF_PROBE"] = "GAGE_ACTIVE_WIF_PROBE";
    GroundSupportEquipmentType["GHD_HOISTING_SLING"] = "GHD_HOISTING_SLING";
    GroundSupportEquipmentType["GLOVE_MEASURE_DEVICE"] = "GLOVE_MEASURE_DEVICE";
    GroundSupportEquipmentType["GLUE_BENCH"] = "GLUE_BENCH";
    GroundSupportEquipmentType["GO_GAGE"] = "GO_GAGE";
    GroundSupportEquipmentType["GO_NO_GO_GAUGE"] = "GO_NO_GO_GAUGE";
    GroundSupportEquipmentType["GROUND_HANDLING_DEVICE"] = "GROUND_HANDLING_DEVICE";
    GroundSupportEquipmentType["HEIGHT_EXTENDER_ASSY"] = "HEIGHT_EXTENDER_ASSY";
    GroundSupportEquipmentType["HELIUM_LEAK_PROBE"] = "HELIUM_LEAK_PROBE";
    GroundSupportEquipmentType["HIGH_POT_TESTER"] = "HIGH_POT_TESTER";
    GroundSupportEquipmentType["HIGH_RESISTANCE_METER"] = "HIGH_RESISTANCE_METER";
    GroundSupportEquipmentType["HOIST"] = "HOIST";
    GroundSupportEquipmentType["HOSE_ASSEMBLY"] = "HOSE_ASSEMBLY";
    GroundSupportEquipmentType["HOSE_SWAGING_CART"] = "HOSE_SWAGING_CART";
    GroundSupportEquipmentType["HYGRO_THERMOMETER"] = "HYGRO_THERMOMETER";
    GroundSupportEquipmentType["IMPACT_O_GRAPH"] = "IMPACT_O_GRAPH";
    GroundSupportEquipmentType["IONIZER"] = "IONIZER";
    GroundSupportEquipmentType["IR_THERMOMETER"] = "IR_THERMOMETER";
    GroundSupportEquipmentType["LAB_TOP_COMPUTER"] = "LAB_TOP_COMPUTER";
    GroundSupportEquipmentType["LAUNCH_ENCLOSURE_LIFTING_EYE"] = "LAUNCH_ENCLOSURE_LIFTING_EYE";
    GroundSupportEquipmentType["LIFTING_BEAM"] = "LIFTING_BEAM";
    GroundSupportEquipmentType["LIFTING_FIXTURE"] = "LIFTING_FIXTURE";
    GroundSupportEquipmentType["LIFTING_STRAP"] = "LIFTING_STRAP";
    GroundSupportEquipmentType["LTA_SUSPENSION_ALIGNMENT_FIXTURE"] = "LTA_SUSPENSION_ALIGNMENT_FIXTURE";
    GroundSupportEquipmentType["MAN_LIFT"] = "MAN_LIFT";
    GroundSupportEquipmentType["MEASURING_TAPE"] = "MEASURING_TAPE";
    GroundSupportEquipmentType["MERCURY_VAPOR_ANALYZER"] = "MERCURY_VAPOR_ANALYZER";
    GroundSupportEquipmentType["MICROMETER"] = "MICROMETER";
    GroundSupportEquipmentType["MODULATION_METER"] = "MODULATION_METER";
    GroundSupportEquipmentType["MOISTURE_MONITOR"] = "MOISTURE_MONITOR";
    GroundSupportEquipmentType["MONITOR_GROUND"] = "MONITOR_GROUND";
    GroundSupportEquipmentType["MOUNTING_FRAME"] = "MOUNTING_FRAME";
    GroundSupportEquipmentType["MULITPLE_WATER_CONNECTOR"] = "MULITPLE_WATER_CONNECTOR";
    GroundSupportEquipmentType["MULTIMETER"] = "MULTIMETER";
    GroundSupportEquipmentType["MULTIPLE_WATER_CONNECTOR_"] = "MULTIPLE_WATER_CONNECTOR_";
    GroundSupportEquipmentType["MULTITESTER"] = "MULTITESTER";
    GroundSupportEquipmentType["NECK_PLATE_INTERFACE_FIXTURE"] = "NECK_PLATE_INTERFACE_FIXTURE";
    GroundSupportEquipmentType["O2_MONITOR"] = "O2_MONITOR";
    GroundSupportEquipmentType["OHMMETER"] = "OHMMETER";
    GroundSupportEquipmentType["OSCILLATOR"] = "OSCILLATOR";
    GroundSupportEquipmentType["OSCILLOSCOPE"] = "OSCILLOSCOPE";
    GroundSupportEquipmentType["OVEN"] = "OVEN";
    GroundSupportEquipmentType["PALLET_JACK"] = "PALLET_JACK";
    GroundSupportEquipmentType["PANEL_METER"] = "PANEL_METER";
    GroundSupportEquipmentType["PARTICLE_COUNTER"] = "PARTICLE_COUNTER";
    GroundSupportEquipmentType["PH_METER"] = "PH_METER";
    GroundSupportEquipmentType["PIN_RETENTION_TOOL"] = "PIN_RETENTION_TOOL";
    GroundSupportEquipmentType["PLATE_MILLIOHM_REFERENCE"] = "PLATE_MILLIOHM_REFERENCE";
    GroundSupportEquipmentType["PLSS_WEIGHT_BOX"] = "PLSS_WEIGHT_BOX";
    GroundSupportEquipmentType["POWER_SUPPLY"] = "POWER_SUPPLY";
    GroundSupportEquipmentType["PRESSURE_GAUGE"] = "PRESSURE_GAUGE";
    GroundSupportEquipmentType["PRESSURE_REGULATOR"] = "PRESSURE_REGULATOR";
    GroundSupportEquipmentType["PRESSURE_SWITCH"] = "PRESSURE_SWITCH";
    GroundSupportEquipmentType["PROBE"] = "PROBE";
    GroundSupportEquipmentType["PT_PRESSURE_TRANSDUCER"] = "PT_PRESSURE_TRANSDUCER";
    GroundSupportEquipmentType["PTD_PRESSURE_TRANSDUCER_DISPLAY"] = "PTD_PRESSURE_TRANSDUCER_DISPLAY";
    GroundSupportEquipmentType["RADIO_TEST_SET"] = "RADIO_TEST_SET";
    GroundSupportEquipmentType["RECEIVER"] = "RECEIVER";
    GroundSupportEquipmentType["RECORDERS"] = "RECORDERS";
    GroundSupportEquipmentType["REGULATOR"] = "REGULATOR";
    GroundSupportEquipmentType["RELIEF_VALVE"] = "RELIEF_VALVE";
    GroundSupportEquipmentType["SAMPLE_CYLINDER"] = "SAMPLE_CYLINDER";
    GroundSupportEquipmentType["SCALE"] = "SCALE";
    GroundSupportEquipmentType["SEMU_LIFTING_BAR_ASSY"] = "SEMU_LIFTING_BAR_ASSY";
    GroundSupportEquipmentType["SENSOR_MODULE"] = "SENSOR_MODULE";
    GroundSupportEquipmentType["SHACKLE"] = "SHACKLE";
    GroundSupportEquipmentType["SHIPPING_CONTAINER"] = "SHIPPING_CONTAINER";
    GroundSupportEquipmentType["SHUCK_TEST_MICROMETER_GAUGE_ASSY"] = "SHUCK_TEST_MICROMETER_GAUGE_ASSY";
    GroundSupportEquipmentType["SOLDERING_STATION"] = "SOLDERING_STATION";
    GroundSupportEquipmentType["SOUND_LEVEL_CALIBRATOR"] = "SOUND_LEVEL_CALIBRATOR";
    GroundSupportEquipmentType["SOUND_LEVEL_METER"] = "SOUND_LEVEL_METER";
    GroundSupportEquipmentType["SOUND_PRESSURE_METER"] = "SOUND_PRESSURE_METER";
    GroundSupportEquipmentType["STATIC_LOCATOR"] = "STATIC_LOCATOR";
    GroundSupportEquipmentType["SUPPORT_PLATFORM_EMU_ADJUSTIBLE_HEIGHT"] = "SUPPORT_PLATFORM_EMU_ADJUSTIBLE_HEIGHT";
    GroundSupportEquipmentType["SURFACE_RESIST_IND"] = "SURFACE_RESIST_IND";
    GroundSupportEquipmentType["SURFACE_RESISTIVITY_METER"] = "SURFACE_RESISTIVITY_METER";
    GroundSupportEquipmentType["TD_TEMPERATURE_DISPLAY"] = "TD_TEMPERATURE_DISPLAY";
    GroundSupportEquipmentType["TEMP_HUMIDITY_RECORDER"] = "TEMP_HUMIDITY_RECORDER";
    GroundSupportEquipmentType["TEMPERATURE_CONTROLLER"] = "TEMPERATURE_CONTROLLER";
    GroundSupportEquipmentType["TEMPERATURE_LIMIT_CONTROLLER"] = "TEMPERATURE_LIMIT_CONTROLLER";
    GroundSupportEquipmentType["TEMPERATURE_RECORDER"] = "TEMPERATURE_RECORDER";
    GroundSupportEquipmentType["TEST_STAND"] = "TEST_STAND";
    GroundSupportEquipmentType["THERMOCOUPLE_DISPLAY"] = "THERMOCOUPLE_DISPLAY";
    GroundSupportEquipmentType["THERMOMETER"] = "THERMOMETER";
    GroundSupportEquipmentType["TIMER"] = "TIMER";
    GroundSupportEquipmentType["TIMER_CLOCK"] = "TIMER_CLOCK";
    GroundSupportEquipmentType["TORQUE_DRIVER"] = "TORQUE_DRIVER";
    GroundSupportEquipmentType["TORQUE_METER"] = "TORQUE_METER";
    GroundSupportEquipmentType["TORQUE_TESTER"] = "TORQUE_TESTER";
    GroundSupportEquipmentType["TORQUE_TRANSDUCER"] = "TORQUE_TRANSDUCER";
    GroundSupportEquipmentType["TORQUE_WRENCH"] = "TORQUE_WRENCH";
    GroundSupportEquipmentType["TT_TEMPERATURE_TRANSMITTER"] = "TT_TEMPERATURE_TRANSMITTER";
    GroundSupportEquipmentType["_2_KN_LOAD_CELL"] = "_2_KN_LOAD_CELL";
    GroundSupportEquipmentType["VACUUM_CHAMBER_ASSY_22_LITER"] = "VACUUM_CHAMBER_ASSY_22_LITER";
    GroundSupportEquipmentType["VACUUM_DISPLAY"] = "VACUUM_DISPLAY";
    GroundSupportEquipmentType["VACUUM_OVEN"] = "VACUUM_OVEN";
    GroundSupportEquipmentType["VACUUM_PUMP"] = "VACUUM_PUMP";
    GroundSupportEquipmentType["VACUUM_SENSOR"] = "VACUUM_SENSOR";
    GroundSupportEquipmentType["VEHICLE_BOBTAIL_TRUCK"] = "VEHICLE_BOBTAIL_TRUCK";
    GroundSupportEquipmentType["VELOCITY_METER"] = "VELOCITY_METER";
    GroundSupportEquipmentType["VENT_HOOD"] = "VENT_HOOD";
    GroundSupportEquipmentType["VITAL_SIGNS_SIMILATOR"] = "VITAL_SIGNS_SIMILATOR";
    GroundSupportEquipmentType["VOLTMETER"] = "VOLTMETER";
    GroundSupportEquipmentType["VRMS_METER"] = "VRMS_METER";
    GroundSupportEquipmentType["WATER_CONNECTOR_AIRLOCK"] = "WATER_CONNECTOR_AIRLOCK";
    GroundSupportEquipmentType["WATER_TANK"] = "WATER_TANK";
    GroundSupportEquipmentType["WATER_TRAP"] = "WATER_TRAP";
    GroundSupportEquipmentType["WAVEFORM_GENERATOR"] = "WAVEFORM_GENERATOR";
    GroundSupportEquipmentType["WAVEFORM_MONITOR"] = "WAVEFORM_MONITOR";
    GroundSupportEquipmentType["WEIGHT_BOX"] = "WEIGHT_BOX";
    GroundSupportEquipmentType["WELD_EXHAUST_HOOD"] = "WELD_EXHAUST_HOOD";
    GroundSupportEquipmentType["WIRE_CRIMPER"] = "WIRE_CRIMPER";
    GroundSupportEquipmentType["WIRE_STRIPPER"] = "WIRE_STRIPPER";
    GroundSupportEquipmentType["WRIST_STRAP_FOOTWEAR_CHECKER"] = "WRIST_STRAP_FOOTWEAR_CHECKER";
    GroundSupportEquipmentType["X_RAY_MACHINE"] = "X_RAY_MACHINE";
})(GroundSupportEquipmentType || (GroundSupportEquipmentType = {}));
export var HardwareListType;
(function (HardwareListType) {
    HardwareListType["CREWMEMBER"] = "CREWMEMBER";
    HardwareListType["EMU_HARDWARE"] = "EMU_HARDWARE";
    HardwareListType["FLIGHT_SUPPORT_HARDWARE"] = "FLIGHT_SUPPORT_HARDWARE";
    HardwareListType["GROUND_SUPPORT_EQUIPMENT"] = "GROUND_SUPPORT_EQUIPMENT";
    HardwareListType["HARDWARE"] = "HARDWARE";
    HardwareListType["TOOLS"] = "TOOLS";
    HardwareListType["DELIVERY_MATRIX"] = "DELIVERY_MATRIX";
    HardwareListType["COSMIC_DOCUMENT_HARDWARE"] = "COSMIC_DOCUMENT_HARDWARE";
    HardwareListType["COSMIC_DOCUMENT_TOOLS"] = "COSMIC_DOCUMENT_TOOLS";
    HardwareListType["WORK_ORDER_STEP_HARDWARE"] = "WORK_ORDER_STEP_HARDWARE";
    HardwareListType["WORK_ORDER_STEP_TOOLS"] = "WORK_ORDER_STEP_TOOLS";
})(HardwareListType || (HardwareListType = {}));
export var InventoryClass;
(function (InventoryClass) {
    InventoryClass["CLASS_I"] = "CLASS_I";
    InventoryClass["CLASS_IE"] = "CLASS_IE";
    InventoryClass["CLASS_II"] = "CLASS_II";
    InventoryClass["CLASS_III"] = "CLASS_III";
    InventoryClass["CLASS_IIIC"] = "CLASS_IIIC";
    InventoryClass["CLASS_IIIW"] = "CLASS_IIIW";
    InventoryClass["GROUND_SUPPORT_EQUIPMENT"] = "GROUND_SUPPORT_EQUIPMENT";
    InventoryClass["SPECIAL_TEST_EQUIPMENT"] = "SPECIAL_TEST_EQUIPMENT";
    InventoryClass["PUBLIC_AFFAIRS_ONLY"] = "PUBLIC_AFFAIRS_ONLY";
    InventoryClass["PROGRAM_SUPPORT_EQUIPMENT"] = "PROGRAM_SUPPORT_EQUIPMENT";
    InventoryClass["_PROTOTYPE"] = "_PROTOTYPE";
    InventoryClass["UC"] = "UC";
    InventoryClass["UNKNOWN"] = "UNKNOWN";
    InventoryClass["WORKSITE_INTERFACE_FIXTURE"] = "WORKSITE_INTERFACE_FIXTURE";
})(InventoryClass || (InventoryClass = {}));
export var InventoryControl;
(function (InventoryControl) {
    InventoryControl["LOT"] = "LOT";
    InventoryControl["SERIAL"] = "SERIAL";
})(InventoryControl || (InventoryControl = {}));
export var InventoryMethod;
(function (InventoryMethod) {
    InventoryMethod["ARTICLE"] = "ARTICLE";
    InventoryMethod["BAG"] = "BAG";
    InventoryMethod["BOTTLE"] = "BOTTLE";
    InventoryMethod["BOX"] = "BOX";
    InventoryMethod["BUNDLE"] = "BUNDLE";
    InventoryMethod["CAN"] = "CAN";
    InventoryMethod["CARD"] = "CARD";
    InventoryMethod["CARTON"] = "CARTON";
    InventoryMethod["CASE"] = "CASE";
    InventoryMethod["CONTAINER"] = "CONTAINER";
    InventoryMethod["CYLINDER"] = "CYLINDER";
    InventoryMethod["DOZEN"] = "DOZEN";
    InventoryMethod["DRUM"] = "DRUM";
    InventoryMethod["EACH"] = "EACH";
    InventoryMethod["GROSS"] = "GROSS";
    InventoryMethod["JAR"] = "JAR";
    InventoryMethod["JOB"] = "JOB";
    InventoryMethod["KIT"] = "KIT";
    InventoryMethod["LOT"] = "LOT";
    InventoryMethod["PACKAGE"] = "PACKAGE";
    InventoryMethod["PAD"] = "PAD";
    InventoryMethod["PAGE"] = "PAGE";
    InventoryMethod["PAIL"] = "PAIL";
    InventoryMethod["PAIR"] = "PAIR";
    InventoryMethod["PER_THOUSAND"] = "PER_THOUSAND";
    InventoryMethod["PIECE"] = "PIECE";
    InventoryMethod["REAM"] = "REAM";
    InventoryMethod["REEL"] = "REEL";
    InventoryMethod["ROD"] = "ROD";
    InventoryMethod["ROLL"] = "ROLL";
    InventoryMethod["SET"] = "SET";
    InventoryMethod["SHEET"] = "SHEET";
    InventoryMethod["SPOOL"] = "SPOOL";
    InventoryMethod["TUBE"] = "TUBE";
    InventoryMethod["UNIT"] = "UNIT";
    InventoryMethod["VIAL"] = "VIAL";
})(InventoryMethod || (InventoryMethod = {}));
export var InventoryStatus;
(function (InventoryStatus) {
    InventoryStatus["IN_GRIEF"] = "IN_GRIEF";
    InventoryStatus["IN_INVENTORY"] = "IN_INVENTORY";
    InventoryStatus["IN_TRANSIT"] = "IN_TRANSIT";
    InventoryStatus["ISSUED"] = "ISSUED";
    InventoryStatus["NEEDS_INSPECTION"] = "NEEDS_INSPECTION";
    InventoryStatus["NEEDS_TRANSFER"] = "NEEDS_TRANSFER";
    InventoryStatus["ON_DOCK"] = "ON_DOCK";
    InventoryStatus["REQUESTED"] = "REQUESTED";
})(InventoryStatus || (InventoryStatus = {}));
export var InventoryUnitType;
(function (InventoryUnitType) {
    InventoryUnitType["DEGREE"] = "DEGREE";
    InventoryUnitType["RADIAN"] = "RADIAN";
    InventoryUnitType["INCH"] = "INCH";
    InventoryUnitType["FOOT"] = "FOOT";
    InventoryUnitType["YARD"] = "YARD";
    InventoryUnitType["METER"] = "METER";
    InventoryUnitType["SQUARE_INCH"] = "SQUARE_INCH";
    InventoryUnitType["SQUARE_FOOT"] = "SQUARE_FOOT";
    InventoryUnitType["SQUARE_YARD"] = "SQUARE_YARD";
    InventoryUnitType["SQUARE_METER"] = "SQUARE_METER";
    InventoryUnitType["GALLON"] = "GALLON";
    InventoryUnitType["OUNCE"] = "OUNCE";
    InventoryUnitType["PINT"] = "PINT";
    InventoryUnitType["QUART"] = "QUART";
    InventoryUnitType["MILLILITER"] = "MILLILITER";
    InventoryUnitType["LITER"] = "LITER";
    InventoryUnitType["CUBIC_FOOT"] = "CUBIC_FOOT";
    InventoryUnitType["CUBIC_INCH"] = "CUBIC_INCH";
    InventoryUnitType["POUND"] = "POUND";
    InventoryUnitType["GRAM"] = "GRAM";
    InventoryUnitType["KILOGRAM"] = "KILOGRAM";
    InventoryUnitType["SECOND"] = "SECOND";
    InventoryUnitType["MINUTE"] = "MINUTE";
    InventoryUnitType["HOUR"] = "HOUR";
    InventoryUnitType["DAY"] = "DAY";
    InventoryUnitType["MONTH"] = "MONTH";
    InventoryUnitType["YEAR"] = "YEAR";
    InventoryUnitType["SHOE_US"] = "SHOE_US";
    InventoryUnitType["PH"] = "PH";
    InventoryUnitType["CFU_PER_MILLILITER"] = "CFU_PER_MILLILITER";
    InventoryUnitType["MICRO_SEMENS_PER_CENTIMETER"] = "MICRO_SEMENS_PER_CENTIMETER";
    InventoryUnitType["PARTS_PER_BILLION"] = "PARTS_PER_BILLION";
    InventoryUnitType["MILLIMETER"] = "MILLIMETER";
    InventoryUnitType["DOZEN"] = "DOZEN";
    InventoryUnitType["CENTIMETER"] = "CENTIMETER";
})(InventoryUnitType || (InventoryUnitType = {}));
export var ItemMasterCategoryCode;
(function (ItemMasterCategoryCode) {
    ItemMasterCategoryCode["AB"] = "AB";
    ItemMasterCategoryCode["BE"] = "BE";
    ItemMasterCategoryCode["CA"] = "CA";
    ItemMasterCategoryCode["CC"] = "CC";
    ItemMasterCategoryCode["CD"] = "CD";
    ItemMasterCategoryCode["CE"] = "CE";
    ItemMasterCategoryCode["CH"] = "CH";
    ItemMasterCategoryCode["CP"] = "CP";
    ItemMasterCategoryCode["CR"] = "CR";
    ItemMasterCategoryCode["CS"] = "CS";
    ItemMasterCategoryCode["DA"] = "DA";
    ItemMasterCategoryCode["DC"] = "DC";
    ItemMasterCategoryCode["DD"] = "DD";
    ItemMasterCategoryCode["DS"] = "DS";
    ItemMasterCategoryCode["DT"] = "DT";
    ItemMasterCategoryCode["EE"] = "EE";
    ItemMasterCategoryCode["EH"] = "EH";
    ItemMasterCategoryCode["EL"] = "EL";
    ItemMasterCategoryCode["ES"] = "ES";
    ItemMasterCategoryCode["FC"] = "FC";
    ItemMasterCategoryCode["FE"] = "FE";
    ItemMasterCategoryCode["FF"] = "FF";
    ItemMasterCategoryCode["FL"] = "FL";
    ItemMasterCategoryCode["FS"] = "FS";
    ItemMasterCategoryCode["GS"] = "GS";
    ItemMasterCategoryCode["HF"] = "HF";
    ItemMasterCategoryCode["JH"] = "JH";
    ItemMasterCategoryCode["JP"] = "JP";
    ItemMasterCategoryCode["JS"] = "JS";
    ItemMasterCategoryCode["LS"] = "LS";
    ItemMasterCategoryCode["LT"] = "LT";
    ItemMasterCategoryCode["MS"] = "MS";
    ItemMasterCategoryCode["NF"] = "NF";
    ItemMasterCategoryCode["NI"] = "NI";
    ItemMasterCategoryCode["NL"] = "NL";
    ItemMasterCategoryCode["OC"] = "OC";
    ItemMasterCategoryCode["PR"] = "PR";
    ItemMasterCategoryCode["PS"] = "PS";
    ItemMasterCategoryCode["PT"] = "PT";
    ItemMasterCategoryCode["RF"] = "RF";
    ItemMasterCategoryCode["RM"] = "RM";
    ItemMasterCategoryCode["SC"] = "SC";
    ItemMasterCategoryCode["SD"] = "SD";
    ItemMasterCategoryCode["SE"] = "SE";
    ItemMasterCategoryCode["SF"] = "SF";
    ItemMasterCategoryCode["SG"] = "SG";
    ItemMasterCategoryCode["SL"] = "SL";
    ItemMasterCategoryCode["SP"] = "SP";
    ItemMasterCategoryCode["SR"] = "SR";
    ItemMasterCategoryCode["SS"] = "SS";
    ItemMasterCategoryCode["ST"] = "ST";
    ItemMasterCategoryCode["SV"] = "SV";
    ItemMasterCategoryCode["SW"] = "SW";
    ItemMasterCategoryCode["TA"] = "TA";
    ItemMasterCategoryCode["TB"] = "TB";
    ItemMasterCategoryCode["TR"] = "TR";
    ItemMasterCategoryCode["TS"] = "TS";
    ItemMasterCategoryCode["TX"] = "TX";
    ItemMasterCategoryCode["UU"] = "UU";
    ItemMasterCategoryCode["WH"] = "WH";
    ItemMasterCategoryCode["WW"] = "WW";
    ItemMasterCategoryCode["XE"] = "XE";
    ItemMasterCategoryCode["ZA"] = "ZA";
    ItemMasterCategoryCode["ZB"] = "ZB";
    ItemMasterCategoryCode["ZU"] = "ZU";
    ItemMasterCategoryCode["ZZ"] = "ZZ";
})(ItemMasterCategoryCode || (ItemMasterCategoryCode = {}));
export var ItemMasterCriticalityCode;
(function (ItemMasterCriticalityCode) {
    ItemMasterCriticalityCode["_1_1"] = "_1_1";
    ItemMasterCriticalityCode["_2_2"] = "_2_2";
    ItemMasterCriticalityCode["_3_3"] = "_3_3";
    ItemMasterCriticalityCode["_2_1R"] = "_2_1R";
    ItemMasterCriticalityCode["_3_1R"] = "_3_1R";
    ItemMasterCriticalityCode["_3_2R"] = "_3_2R";
})(ItemMasterCriticalityCode || (ItemMasterCriticalityCode = {}));
export var ItemMasterGreenProcurementCode;
(function (ItemMasterGreenProcurementCode) {
    ItemMasterGreenProcurementCode["C1"] = "C1";
    ItemMasterGreenProcurementCode["C2"] = "C2";
    ItemMasterGreenProcurementCode["C4"] = "C4";
    ItemMasterGreenProcurementCode["C5"] = "C5";
    ItemMasterGreenProcurementCode["C6"] = "C6";
    ItemMasterGreenProcurementCode["C7"] = "C7";
    ItemMasterGreenProcurementCode["C8"] = "C8";
    ItemMasterGreenProcurementCode["C9"] = "C9";
    ItemMasterGreenProcurementCode["C10"] = "C10";
    ItemMasterGreenProcurementCode["C11"] = "C11";
    ItemMasterGreenProcurementCode["C12"] = "C12";
    ItemMasterGreenProcurementCode["C13"] = "C13";
    ItemMasterGreenProcurementCode["C14"] = "C14";
    ItemMasterGreenProcurementCode["C15"] = "C15";
    ItemMasterGreenProcurementCode["C16"] = "C16";
    ItemMasterGreenProcurementCode["C17"] = "C17";
    ItemMasterGreenProcurementCode["C18"] = "C18";
    ItemMasterGreenProcurementCode["C19"] = "C19";
    ItemMasterGreenProcurementCode["C20"] = "C20";
    ItemMasterGreenProcurementCode["C21"] = "C21";
    ItemMasterGreenProcurementCode["C22"] = "C22";
    ItemMasterGreenProcurementCode["C23"] = "C23";
    ItemMasterGreenProcurementCode["C24"] = "C24";
    ItemMasterGreenProcurementCode["C25"] = "C25";
    ItemMasterGreenProcurementCode["C26"] = "C26";
    ItemMasterGreenProcurementCode["C27"] = "C27";
    ItemMasterGreenProcurementCode["C28"] = "C28";
    ItemMasterGreenProcurementCode["C29"] = "C29";
    ItemMasterGreenProcurementCode["L1"] = "L1";
    ItemMasterGreenProcurementCode["L2"] = "L2";
    ItemMasterGreenProcurementCode["L3"] = "L3";
    ItemMasterGreenProcurementCode["L4"] = "L4";
    ItemMasterGreenProcurementCode["L5"] = "L5";
    ItemMasterGreenProcurementCode["L6"] = "L6";
    ItemMasterGreenProcurementCode["L7"] = "L7";
    ItemMasterGreenProcurementCode["L8"] = "L8";
    ItemMasterGreenProcurementCode["L9"] = "L9";
    ItemMasterGreenProcurementCode["M1"] = "M1";
    ItemMasterGreenProcurementCode["M2"] = "M2";
    ItemMasterGreenProcurementCode["M3"] = "M3";
    ItemMasterGreenProcurementCode["M4"] = "M4";
    ItemMasterGreenProcurementCode["M5"] = "M5";
    ItemMasterGreenProcurementCode["M6"] = "M6";
    ItemMasterGreenProcurementCode["M7"] = "M7";
    ItemMasterGreenProcurementCode["M8"] = "M8";
    ItemMasterGreenProcurementCode["M9"] = "M9";
    ItemMasterGreenProcurementCode["M10"] = "M10";
    ItemMasterGreenProcurementCode["M11"] = "M11";
    ItemMasterGreenProcurementCode["M12"] = "M12";
    ItemMasterGreenProcurementCode["M13"] = "M13";
    ItemMasterGreenProcurementCode["M14"] = "M14";
    ItemMasterGreenProcurementCode["M15"] = "M15";
    ItemMasterGreenProcurementCode["M16"] = "M16";
    ItemMasterGreenProcurementCode["M17"] = "M17";
    ItemMasterGreenProcurementCode["M18"] = "M18";
    ItemMasterGreenProcurementCode["M19"] = "M19";
    ItemMasterGreenProcurementCode["M20"] = "M20";
    ItemMasterGreenProcurementCode["M21"] = "M21";
    ItemMasterGreenProcurementCode["M22"] = "M22";
    ItemMasterGreenProcurementCode["M23"] = "M23";
    ItemMasterGreenProcurementCode["M24"] = "M24";
    ItemMasterGreenProcurementCode["M25"] = "M25";
    ItemMasterGreenProcurementCode["M26"] = "M26";
    ItemMasterGreenProcurementCode["M27"] = "M27";
    ItemMasterGreenProcurementCode["N1"] = "N1";
    ItemMasterGreenProcurementCode["N2"] = "N2";
    ItemMasterGreenProcurementCode["N3"] = "N3";
    ItemMasterGreenProcurementCode["N4"] = "N4";
    ItemMasterGreenProcurementCode["N5"] = "N5";
    ItemMasterGreenProcurementCode["N6"] = "N6";
    ItemMasterGreenProcurementCode["N7"] = "N7";
    ItemMasterGreenProcurementCode["N8"] = "N8";
    ItemMasterGreenProcurementCode["N9"] = "N9";
    ItemMasterGreenProcurementCode["N10"] = "N10";
    ItemMasterGreenProcurementCode["N11"] = "N11";
    ItemMasterGreenProcurementCode["N12"] = "N12";
    ItemMasterGreenProcurementCode["N13"] = "N13";
    ItemMasterGreenProcurementCode["N16"] = "N16";
    ItemMasterGreenProcurementCode["N17"] = "N17";
    ItemMasterGreenProcurementCode["N18"] = "N18";
    ItemMasterGreenProcurementCode["N19"] = "N19";
    ItemMasterGreenProcurementCode["N20"] = "N20";
    ItemMasterGreenProcurementCode["N21"] = "N21";
    ItemMasterGreenProcurementCode["N22"] = "N22";
    ItemMasterGreenProcurementCode["N23"] = "N23";
    ItemMasterGreenProcurementCode["N24"] = "N24";
    ItemMasterGreenProcurementCode["N25"] = "N25";
    ItemMasterGreenProcurementCode["P1"] = "P1";
    ItemMasterGreenProcurementCode["P2"] = "P2";
    ItemMasterGreenProcurementCode["P3"] = "P3";
    ItemMasterGreenProcurementCode["P4"] = "P4";
    ItemMasterGreenProcurementCode["P5"] = "P5";
    ItemMasterGreenProcurementCode["P6"] = "P6";
    ItemMasterGreenProcurementCode["P7"] = "P7";
    ItemMasterGreenProcurementCode["P8"] = "P8";
    ItemMasterGreenProcurementCode["P9"] = "P9";
    ItemMasterGreenProcurementCode["P10"] = "P10";
    ItemMasterGreenProcurementCode["P11"] = "P11";
    ItemMasterGreenProcurementCode["P12"] = "P12";
    ItemMasterGreenProcurementCode["P13"] = "P13";
    ItemMasterGreenProcurementCode["P14"] = "P14";
    ItemMasterGreenProcurementCode["P15"] = "P15";
    ItemMasterGreenProcurementCode["P16"] = "P16";
    ItemMasterGreenProcurementCode["P17"] = "P17";
    ItemMasterGreenProcurementCode["P18"] = "P18";
    ItemMasterGreenProcurementCode["P19"] = "P19";
    ItemMasterGreenProcurementCode["P20"] = "P20";
    ItemMasterGreenProcurementCode["P21"] = "P21";
    ItemMasterGreenProcurementCode["P22"] = "P22";
    ItemMasterGreenProcurementCode["P23"] = "P23";
    ItemMasterGreenProcurementCode["P24"] = "P24";
    ItemMasterGreenProcurementCode["P25"] = "P25";
    ItemMasterGreenProcurementCode["P26"] = "P26";
    ItemMasterGreenProcurementCode["P27"] = "P27";
    ItemMasterGreenProcurementCode["P28"] = "P28";
    ItemMasterGreenProcurementCode["P29"] = "P29";
    ItemMasterGreenProcurementCode["P30"] = "P30";
    ItemMasterGreenProcurementCode["P31"] = "P31";
    ItemMasterGreenProcurementCode["P32"] = "P32";
    ItemMasterGreenProcurementCode["P33"] = "P33";
    ItemMasterGreenProcurementCode["P34"] = "P34";
    ItemMasterGreenProcurementCode["P35"] = "P35";
    ItemMasterGreenProcurementCode["P36"] = "P36";
    ItemMasterGreenProcurementCode["R1"] = "R1";
    ItemMasterGreenProcurementCode["R2"] = "R2";
    ItemMasterGreenProcurementCode["R3"] = "R3";
    ItemMasterGreenProcurementCode["R4"] = "R4";
    ItemMasterGreenProcurementCode["R5"] = "R5";
    ItemMasterGreenProcurementCode["R6"] = "R6";
    ItemMasterGreenProcurementCode["R7"] = "R7";
    ItemMasterGreenProcurementCode["R8"] = "R8";
    ItemMasterGreenProcurementCode["R9"] = "R9";
    ItemMasterGreenProcurementCode["T1"] = "T1";
    ItemMasterGreenProcurementCode["T2"] = "T2";
    ItemMasterGreenProcurementCode["T3"] = "T3";
    ItemMasterGreenProcurementCode["T4"] = "T4";
    ItemMasterGreenProcurementCode["T5"] = "T5";
    ItemMasterGreenProcurementCode["T6"] = "T6";
    ItemMasterGreenProcurementCode["T7"] = "T7";
    ItemMasterGreenProcurementCode["T8"] = "T8";
    ItemMasterGreenProcurementCode["T9"] = "T9";
    ItemMasterGreenProcurementCode["T10"] = "T10";
    ItemMasterGreenProcurementCode["T11"] = "T11";
    ItemMasterGreenProcurementCode["T12"] = "T12";
    ItemMasterGreenProcurementCode["T13"] = "T13";
    ItemMasterGreenProcurementCode["T14"] = "T14";
    ItemMasterGreenProcurementCode["T15"] = "T15";
    ItemMasterGreenProcurementCode["V1"] = "V1";
    ItemMasterGreenProcurementCode["V2"] = "V2";
    ItemMasterGreenProcurementCode["V3"] = "V3";
})(ItemMasterGreenProcurementCode || (ItemMasterGreenProcurementCode = {}));
export var ItemMasterPreciousMetal;
(function (ItemMasterPreciousMetal) {
    ItemMasterPreciousMetal["GOLD"] = "GOLD";
    ItemMasterPreciousMetal["IRIDIUM"] = "IRIDIUM";
    ItemMasterPreciousMetal["OSMIUM"] = "OSMIUM";
    ItemMasterPreciousMetal["PALLADIUM"] = "PALLADIUM";
    ItemMasterPreciousMetal["PLATINUM"] = "PLATINUM";
    ItemMasterPreciousMetal["RHODIUM"] = "RHODIUM";
    ItemMasterPreciousMetal["RUTHENIUM"] = "RUTHENIUM";
    ItemMasterPreciousMetal["SILVER"] = "SILVER";
})(ItemMasterPreciousMetal || (ItemMasterPreciousMetal = {}));
export var ItemMasterPropertyClass;
(function (ItemMasterPropertyClass) {
    ItemMasterPropertyClass["BUILDINGS"] = "BUILDINGS";
    ItemMasterPropertyClass["COMPUTER_SOFTWARE"] = "COMPUTER_SOFTWARE";
    ItemMasterPropertyClass["LAND"] = "LAND";
    ItemMasterPropertyClass["MATERIAL"] = "MATERIAL";
    ItemMasterPropertyClass["NEEDS_ASSIGNMENT"] = "NEEDS_ASSIGNMENT";
    ItemMasterPropertyClass["OTHER_STRUCTURES"] = "OTHER_STRUCTURES";
    ItemMasterPropertyClass["PLANT_EQUIPMENT"] = "PLANT_EQUIPMENT";
    ItemMasterPropertyClass["SENSITIVE_EQUIPMENT"] = "SENSITIVE_EQUIPMENT";
    ItemMasterPropertyClass["SERVICE_MAINTENANCE"] = "SERVICE_MAINTENANCE";
    ItemMasterPropertyClass["SPACE_HARDWARE_APP"] = "SPACE_HARDWARE_APP";
    ItemMasterPropertyClass["SPECIAL_TEST_EQUIPMENT"] = "SPECIAL_TEST_EQUIPMENT";
    ItemMasterPropertyClass["SPECIAL_TOOLING"] = "SPECIAL_TOOLING";
})(ItemMasterPropertyClass || (ItemMasterPropertyClass = {}));
export var ItemMasterType;
(function (ItemMasterType) {
    ItemMasterType["ANCILLARY_EQUIPMENT"] = "ANCILLARY_EQUIPMENT";
    ItemMasterType["BATTERY"] = "BATTERY";
    ItemMasterType["CAPITAL_PROPERTY"] = "CAPITAL_PROPERTY";
    ItemMasterType["COMMUNICATIONS_EQUIPMENT"] = "COMMUNICATIONS_EQUIPMENT";
    ItemMasterType["CREW_EQUIPMENT_STOWAGE_PROV"] = "CREW_EQUIPMENT_STOWAGE_PROV";
    ItemMasterType["CREW_ESCAPE_SYSTEM"] = "CREW_ESCAPE_SYSTEM";
    ItemMasterType["CTSD"] = "CTSD";
    ItemMasterType["DECALS_PLACARDS_TAGS_PATCHES"] = "DECALS_PLACARDS_TAGS_PATCHES";
    ItemMasterType["DISPLAY_AND_CONTROL_EQUIPMENT"] = "DISPLAY_AND_CONTROL_EQUIPMENT";
    ItemMasterType["ELECTRICAL"] = "ELECTRICAL";
    ItemMasterType["EMU_LSS_FLIGHT_SSA_NBL"] = "EMU_LSS_FLIGHT_SSA_NBL";
    ItemMasterType["EVA_SUPPORT_EQUIPMENT"] = "EVA_SUPPORT_EQUIPMENT";
    ItemMasterType["EVA_TOOLS"] = "EVA_TOOLS";
    ItemMasterType["FACILITIES_CONTRACT"] = "FACILITIES_CONTRACT";
    ItemMasterType["FILM"] = "FILM";
    ItemMasterType["FOOD_AND_MEDICAL_SYSTEMS"] = "FOOD_AND_MEDICAL_SYSTEMS";
    ItemMasterType["GENERAL_COMPUTER_SUPPLIES"] = "GENERAL_COMPUTER_SUPPLIES";
    ItemMasterType["GROUND_SUPPORT_EQUIPMENT"] = "GROUND_SUPPORT_EQUIPMENT";
    ItemMasterType["IDWA"] = "IDWA";
    ItemMasterType["ISIL_IDWA"] = "ISIL_IDWA";
    ItemMasterType["ISS_IDWA"] = "ISS_IDWA";
    ItemMasterType["ISS_M_AND_O_HARDWARE_PBA"] = "ISS_M_AND_O_HARDWARE_PBA";
    ItemMasterType["ISS_WORK_THROUGH_FCE"] = "ISS_WORK_THROUGH_FCE";
    ItemMasterType["MANNED_MANEUVERING_UNIT"] = "MANNED_MANEUVERING_UNIT";
    ItemMasterType["MECHANICAL"] = "MECHANICAL";
    ItemMasterType["MULTIPLE_EQ_GROUPS_FLIGHT"] = "MULTIPLE_EQ_GROUPS_FLIGHT";
    ItemMasterType["MULTIPLE_EQ_GROUPS_PSE"] = "MULTIPLE_EQ_GROUPS_PSE";
    ItemMasterType["NON_FCE"] = "NON_FCE";
    ItemMasterType["OBS"] = "OBS";
    ItemMasterType["OH_HOUSTON_OPS_JANITOR_SUPP"] = "OH_HOUSTON_OPS_JANITOR_SUPP";
    ItemMasterType["OH_HOUSTON_OPS_OFFICE_SUPP"] = "OH_HOUSTON_OPS_OFFICE_SUPP";
    ItemMasterType["OH_HOUSTON_OPS_REPROD_SUPP"] = "OH_HOUSTON_OPS_REPROD_SUPP";
    ItemMasterType["OH_SR_QA_ONLY"] = "OH_SR_QA_ONLY";
    ItemMasterType["OTHER_STS_SUPPORT_EQUIPMENT"] = "OTHER_STS_SUPPORT_EQUIPMENT";
    ItemMasterType["PGSC_PCS"] = "PGSC_PCS";
    ItemMasterType["PHOTOGRAPHIC_EQUIPMENT"] = "PHOTOGRAPHIC_EQUIPMENT";
    ItemMasterType["PREPACK_BENCH_REVIEW"] = "PREPACK_BENCH_REVIEW";
    ItemMasterType["SAFER"] = "SAFER";
    ItemMasterType["SHARED_CONSUMABLES"] = "SHARED_CONSUMABLES";
    ItemMasterType["SPECIAL_ORDER"] = "SPECIAL_ORDER";
    ItemMasterType["VEHICLE_ACCOMMODATIONS"] = "VEHICLE_ACCOMMODATIONS";
    ItemMasterType["XEMU"] = "XEMU";
    ItemMasterType["XFSE"] = "XFSE";
    ItemMasterType["XINFO"] = "XINFO";
    ItemMasterType["XPGS"] = "XPGS";
    ItemMasterType["XPLSS"] = "XPLSS";
})(ItemMasterType || (ItemMasterType = {}));
export var LimitedLifeCode;
(function (LimitedLifeCode) {
    LimitedLifeCode["BAT"] = "BAT";
    LimitedLifeCode["BOT"] = "BOT";
    LimitedLifeCode["CAL"] = "CAL";
    LimitedLifeCode["CCC"] = "CCC";
    LimitedLifeCode["CYC"] = "CYC";
    LimitedLifeCode["FAN"] = "FAN";
    LimitedLifeCode["REG"] = "REG";
    LimitedLifeCode["SSA"] = "SSA";
})(LimitedLifeCode || (LimitedLifeCode = {}));
export var LimitedLifeKind;
(function (LimitedLifeKind) {
    LimitedLifeKind["DATE"] = "DATE";
    LimitedLifeKind["INTERVAL"] = "INTERVAL";
    LimitedLifeKind["USES"] = "USES";
})(LimitedLifeKind || (LimitedLifeKind = {}));
export var LimitedLifeType;
(function (LimitedLifeType) {
    LimitedLifeType["OPERATION"] = "OPERATION";
    LimitedLifeType["SHELF"] = "SHELF";
    LimitedLifeType["USAGE"] = "USAGE";
    LimitedLifeType["INTERVAL"] = "INTERVAL";
    LimitedLifeType["ON_ORBIT"] = "ON_ORBIT";
    LimitedLifeType["PRESSURIZED_TIME"] = "PRESSURIZED_TIME";
    LimitedLifeType["STATIC"] = "STATIC";
    LimitedLifeType["CYCLE"] = "CYCLE";
    LimitedLifeType["EVA"] = "EVA";
    LimitedLifeType["FLIGHT"] = "FLIGHT";
})(LimitedLifeType || (LimitedLifeType = {}));
export var LinkType;
(function (LinkType) {
    LinkType["COSMIC"] = "COSMIC";
    LinkType["DOCUMENT"] = "DOCUMENT";
    LinkType["EDCC"] = "EDCC";
    LinkType["EDS"] = "EDS";
    LinkType["OTHER"] = "OTHER";
    LinkType["QARC"] = "QARC";
    LinkType["RIDS"] = "RIDS";
})(LinkType || (LinkType = {}));
export var LocationAutocompleteField;
(function (LocationAutocompleteField) {
    LocationAutocompleteField["BUILDING"] = "BUILDING";
    LocationAutocompleteField["ROOM"] = "ROOM";
    LocationAutocompleteField["STOCK"] = "STOCK";
    LocationAutocompleteField["BIN"] = "BIN";
    LocationAutocompleteField["CONTRACT_NUMBER"] = "CONTRACT_NUMBER";
    LocationAutocompleteField["ORGANIZATION_CODE"] = "ORGANIZATION_CODE";
})(LocationAutocompleteField || (LocationAutocompleteField = {}));
export var MakeBuySupply;
(function (MakeBuySupply) {
    MakeBuySupply["MAKE"] = "MAKE";
    MakeBuySupply["BUY"] = "BUY";
    MakeBuySupply["SUPPLY"] = "SUPPLY";
})(MakeBuySupply || (MakeBuySupply = {}));
export var MeetingCadenceType;
(function (MeetingCadenceType) {
    MeetingCadenceType["NONE"] = "NONE";
    MeetingCadenceType["DAILY"] = "DAILY";
    MeetingCadenceType["WEEKLY"] = "WEEKLY";
    MeetingCadenceType["MONTHLY"] = "MONTHLY";
    MeetingCadenceType["MONTHLY_WEEKDAY"] = "MONTHLY_WEEKDAY";
})(MeetingCadenceType || (MeetingCadenceType = {}));
export var MeetingSeriesType;
(function (MeetingSeriesType) {
    MeetingSeriesType["INSIGHT"] = "INSIGHT";
    MeetingSeriesType["INTERNAL"] = "INTERNAL";
    MeetingSeriesType["QUARTERLY_MANAGEMENT_REVIEW"] = "QUARTERLY_MANAGEMENT_REVIEW";
    MeetingSeriesType["MILESTONE_REVIEW"] = "MILESTONE_REVIEW";
})(MeetingSeriesType || (MeetingSeriesType = {}));
export var ObjectClass;
(function (ObjectClass) {
    ObjectClass["_5611"] = "_5611";
    ObjectClass["_5621"] = "_5621";
    ObjectClass["_5631"] = "_5631";
    ObjectClass["_5641"] = "_5641";
    ObjectClass["_5651"] = "_5651";
    ObjectClass["_5661"] = "_5661";
    ObjectClass["_5671"] = "_5671";
    ObjectClass["_5681"] = "_5681";
    ObjectClass["_5691"] = "_5691";
    ObjectClass["_5711"] = "_5711";
    ObjectClass["_5721"] = "_5721";
    ObjectClass["_5725"] = "_5725";
    ObjectClass["_5731"] = "_5731";
    ObjectClass["_5741"] = "_5741";
    ObjectClass["_5742"] = "_5742";
    ObjectClass["_5751"] = "_5751";
    ObjectClass["_5761"] = "_5761";
    ObjectClass["_5762"] = "_5762";
    ObjectClass["_5765"] = "_5765";
    ObjectClass["NO_OBJECT_CLASS_FOUND"] = "NO_OBJECT_CLASS_FOUND";
})(ObjectClass || (ObjectClass = {}));
export var OrganizationType;
(function (OrganizationType) {
    OrganizationType["CONTRACTOR"] = "CONTRACTOR";
    OrganizationType["GOVERNMENT"] = "GOVERNMENT";
    OrganizationType["MANUFACTURER"] = "MANUFACTURER";
    OrganizationType["PROCESSOR"] = "PROCESSOR";
    OrganizationType["SUPPLIER"] = "SUPPLIER";
    OrganizationType["VENDOR"] = "VENDOR";
})(OrganizationType || (OrganizationType = {}));
export var PhysicalInventoryAuditAdjustmentReason;
(function (PhysicalInventoryAuditAdjustmentReason) {
    PhysicalInventoryAuditAdjustmentReason["DAMAGE_OR_DESTRUCTION"] = "DAMAGE_OR_DESTRUCTION";
    PhysicalInventoryAuditAdjustmentReason["EXCESS_TRANSFERS_TO_PDO"] = "EXCESS_TRANSFERS_TO_PDO";
    PhysicalInventoryAuditAdjustmentReason["LOSS"] = "LOSS";
    PhysicalInventoryAuditAdjustmentReason["OBSOLESCENCE_OR_DETERIORATION"] = "OBSOLESCENCE_OR_DETERIORATION";
    PhysicalInventoryAuditAdjustmentReason["OPERATIONAL_ERRORS"] = "OPERATIONAL_ERRORS";
    PhysicalInventoryAuditAdjustmentReason["PHYSICAL_INVENTORY_DISCREPANCIES"] = "PHYSICAL_INVENTORY_DISCREPANCIES";
    PhysicalInventoryAuditAdjustmentReason["RETURNS_TO_VENDOR"] = "RETURNS_TO_VENDOR";
    PhysicalInventoryAuditAdjustmentReason["THEFT"] = "THEFT";
})(PhysicalInventoryAuditAdjustmentReason || (PhysicalInventoryAuditAdjustmentReason = {}));
export var PhysicalInventoryAuditStatus;
(function (PhysicalInventoryAuditStatus) {
    PhysicalInventoryAuditStatus["OPEN"] = "OPEN";
    PhysicalInventoryAuditStatus["COMPLETE"] = "COMPLETE";
    PhysicalInventoryAuditStatus["CANCELED"] = "CANCELED";
})(PhysicalInventoryAuditStatus || (PhysicalInventoryAuditStatus = {}));
export var PhysicalInventoryAuditType;
(function (PhysicalInventoryAuditType) {
    PhysicalInventoryAuditType["WALL_TO_WALL"] = "WALL_TO_WALL";
    PhysicalInventoryAuditType["SAMPLE"] = "SAMPLE";
})(PhysicalInventoryAuditType || (PhysicalInventoryAuditType = {}));
export var PressurizationRange;
(function (PressurizationRange) {
    PressurizationRange["ZERO_POINT_TWO_TO_ONE_POINT_FOUR_ONE_PSI"] = "ZERO_POINT_TWO_TO_ONE_POINT_FOUR_ONE_PSI";
    PressurizationRange["ONE_POINT_FOUR_ONE_TO_FIVE_POINT_THREE_PSI"] = "ONE_POINT_FOUR_ONE_TO_FIVE_POINT_THREE_PSI";
    PressurizationRange["FIVE_POINT_THREE_ONE_PLUS_PSI"] = "FIVE_POINT_THREE_ONE_PLUS_PSI";
})(PressurizationRange || (PressurizationRange = {}));
export var Priority;
(function (Priority) {
    Priority["HIGH"] = "HIGH";
    Priority["MEDIUM"] = "MEDIUM";
    Priority["LOW"] = "LOW";
})(Priority || (Priority = {}));
export var ReportPeriod;
(function (ReportPeriod) {
    ReportPeriod["MONTH"] = "MONTH";
    ReportPeriod["QUARTER"] = "QUARTER";
})(ReportPeriod || (ReportPeriod = {}));
export var ReturnableStatus;
(function (ReturnableStatus) {
    ReturnableStatus["RETURNABLE_INVENTORY"] = "RETURNABLE_INVENTORY";
    ReturnableStatus["NON_RETURNABLE_INVENTORY"] = "NON_RETURNABLE_INVENTORY";
    ReturnableStatus["RETURNABLE_ITEM_INSTANCE"] = "RETURNABLE_ITEM_INSTANCE";
})(ReturnableStatus || (ReturnableStatus = {}));
export var RiskCategory;
(function (RiskCategory) {
    RiskCategory["MISSION_SUCCESS"] = "MISSION_SUCCESS";
    RiskCategory["PROGRAM_MANAGEMENT"] = "PROGRAM_MANAGEMENT";
    RiskCategory["PROGRAMMATIC"] = "PROGRAMMATIC";
    RiskCategory["RISK_RECOVERY_COST"] = "RISK_RECOVERY_COST";
    RiskCategory["SAFETY"] = "SAFETY";
    RiskCategory["SUPPORTABILITY"] = "SUPPORTABILITY";
    RiskCategory["SUPPORTABILITY_COST"] = "SUPPORTABILITY_COST";
    RiskCategory["SUPPORTABILITY_SCHEDULE"] = "SUPPORTABILITY_SCHEDULE";
    RiskCategory["TECHNICAL"] = "TECHNICAL";
})(RiskCategory || (RiskCategory = {}));
export var RiskClosureType;
(function (RiskClosureType) {
    RiskClosureType["ACCEPTED"] = "ACCEPTED";
    RiskClosureType["MITIGATED"] = "MITIGATED";
    RiskClosureType["TRANSFERRED"] = "TRANSFERRED";
})(RiskClosureType || (RiskClosureType = {}));
export var RiskHardwareType;
(function (RiskHardwareType) {
    RiskHardwareType["EMU"] = "EMU";
    RiskHardwareType["TOOLS"] = "TOOLS";
})(RiskHardwareType || (RiskHardwareType = {}));
export var RiskMitigationTeam;
(function (RiskMitigationTeam) {
    RiskMitigationTeam["OPERATIONS"] = "OPERATIONS";
    RiskMitigationTeam["ENGINEERING"] = "ENGINEERING";
    RiskMitigationTeam["PROJECT_MANAGEMENT"] = "PROJECT_MANAGEMENT";
})(RiskMitigationTeam || (RiskMitigationTeam = {}));
export var RiskReportability;
(function (RiskReportability) {
    RiskReportability["DEFAULT"] = "DEFAULT";
    RiskReportability["ALWAYS"] = "ALWAYS";
    RiskReportability["NEVER"] = "NEVER";
})(RiskReportability || (RiskReportability = {}));
export var SerialLot;
(function (SerialLot) {
    SerialLot["SERIAL"] = "SERIAL";
    SerialLot["LOT"] = "LOT";
})(SerialLot || (SerialLot = {}));
export var Side;
(function (Side) {
    Side["NONE"] = "NONE";
    Side["LEFT"] = "LEFT";
    Side["RIGHT"] = "RIGHT";
})(Side || (Side = {}));
export var StoplightStatus;
(function (StoplightStatus) {
    StoplightStatus["GREEN"] = "GREEN";
    StoplightStatus["YELLOW"] = "YELLOW";
    StoplightStatus["RED"] = "RED";
})(StoplightStatus || (StoplightStatus = {}));
export var TelemetryType;
(function (TelemetryType) {
    TelemetryType["ERROR"] = "ERROR";
    TelemetryType["TELEMETRY"] = "TELEMETRY";
})(TelemetryType || (TelemetryType = {}));
export var TpsDocumentType;
(function (TpsDocumentType) {
    TpsDocumentType["CONFIGURATION_CHANGE"] = "CONFIGURATION_CHANGE";
    TpsDocumentType["FABRICATION"] = "FABRICATION";
    TpsDocumentType["ASSEMBLY"] = "ASSEMBLY";
    TpsDocumentType["TEMPORARY_CONFIGURATION_CHANGE"] = "TEMPORARY_CONFIGURATION_CHANGE";
    TpsDocumentType["DOWNGRADE_NON_DISCREPANT"] = "DOWNGRADE_NON_DISCREPANT";
    TpsDocumentType["CLASSIFICATION"] = "CLASSIFICATION";
    TpsDocumentType["ACTIVATION_INACTIVATION_OF_HARDWARE"] = "ACTIVATION_INACTIVATION_OF_HARDWARE";
    TpsDocumentType["OTHER_CONFIGURATION_CHANGE"] = "OTHER_CONFIGURATION_CHANGE";
    TpsDocumentType["MAINTENANCE"] = "MAINTENANCE";
    TpsDocumentType["ACCEPTANCE_TEST"] = "ACCEPTANCE_TEST";
    TpsDocumentType["QUALIFICATION_CERTIFICATION_TEST"] = "QUALIFICATION_CERTIFICATION_TEST";
    TpsDocumentType["TEST_CALIBRATION"] = "TEST_CALIBRATION";
    TpsDocumentType["ENGINEERING_EVALUATION"] = "ENGINEERING_EVALUATION";
    TpsDocumentType["TRANSFER_ROUND_ROBIN"] = "TRANSFER_ROUND_ROBIN";
    TpsDocumentType["OTHER_NON_CONFIGURATION_CHANGE"] = "OTHER_NON_CONFIGURATION_CHANGE";
    TpsDocumentType["POST_FLIGHT_ACTIVITY"] = "POST_FLIGHT_ACTIVITY";
    TpsDocumentType["FABRICATION_I_E"] = "FABRICATION_I_E";
    TpsDocumentType["I_E_PROJECT_WORK"] = "I_E_PROJECT_WORK";
})(TpsDocumentType || (TpsDocumentType = {}));
export var TransactionType;
(function (TransactionType) {
    TransactionType["INSTALL"] = "INSTALL";
    TransactionType["INVENTORY_AUDIT_VERIFICATION"] = "INVENTORY_AUDIT_VERIFICATION";
    TransactionType["INVENTORY_CHECK"] = "INVENTORY_CHECK";
    TransactionType["REMOVE"] = "REMOVE";
    TransactionType["SERIALIZE_FROM_ISSUED"] = "SERIALIZE_FROM_ISSUED";
    TransactionType["SERIALIZE_ISSUED"] = "SERIALIZE_ISSUED";
    TransactionType["TRANSFER_ISSUED"] = "TRANSFER_ISSUED";
    TransactionType["TRANSFER_ISSUED_ASSEMBLED"] = "TRANSFER_ISSUED_ASSEMBLED";
    TransactionType["COSMIC_SYSTEM_CORRECTION"] = "COSMIC_SYSTEM_CORRECTION";
    TransactionType["COST_CORRECTION"] = "COST_CORRECTION";
    TransactionType["INVENTORY_AUDIT_CORRECTION"] = "INVENTORY_AUDIT_CORRECTION";
    TransactionType["ISSUE"] = "ISSUE";
    TransactionType["ISSUE_ASSEMBLED"] = "ISSUE_ASSEMBLED";
    TransactionType["MERGE_CORRECTION"] = "MERGE_CORRECTION";
    TransactionType["RECEIPT"] = "RECEIPT";
    TransactionType["RECEIPT_ASSEMBLED"] = "RECEIPT_ASSEMBLED";
    TransactionType["SERIALIZE"] = "SERIALIZE";
    TransactionType["SERIALIZE_FROM"] = "SERIALIZE_FROM";
    TransactionType["TRANSFER_ASSEMBLED_FROM"] = "TRANSFER_ASSEMBLED_FROM";
    TransactionType["TRANSFER_ASSEMBLED_TO"] = "TRANSFER_ASSEMBLED_TO";
    TransactionType["TRANSFER_FROM"] = "TRANSFER_FROM";
    TransactionType["TRANSFER_TO"] = "TRANSFER_TO";
})(TransactionType || (TransactionType = {}));
export var UsState;
(function (UsState) {
    UsState["AL"] = "AL";
    UsState["AK"] = "AK";
    UsState["AZ"] = "AZ";
    UsState["AR"] = "AR";
    UsState["CA"] = "CA";
    UsState["CO"] = "CO";
    UsState["CT"] = "CT";
    UsState["DE"] = "DE";
    UsState["DC"] = "DC";
    UsState["FL"] = "FL";
    UsState["GA"] = "GA";
    UsState["GU"] = "GU";
    UsState["HI"] = "HI";
    UsState["ID"] = "ID";
    UsState["IL"] = "IL";
    UsState["IN"] = "IN";
    UsState["IA"] = "IA";
    UsState["KS"] = "KS";
    UsState["KY"] = "KY";
    UsState["LA"] = "LA";
    UsState["ME"] = "ME";
    UsState["MD"] = "MD";
    UsState["MA"] = "MA";
    UsState["MI"] = "MI";
    UsState["MN"] = "MN";
    UsState["MS"] = "MS";
    UsState["MO"] = "MO";
    UsState["MT"] = "MT";
    UsState["NE"] = "NE";
    UsState["NV"] = "NV";
    UsState["NH"] = "NH";
    UsState["NJ"] = "NJ";
    UsState["NM"] = "NM";
    UsState["NY"] = "NY";
    UsState["NC"] = "NC";
    UsState["ND"] = "ND";
    UsState["OH"] = "OH";
    UsState["OK"] = "OK";
    UsState["OR"] = "OR";
    UsState["PW"] = "PW";
    UsState["PA"] = "PA";
    UsState["PR"] = "PR";
    UsState["RI"] = "RI";
    UsState["SC"] = "SC";
    UsState["SD"] = "SD";
    UsState["TN"] = "TN";
    UsState["TX"] = "TX";
    UsState["UT"] = "UT";
    UsState["VT"] = "VT";
    UsState["VI"] = "VI";
    UsState["VA"] = "VA";
    UsState["WA"] = "WA";
    UsState["WV"] = "WV";
    UsState["WI"] = "WI";
    UsState["WY"] = "WY";
})(UsState || (UsState = {}));
export var UserGroupType;
(function (UserGroupType) {
    UserGroupType["SYSTEM"] = "SYSTEM";
    UserGroupType["USER_MANAGED"] = "USER_MANAGED";
    UserGroupType["USER_DELEGATE"] = "USER_DELEGATE";
})(UserGroupType || (UserGroupType = {}));
export var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "ADMIN";
    UserRole["DISABLED"] = "DISABLED";
    UserRole["SYSTEM"] = "SYSTEM";
    UserRole["USER"] = "USER";
})(UserRole || (UserRole = {}));
export var Weekday;
(function (Weekday) {
    Weekday["SUNDAY"] = "SUNDAY";
    Weekday["MONDAY"] = "MONDAY";
    Weekday["TUESDAY"] = "TUESDAY";
    Weekday["WEDNESDAY"] = "WEDNESDAY";
    Weekday["THURSDAY"] = "THURSDAY";
    Weekday["FRIDAY"] = "FRIDAY";
    Weekday["SATURDAY"] = "SATURDAY";
})(Weekday || (Weekday = {}));
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
export class HttpClient {
    baseUrl = '';
    securityData = null;
    securityWorker;
    abortControllers = new Map();
    customFetch = (...fetchParams) => fetch(...fetchParams);
    baseApiParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };
    constructor(apiConfig = {}) {
        Object.assign(this, apiConfig);
    }
    setSecurityData = (data) => {
        this.securityData = data;
    };
    encodeQueryParam(key, value) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }
    addQueryParam(query, key) {
        return this.encodeQueryParam(key, query[key]);
    }
    addArrayQueryParam(query, key) {
        const value = query[key];
        return value.map((v) => this.encodeQueryParam(key, v)).join('&');
    }
    toQueryString(rawQuery) {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
        return keys
            .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
            .join('&');
    }
    addQueryParams(rawQuery) {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }
    contentFormatters = {
        [ContentType.Json]: (input) => input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.Text]: (input) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
        [ContentType.FormData]: (input) => Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            formData.append(key, property instanceof Blob
                ? property
                : typeof property === 'object' && property !== null
                    ? JSON.stringify(property)
                    : `${property}`);
            return formData;
        }, new FormData()),
        [ContentType.UrlEncoded]: (input) => this.toQueryString(input)
    };
    mergeRequestParams(params1, params2) {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }
    createAbortSignal = (cancelToken) => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }
        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };
    abortRequest = (cancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);
        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };
    request = async ({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }) => {
        const secureParams = ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
            this.securityWorker &&
            (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;
        return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
            },
            signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
        }).then(async (response) => {
            const r = response;
            r.data = null;
            r.error = null;
            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                    .then((data) => {
                    if (r.ok) {
                        r.data = data;
                    }
                    else {
                        r.error = data;
                    }
                    return r;
                })
                    .catch((e) => {
                    r.error = e;
                    return r;
                });
            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }
            if (!response.ok)
                throw data;
            return data;
        });
    };
}
/**
 * @title COSMIC API
 * @version v2
 */
export class Api extends HttpClient {
    accessRequests = {
        /**
         * No description
         *
         * @tags AccessRequest
         * @name AccessRequestsList
         * @summary Fetch access request change sets for the current user across all contexts.
         * @request GET:/access-requests
         */
        accessRequestsList: (query, params = {}) => this.request({
            path: `/access-requests`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description The x-organization header will be ignored. Pass the support context that the user is requesting access to as the `SupportContextOrganizationCode` parameter. One change set per group code will be created in the target context. Omit the group codes to request general context access. If `Comment` is provided then a new Genral comment will be attached to each change set. The context being requested must have change set templates created for the AccessRequest change set type or else this call will fail (access requests can never be auto-approved).
         *
         * @tags AccessRequest
         * @name AccessRequestsCreate
         * @summary Create an access request change set.
         * @request POST:/access-requests
         */
        accessRequestsCreate: (data, params = {}) => this.request({
            path: `/access-requests`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags AccessRequest
         * @name AccessRequestsDetail
         * @summary Get a single access request change set by id.
         * @request GET:/access-requests/{id}
         */
        accessRequestsDetail: (id, params = {}) => this.request({
            path: `/access-requests/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description This will fetch user groups (without users) from another context for which access requests can be made. Can be called by a user who does not already belong to the specified (or any) context.
         *
         * @tags AccessRequest
         * @name GroupsList
         * @summary Get user groups for a specific context.
         * @request GET:/access-requests/groups
         */
        groupsList: (query, params = {}) => this.request({
            path: `/access-requests/groups`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        })
    };
    activities = {
        /**
         * No description
         *
         * @tags Activity
         * @name ActivitiesList
         * @request GET:/activities
         */
        activitiesList: (query, params = {}) => this.request({
            path: `/activities`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Activity
         * @name ActivitiesCreate
         * @summary Create a new activity.
         * @request POST:/activities
         */
        activitiesCreate: (data, params = {}) => this.request({
            path: `/activities`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Activity
         * @name ActivitiesDetail
         * @summary Get an activity by id.
         * @request GET:/activities/{id}
         */
        activitiesDetail: (id, params = {}) => this.request({
            path: `/activities/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Activity
         * @name ActivitiesDelete
         * @summary Delete an activity.
         * @request DELETE:/activities/{id}
         */
        activitiesDelete: (id, params = {}) => this.request({
            path: `/activities/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags Activity
         * @name ActivitiesUpdate
         * @summary Update activity details.
         * @request PUT:/activities/{id}
         */
        activitiesUpdate: (id, data, params = {}) => this.request({
            path: `/activities/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    admin = {
        /**
         * No description
         *
         * @tags Admin
         * @name AdminList
         * @request GET:/admin
         * @secure
         */
        adminList: (params = {}) => this.request({
            path: `/admin`,
            method: 'GET',
            secure: true,
            ...params
        }),
        /**
         * No description
         *
         * @tags Admin
         * @name ExecCreate
         * @request POST:/admin/exec
         * @secure
         */
        execCreate: (data, params = {}) => this.request({
            path: `/admin/exec`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    announcements = {
        /**
         * No description
         *
         * @tags Announcement
         * @name AnnouncementsCreate
         * @summary Create an announcement.
         * @request POST:/announcements
         */
        announcementsCreate: (data, params = {}) => this.request({
            path: `/announcements`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Announcement
         * @name AnnouncementsList
         * @summary Search announcements.
         * @request GET:/announcements
         */
        announcementsList: (query, params = {}) => this.request({
            path: `/announcements`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Announcement
         * @name AnnouncementsUpdate
         * @summary Update an announcement.
         * @request PUT:/announcements/{id}
         */
        announcementsUpdate: (id, data, params = {}) => this.request({
            path: `/announcements/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Announcement
         * @name AnnouncementsDetail
         * @summary Get an announcement by id.
         * @request GET:/announcements/{id}
         */
        announcementsDetail: (id, params = {}) => this.request({
            path: `/announcements/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Announcement
         * @name AnnouncementsDelete
         * @summary Delete an announcement.
         * @request DELETE:/announcements/{id}
         */
        announcementsDelete: (id, params = {}) => this.request({
            path: `/announcements/${id}`,
            method: 'DELETE',
            ...params
        })
    };
    changeSets = {
        /**
         * No description
         *
         * @tags ApprovalRoutingTemplate
         * @name ApprovalTemplatesList
         * @summary Retrieves Approval Routing Templates
         * @request GET:/change-sets/approval-templates
         */
        approvalTemplatesList: (query, params = {}) => this.request({
            path: `/change-sets/approval-templates`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description May specify 0, 1 or many Approval Routing Group objects in request.
         *
         * @tags ApprovalRoutingTemplate
         * @name ApprovalTemplatesCreate
         * @summary Create an Approval Routing Template.
         * @request POST:/change-sets/approval-templates
         */
        approvalTemplatesCreate: (data, params = {}) => this.request({
            path: `/change-sets/approval-templates`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ApprovalRoutingTemplate
         * @name ApprovalTemplatesDetail
         * @summary Get an Approval Routing Template plus child Approval Routing Groups.
         * @request GET:/change-sets/approval-templates/{approvalRoutingTemplateId}
         */
        approvalTemplatesDetail: (approvalRoutingTemplateId, params = {}) => this.request({
            path: `/change-sets/approval-templates/${approvalRoutingTemplateId}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description Removes an Approval Routing Template along with any associated Approval Routing Groups.
         *
         * @tags ApprovalRoutingTemplate
         * @name ApprovalTemplatesDelete
         * @summary Delete an approval route template plus all of its children approval routing groups
         * @request DELETE:/change-sets/approval-templates/{approvalRoutingTemplateId}
         */
        approvalTemplatesDelete: (approvalRoutingTemplateId, params = {}) => this.request({
            path: `/change-sets/approval-templates/${approvalRoutingTemplateId}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags ApprovalRoutingTemplate
         * @name ApprovalTemplatesUpdate
         * @summary Updates an Approval Routing Template
         * @request PUT:/change-sets/approval-templates/{approvalRoutingTemplateId}
         */
        approvalTemplatesUpdate: (approvalRoutingTemplateId, data, params = {}) => this.request({
            path: `/change-sets/approval-templates/${approvalRoutingTemplateId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ChangeSet
         * @name ChangeSetsList
         * @summary Search change sets.
         * @request GET:/change-sets
         */
        changeSetsList: (query, params = {}) => this.request({
            path: `/change-sets`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags ChangeSet
     * @name UpcomingList
     * @summary Get change sets that the currently logged in user can either approve right now
    or may be able to approve soon, based on their group memberships.
     * @request GET:/change-sets/upcoming
     */
        upcomingList: (query, params = {}) => this.request({
            path: `/change-sets/upcoming`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags ChangeSet
         * @name ChangeSetsDetail
         * @summary Get a single change set by id.
         * @request GET:/change-sets/{id}
         */
        changeSetsDetail: (id, params = {}) => this.request({
            path: `/change-sets/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description This will fail if the change set is not Open, the authority is not Open, this is not the next authority waiting for approval on the change set, it is a document managed change set and the document's initialization status is not Approved, the user making the request is not a member of the appropriate user group, the authority requires a quality control stamp and one was not provided, or the authority requires a comment and one was not provided. If it's the final authority on the change set and it is approved then the change set will be automatically applied, unless its state became invalid since it was created, in which case the authority will not be approved and an error will be returned.
         *
         * @tags ChangeSet
         * @name AuthoritiesUpdate
         * @summary Approve or reject a change set authority.
         * @request PUT:/change-sets/authorities/{id}
         */
        authoritiesUpdate: (id, data, params = {}) => this.request({
            path: `/change-sets/authorities/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    assemblyTemplates = {
        /**
         * No description
         *
         * @tags AssemblyTemplate
         * @name AssemblyTemplatesDetail
         * @summary Get an assembly template plus all of its descendents.
         * @request GET:/assembly-templates/{id}
         */
        assemblyTemplatesDetail: (id, params = {}) => this.request({
            path: `/assembly-templates/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags AssemblyTemplate
         * @name AssemblyTemplatesUpdate
         * @summary Update an assembly template.
         * @request PUT:/assembly-templates/{id}
         */
        assemblyTemplatesUpdate: (id, data, params = {}) => this.request({
            path: `/assembly-templates/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description May fail with a 400/Bad Request if the assembly template is being referenced by something else (i.e., a working assembly).
         *
         * @tags AssemblyTemplate
         * @name AssemblyTemplatesDelete
         * @summary Delete an assembly template plus all of its children.
         * @request DELETE:/assembly-templates/{id}
         */
        assemblyTemplatesDelete: (id, params = {}) => this.request({
            path: `/assembly-templates/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags AssemblyTemplate
         * @name AssemblyTemplatesList
         * @summary Search assembly templates.
         * @request GET:/assembly-templates
         */
        assemblyTemplatesList: (query, params = {}) => this.request({
            path: `/assembly-templates`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description If providing a parent, the new template must have a unique sequence among the other chidren of that parent.
         *
         * @tags AssemblyTemplate
         * @name AssemblyTemplatesCreate
         * @summary Create an assembly template.
         * @request POST:/assembly-templates
         */
        assemblyTemplatesCreate: (data, params = {}) => this.request({
            path: `/assembly-templates`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description NeedDate is not cloned.
         *
         * @tags AssemblyTemplate
         * @name CloneCreate
         * @summary Clone an assembly template, all of its children, and all parts.
         * @request POST:/assembly-templates/{id}/clone
         */
        cloneCreate: (id, data, params = {}) => this.request({
            path: `/assembly-templates/${id}/clone`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * @description This will fail if the specified assembly template has any children with sequences that conflict with the sequences that would be created from the working assembly.
     *
     * @tags AssemblyTemplate
     * @name FromWorkingAssemblyCreate
     * @summary Updates an assembly template and creates new children using the structure and item
    instances frome the specified working assembly.
     * @request POST:/assembly-templates/{id}/from-working-assembly/{workingAssemblyId}
     */
        fromWorkingAssemblyCreate: (id, workingAssemblyId, data, params = {}) => this.request({
            path: `/assembly-templates/${id}/from-working-assembly/${workingAssemblyId}`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Events can only be added to a root assembly template node. Attempting to add events to a template that has a parent will fail.
         *
         * @tags AssemblyTemplate
         * @name EventsUpdate
         * @summary Set which events apply to the given assembly template.
         * @request PUT:/assembly-templates/{id}/events
         */
        eventsUpdate: (id, data, params = {}) => this.request({
            path: `/assembly-templates/${id}/events`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    attachments = {
        /**
         * No description
         *
         * @tags Attachment
         * @name AttachmentsDetail
         * @summary Get a single attachment by id.
         * @request GET:/attachments/{id}
         */
        attachmentsDetail: (id, params = {}) => this.request({
            path: `/attachments/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Attachment
         * @name AttachmentsUpdate
         * @summary Update an attachment's details.
         * @request PUT:/attachments/{id}
         */
        attachmentsUpdate: (id, data, params = {}) => this.request({
            path: `/attachments/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This does not currently delete the actual file in AS2. To actually delete the file you should do that via AS2 using the attachment's `key`.
         *
         * @tags Attachment
         * @name AttachmentsDelete
         * @summary Delete an attachment.
         * @request DELETE:/attachments/{id}
         */
        attachmentsDelete: (id, params = {}) => this.request({
            path: `/attachments/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags Attachment
         * @name AttachmentsCreate
         * @summary Upload a new attachment.
         * @request POST:/attachments
         */
        attachmentsCreate: (data, params = {}) => this.request({
            path: `/attachments`,
            method: 'POST',
            body: data,
            type: ContentType.FormData,
            format: 'json',
            ...params
        }),
        /**
         * @description Returns the decrypted file contents. A Content-Disposition: attachment header with the file's name will be included in the response, so you can point the user's browser directly to this endpoint when they want to download the file. Swagger says the fileName parameter is required but you can leave it off to use the file's original name in the Content-Disposition header.
         *
         * @tags Attachment
         * @name DownloadDetail
         * @summary Download an attachment.
         * @request GET:/attachments/download/{id}/{fileName}
         */
        downloadDetail: (id, fileName, params = {}) => this.request({
            path: `/attachments/download/${id}/${fileName}`,
            method: 'GET',
            ...params
        })
    };
    autoHardwareLists = {
        /**
         * No description
         *
         * @tags AutoHardwareList
         * @name AutoHardwareListsList
         * @summary Get AutoHardwareLists.
         * @request GET:/auto-hardware-lists
         */
        autoHardwareListsList: (query, params = {}) => this.request({
            path: `/auto-hardware-lists`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags AutoHardwareList
         * @name AutoHardwareListsCreate
         * @summary Create an AutoHardwareList.
         * @request POST:/auto-hardware-lists
         */
        autoHardwareListsCreate: (data, params = {}) => this.request({
            path: `/auto-hardware-lists`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags AutoHardwareList
         * @name ByDocumentDetail
         * @summary Get AutoHardwareList details for all line items on a given document.
         * @request GET:/auto-hardware-lists/by-document/{documentId}
         */
        byDocumentDetail: ({ documentId, ...query }, params = {}) => this.request({
            path: `/auto-hardware-lists/by-document/${documentId}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags AutoHardwareList
         * @name AutoHardwareListsDetail
         * @summary Get an AutoHardwareList by ID.
         * @request GET:/auto-hardware-lists/{id}
         */
        autoHardwareListsDetail: (id, params = {}) => this.request({
            path: `/auto-hardware-lists/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags AutoHardwareList
         * @name AutoHardwareListsUpdate
         * @summary Update an AutoHardwareList.
         * @request PUT:/auto-hardware-lists/{id}
         */
        autoHardwareListsUpdate: (id, data, params = {}) => this.request({
            path: `/auto-hardware-lists/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags AutoHardwareList
         * @name AutoHardwareListsDelete
         * @summary Delete an AutoHardwareList.
         * @request DELETE:/auto-hardware-lists/{id}
         */
        autoHardwareListsDelete: (id, params = {}) => this.request({
            path: `/auto-hardware-lists/${id}`,
            method: 'DELETE',
            ...params
        })
    };
    changeLogs = {
        /**
         * No description
         *
         * @tags ChangeLog
         * @name ChangeLogsList
         * @summary Get change logs.
         * @request GET:/change-logs
         */
        changeLogsList: (query, params = {}) => this.request({
            path: `/change-logs`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        })
    };
    comments = {
        /**
         * No description
         *
         * @tags Comment
         * @name CommentsCreate
         * @summary Create a comment.
         * @request POST:/comments
         */
        commentsCreate: (query, data, params = {}) => this.request({
            path: `/comments`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Comment
         * @name CommentsList
         * @summary Get comments.
         * @request GET:/comments
         */
        commentsList: (query, params = {}) => this.request({
            path: `/comments`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Comment
         * @name CommentsUpdate
         * @summary Update an existing comment.
         * @request PUT:/comments/{id}
         */
        commentsUpdate: (id, data, params = {}) => this.request({
            path: `/comments/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Comment
         * @name CommentsDelete
         * @summary Delete a comment.
         * @request DELETE:/comments/{id}
         */
        commentsDelete: (id, params = {}) => this.request({
            path: `/comments/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags Comment
         * @name CommentsDetail
         * @summary Get a single comment by ID.
         * @request GET:/comments/{id}
         */
        commentsDetail: (id, params = {}) => this.request({
            path: `/comments/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags Comment
     * @name ItemInstancesDetail
     * @summary Gets all of the comments associated with this itemInstance plus the comments
    associated with its itemMaster plus the comments associated with its itemDrawing.
     * @request GET:/comments/item-instances/{id}
     */
        itemInstancesDetail: ({ id, ...query }, params = {}) => this.request({
            path: `/comments/item-instances/${id}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags Comment
     * @name ItemMastersDetail
     * @summary Gets all of the comments associated with this itemMaster plus the comments
    associated with its itemDrawing.
     * @request GET:/comments/item-masters/{drawingNumber}/{asBuiltNumber}/{side}
     */
        itemMastersDetail: ({ drawingNumber, asBuiltNumber, side, ...query }, params = {}) => this.request({
            path: `/comments/item-masters/${drawingNumber}/${asBuiltNumber}/${side}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Comment
         * @name AssemblyTemplatesDetail
         * @summary Get all comments on an assembly template and its children.
         * @request GET:/comments/assembly-templates/{rootAssemblyTemplateId}
         */
        assemblyTemplatesDetail: ({ rootAssemblyTemplateId, ...query }, params = {}) => this.request({
            path: `/comments/assembly-templates/${rootAssemblyTemplateId}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        })
    };
    contacts = {
        /**
         * No description
         *
         * @tags Contact
         * @name ContactsList
         * @summary Search contacts and/or users.
         * @request GET:/contacts
         */
        contactsList: (query, params = {}) => this.request({
            path: `/contacts`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Contact
         * @name ContactsCreate
         * @summary Create a new Contact.
         * @request POST:/contacts
         */
        contactsCreate: (data, params = {}) => this.request({
            path: `/contacts`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Contact
         * @name ContactsDetail
         * @summary Get a single Contact by id.
         * @request GET:/contacts/{id}
         */
        contactsDetail: (id, params = {}) => this.request({
            path: `/contacts/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Contact
         * @name ContactsUpdate
         * @summary Update Contact details.
         * @request PUT:/contacts/{id}
         */
        contactsUpdate: (id, data, params = {}) => this.request({
            path: `/contacts/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Contact
         * @name ContactsDelete
         * @summary Delete a Contact.
         * @request DELETE:/contacts/{id}
         */
        contactsDelete: (id, params = {}) => this.request({
            path: `/contacts/${id}`,
            method: 'DELETE',
            ...params
        })
    };
    contracts = {
        /**
         * No description
         *
         * @tags Contract
         * @name ContractsCreate
         * @summary Create a new cross-context contract.
         * @request POST:/contracts
         */
        contractsCreate: (data, params = {}) => this.request({
            path: `/contracts`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Contract
         * @name ContractsList
         * @summary Search contracts.
         * @request GET:/contracts
         */
        contractsList: (query, params = {}) => this.request({
            path: `/contracts`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Contract
         * @name ContractsUpdate
         * @summary Update a cross-context contract.
         * @request PUT:/contracts/{number}
         */
        contractsUpdate: (number, data, params = {}) => this.request({
            path: `/contracts/${number}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description If the contract is in use and cannot be deleted, this will fail with a 400/Bad Request.
         *
         * @tags Contract
         * @name ContractsDelete
         * @summary Delete a cross-context contract.
         * @request DELETE:/contracts/{number}
         */
        contractsDelete: (number, params = {}) => this.request({
            path: `/contracts/${number}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags Contract
         * @name ContractsDetail
         * @summary Get a contract by number.
         * @request GET:/contracts/{number}
         */
        contractsDetail: (number, params = {}) => this.request({
            path: `/contracts/${number}`,
            method: 'GET',
            format: 'json',
            ...params
        })
    };
    faviconIco = {
        /**
         * No description
         *
         * @tags cosmic-api
         * @name FaviconIcoList
         * @request GET:/favicon.ico
         */
        faviconIcoList: (params = {}) => this.request({
            path: `/favicon.ico`,
            method: 'GET',
            ...params
        })
    };
    actions = {
        /**
         * No description
         *
         * @tags CosmicAction
         * @name ActionsCreate
         * @summary Create a new Cosmic Action.
         * @request POST:/actions
         */
        actionsCreate: (data, params = {}) => this.request({
            path: `/actions`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags CosmicAction
         * @name ActionsList
         * @summary Search cosmic actions.
         * @request GET:/actions
         */
        actionsList: (query, params = {}) => this.request({
            path: `/actions`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags CosmicAction
         * @name ActionsUpdate
         * @summary Update a Cosmic Action.
         * @request PUT:/actions/{id}
         */
        actionsUpdate: (id, data, params = {}) => this.request({
            path: `/actions/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags CosmicAction
         * @name ActionsDetail
         * @summary Get a single CosmicAction by ID.
         * @request GET:/actions/{id}
         */
        actionsDetail: (id, params = {}) => this.request({
            path: `/actions/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags CosmicAction
     * @name UpcomingList
     * @summary Get cosmic actions that the currently logged in user can either approve right now
    or may be able to approve soon, based on their group memberships.
     * @request GET:/actions/upcoming
     */
        upcomingList: (query, params = {}) => this.request({
            path: `/actions/upcoming`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags CosmicAction
         * @name ReopenUpdate
         * @summary Re-open a cosmic action.
         * @request PUT:/actions/{id}/reopen
         */
        reopenUpdate: (id, data, params = {}) => this.request({
            path: `/actions/${id}/reopen`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    cosmicEvents = {
        /**
         * No description
         *
         * @tags CosmicEvent
         * @name CosmicEventsList
         * @summary Search CosmicEvents
         * @request GET:/cosmic-events
         */
        cosmicEventsList: (query, params = {}) => this.request({
            path: `/cosmic-events`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags CosmicEvent
         * @name CosmicEventsCreate
         * @summary Create a new CosmicEvent.
         * @request POST:/cosmic-events
         */
        cosmicEventsCreate: (data, params = {}) => this.request({
            path: `/cosmic-events`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags CosmicEvent
         * @name CosmicEventsDetail
         * @summary Get a single CosmicEvent by id.
         * @request GET:/cosmic-events/{id}
         */
        cosmicEventsDetail: (id, params = {}) => this.request({
            path: `/cosmic-events/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags CosmicEvent
         * @name CosmicEventsUpdate
         * @summary Update CosmicEvent details.
         * @request PUT:/cosmic-events/{id}
         */
        cosmicEventsUpdate: (id, data, params = {}) => this.request({
            path: `/cosmic-events/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags CosmicEvent
         * @name CosmicEventsDelete
         * @summary Delete a CosmicEvent.
         * @request DELETE:/cosmic-events/{id}
         */
        cosmicEventsDelete: (id, params = {}) => this.request({
            path: `/cosmic-events/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags CosmicEvent
         * @name ExistsCreate
         * @summary Checks if a cosmic event exists
         * @request POST:/cosmic-events/exists
         */
        existsCreate: (data, params = {}) => this.request({
            path: `/cosmic-events/exists`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    export = {
        /**
         * No description
         *
         * @tags DataExport
         * @name DataImportDetail
         * @summary Export data that was previously imported together.
         * @request GET:/export/data-import/{id}
         */
        dataImportDetail: (id, params = {}) => this.request({
            path: `/export/data-import/${id}`,
            method: 'GET',
            ...params
        }),
        /**
         * No description
         *
         * @tags DataExport
         * @name BuildingInventoryClassReportList
         * @summary Export results of the ObjectClassTransactionReport to excel template.
         * @request GET:/export/building-inventory-class-report
         */
        buildingInventoryClassReportList: (query, params = {}) => this.request({
            path: `/export/building-inventory-class-report`,
            method: 'GET',
            query: query,
            ...params
        }),
        /**
         * No description
         *
         * @tags DataExport
         * @name BuildingItemInstancesReportList
         * @summary Export results of the ItemInstanceTransactionsReport to excel template.
         * @request GET:/export/building-item-instances-report
         */
        buildingItemInstancesReportList: (query, params = {}) => this.request({
            path: `/export/building-item-instances-report`,
            method: 'GET',
            query: query,
            ...params
        })
    };
    import = {
        /**
         * No description
         *
         * @tags DataImport
         * @name ReviewItemsCreate
         * @summary Import digital library review items.
         * @request POST:/import/review-items
         * @secure
         */
        reviewItemsCreate: (data, params = {}) => this.request({
            path: `/import/review-items`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DataImport
         * @name PartReferencesCreate
         * @request POST:/import/part-references
         */
        partReferencesCreate: (data, params = {}) => this.request({
            path: `/import/part-references`,
            method: 'POST',
            body: data,
            type: ContentType.FormData,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DataImport
         * @name ImportDetail
         * @summary Get a single data import by id.
         * @request GET:/import/{id}
         */
        importDetail: (id, params = {}) => this.request({
            path: `/import/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description This will attempt to delete the data import record plus all data that was imported with it. This includes any additional data that was entered into the system related to that imported data such as updates, comments, links, attachments, and so on. It may fail in some cases when data that has been created since the initial import cannot be safely deleted or has a foreign key that doesn't cascade deletes.
         *
         * @tags DataImport
         * @name ImportDelete
         * @summary Delete a data import and all of its imported data.
         * @request DELETE:/import/{id}
         */
        importDelete: (id, params = {}) => this.request({
            path: `/import/${id}`,
            method: 'DELETE',
            ...params
        })
    };
    deliverables = {
        /**
         * No description
         *
         * @tags Deliverable
         * @name DeliverablesList
         * @summary Search Deliverables.
         * @request GET:/deliverables
         * @secure
         */
        deliverablesList: (query, params = {}) => this.request({
            path: `/deliverables`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Deliverable
         * @name DeliverablesCreate
         * @summary Create a new Deliverable.
         * @request POST:/deliverables
         * @secure
         */
        deliverablesCreate: (data, params = {}) => this.request({
            path: `/deliverables`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Deliverable
         * @name DeliverablesDetail
         * @summary Get a single Deliverable by id.
         * @request GET:/deliverables/{id}
         * @secure
         */
        deliverablesDetail: (id, params = {}) => this.request({
            path: `/deliverables/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Deliverable
         * @name DeliverablesUpdate
         * @summary Update a Deliverable.
         * @request PUT:/deliverables/{id}
         * @secure
         */
        deliverablesUpdate: (id, data, params = {}) => this.request({
            path: `/deliverables/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Also deletes all related data, including DigitalLibraryReviewItems and DeliverableRevisions associated with this Deliverable.
         *
         * @tags Deliverable
         * @name DeliverablesDelete
         * @summary Delete a Deliverable.
         * @request DELETE:/deliverables/{id}
         * @secure
         */
        deliverablesDelete: (id, params = {}) => this.request({
            path: `/deliverables/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        }),
        /**
         * @description New records will be added to the deliverable and existing records will have their metadata for the dileverable updated. No records will be removed from the deliverable (for that you should call the delete API).
         *
         * @tags Deliverable
         * @name RecordsCreate
         * @summary Add or update DigitalLibraryRecords on a Deliverable.
         * @request POST:/deliverables/{id}/records
         * @secure
         */
        recordsCreate: (id, data, params = {}) => this.request({
            path: `/deliverables/${id}/records`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Deliverable
         * @name RecordsUpdate
         * @summary Update the StoplightStatus of an existing deliverable record.
         * @request PUT:/deliverables/{id}/records/{recordId}
         * @secure
         */
        recordsUpdate: (id, recordId, data, params = {}) => this.request({
            path: `/deliverables/${id}/records/${recordId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This will also remove the DeliverableRevision (if there is one) and all DigitalLibraryReviewItems associated with this record.
         *
         * @tags Deliverable
         * @name RecordsDelete
         * @summary Remove a DigitalLibraryRecord from a Deliverable.
         * @request DELETE:/deliverables/{id}/records/{recordId}
         * @secure
         */
        recordsDelete: (id, recordId, params = {}) => this.request({
            path: `/deliverables/${id}/records/${recordId}`,
            method: 'DELETE',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Deliverable
         * @name RevisionsUpdate
         * @summary Add or update a revision to satisfy a Deliverable.
         * @request PUT:/deliverables/{id}/revisions
         * @secure
         */
        revisionsUpdate: (id, data, params = {}) => this.request({
            path: `/deliverables/${id}/revisions`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Deliverable
         * @name RevisionsDelete
         * @summary Remove a DeliverableRevision from a Deliverable.
         * @request DELETE:/deliverables/{id}/revisions/{revisionId}
         * @secure
         */
        revisionsDelete: (id, revisionId, params = {}) => this.request({
            path: `/deliverables/${id}/revisions/${revisionId}`,
            method: 'DELETE',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliverableEvent
         * @name EventsList
         * @summary Search DeliverableEvents.
         * @request GET:/deliverables/events
         * @secure
         */
        eventsList: (query, params = {}) => this.request({
            path: `/deliverables/events`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliverableEvent
         * @name EventsCreate
         * @summary Create a new DeliverableEvent.
         * @request POST:/deliverables/events
         * @secure
         */
        eventsCreate: (data, params = {}) => this.request({
            path: `/deliverables/events`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliverableEvent
         * @name EventsDetail
         * @summary Get a single DeliverableEvent by id.
         * @request GET:/deliverables/events/{id}
         * @secure
         */
        eventsDetail: (id, params = {}) => this.request({
            path: `/deliverables/events/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliverableEvent
         * @name EventsUpdate
         * @summary Update a DeliverableEvent.
         * @request PUT:/deliverables/events/{id}
         * @secure
         */
        eventsUpdate: (id, data, params = {}) => this.request({
            path: `/deliverables/events/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Also deletes all data associated with this DeliverableEvent, including Deliverables and DigitalLibraryReviewItems associated with Deliverables associated with this DeliverableEvent.
         *
         * @tags DeliverableEvent
         * @name EventsDelete
         * @summary Delete a DeliverableEvent.
         * @request DELETE:/deliverables/events/{id}
         * @secure
         */
        eventsDelete: (id, params = {}) => this.request({
            path: `/deliverables/events/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliverableEvent
         * @name EventsSummaryDetail
         * @summary Get a summary of Review Item counts for a DeliverableEvent.
         * @request GET:/deliverables/events/{id}/summary
         * @secure
         */
        eventsSummaryDetail: (id, params = {}) => this.request({
            path: `/deliverables/events/${id}/summary`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * @description For this to succeed there must be a DeliverableEvent, a Deliverable with a startDate and endDate enclosing the current date, and the DigitalLibraryRevision must have been added to the Deliverable as either Commentable or Riddable (depending on the review item type).
         *
         * @tags DigitalLibraryReviewItem
         * @name RevisionsReviewItemsCreate
         * @summary Create a review item for a revision on a Deliverable.
         * @request POST:/deliverables/{id}/revisions/{revisionId}/review-items
         * @secure
         */
        revisionsReviewItemsCreate: (id, revisionId, data, params = {}) => this.request({
            path: `/deliverables/${id}/revisions/${revisionId}/review-items`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    documents = {
        /**
         * No description
         *
         * @tags DeliveryOrder
         * @name DeliveryOrdersCreate
         * @summary Create a delivery order document.
         * @request POST:/documents/delivery-orders
         */
        deliveryOrdersCreate: (data, params = {}) => this.request({
            path: `/documents/delivery-orders`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliveryOrder
         * @name DeliveryOrdersList
         * @summary Search delivery order documents.
         * @request GET:/documents/delivery-orders
         */
        deliveryOrdersList: (query, params = {}) => this.request({
            path: `/documents/delivery-orders`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliveryOrder
         * @name DeliveryOrdersUpdate
         * @summary Update a delivery order document.
         * @request PUT:/documents/delivery-orders/{id}
         */
        deliveryOrdersUpdate: (id, data, params = {}) => this.request({
            path: `/documents/delivery-orders/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliveryOrder
         * @name DeliveryOrdersDetail
         * @summary Get a delivery order document by ID.
         * @request GET:/documents/delivery-orders/{id}
         */
        deliveryOrdersDetail: (id, params = {}) => this.request({
            path: `/documents/delivery-orders/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliveryOrder
         * @name DeliveryOrdersLineItemsCreate
         * @summary Add line items to an existing delivery order document.
         * @request POST:/documents/delivery-orders/{id}/line-items
         */
        deliveryOrdersLineItemsCreate: (id, data, params = {}) => this.request({
            path: `/documents/delivery-orders/${id}/line-items`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This will delete all matching line items regardless of status.
         *
         * @tags DeliveryOrder
         * @name DeliveryOrdersLineItemsDelete
         * @summary Delete delivery order line items.
         * @request DELETE:/documents/delivery-orders/{id}/line-items
         */
        deliveryOrdersLineItemsDelete: ({ id, ...query }, params = {}) => this.request({
            path: `/documents/delivery-orders/${id}/line-items`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliveryOrder
         * @name DeliveryOrdersLineItemsUpdate
         * @summary Update a delivery order line item.
         * @request PUT:/documents/delivery-orders/{documentId}/line-items/{changeSetId}
         */
        deliveryOrdersLineItemsUpdate: (documentId, changeSetId, data, params = {}) => this.request({
            path: `/documents/delivery-orders/${documentId}/line-items/${changeSetId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliveryOrder
         * @name DeliveryOrdersLineItemsCloseUpdate
         * @summary Close line items on a delivery order document.
         * @request PUT:/documents/delivery-orders/{id}/line-items/close
         */
        deliveryOrdersLineItemsCloseUpdate: (id, data, params = {}) => this.request({
            path: `/documents/delivery-orders/${id}/line-items/close`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliveryOrder
         * @name DeliveryOrdersLineItemsReopenUpdate
         * @summary Reopen line items on a delivery order.
         * @request PUT:/documents/delivery-orders/{id}/line-items/reopen
         */
        deliveryOrdersLineItemsReopenUpdate: (id, data, params = {}) => this.request({
            path: `/documents/delivery-orders/${id}/line-items/reopen`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DeliveryOrder
         * @name DeliveryOrdersLineItemsList
         * @summary Search delivery order line items.
         * @request GET:/documents/delivery-orders/line-items
         */
        deliveryOrdersLineItemsList: (query, params = {}) => this.request({
            path: `/documents/delivery-orders/line-items`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DiscrepancyReport
         * @name PostDocuments
         * @summary Create a new Cosmic Discrepancy Report (DR) document.
         * @request POST:/documents/dr
         */
        postDocuments: (data, params = {}) => this.request({
            path: `/documents/dr`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DiscrepancyReport
         * @name GetDocuments
         * @summary Search Discrepancy Report (DR) documents.
         * @request GET:/documents/dr
         */
        getDocuments: (query, params = {}) => this.request({
            path: `/documents/dr`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DiscrepancyReport
         * @name GetDocuments2
         * @summary Get a Cosmic Discrepancy Report (DR) document by ID.
         * @request GET:/documents/dr/{id}
         * @originalName getDocuments
         * @duplicate
         */
        getDocuments2: (id, params = {}) => this.request({
            path: `/documents/dr/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DiscrepancyReport
         * @name PutDocuments
         * @summary Update a Cosmic Discrepancy Report (DR) document.
         * @request PUT:/documents/dr/{id}
         */
        putDocuments: (id, data, params = {}) => this.request({
            path: `/documents/dr/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DiscrepancyReport
         * @name DeleteDocuments
         * @summary Delete a Cosmic Discrepancy Report (DR) document.
         * @request DELETE:/documents/dr/{id}
         */
        deleteDocuments: (id, params = {}) => this.request({
            path: `/documents/dr/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * @description This will return an error if a managed document subType or a document subType with a custom line item type is specified.
         *
         * @tags Document
         * @name DocumentsCreate
         * @summary Create a non-managed document.
         * @request POST:/documents
         */
        documentsCreate: (data, params = {}) => this.request({
            path: `/documents`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags Document
     * @name DocumentsList
     * @summary Search documents. This searches all document types--managed and unmanaged. When
    subclassed for a specific document type it will ignore the subType parameter and only
    return documents of the appropriate type.
     * @request GET:/documents
     */
        documentsList: (query, params = {}) => this.request({
            path: `/documents`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description This will return an error for managed document types or document types that have custom line item types.
         *
         * @tags Document
         * @name DocumentsUpdate
         * @summary Update a non-managed document.
         * @request PUT:/documents/{id}
         */
        documentsUpdate: (id, data, params = {}) => this.request({
            path: `/documents/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Document
         * @name DocumentsDelete
         * @summary Delete a document.
         * @request DELETE:/documents/{id}
         */
        documentsDelete: (id, params = {}) => this.request({
            path: `/documents/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * @description This will return an error for managed document types or document types that have custom line item types. Use the dedicated APIs for those subTypes.
         *
         * @tags Document
         * @name DocumentsDetail
         * @summary Get a single unmanaged document by id.
         * @request GET:/documents/{id}
         */
        documentsDetail: (id, params = {}) => this.request({
            path: `/documents/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Document
         * @name ExistsCreate
         * @summary Checks if a document exists
         * @request POST:/documents/exists
         */
        existsCreate: (data, params = {}) => this.request({
            path: `/documents/exists`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Specifying a managed document or document type that has a custom line item type will return an error. Use dedicated APIs for those instead.
         *
         * @tags Document
         * @name LineItemsCreate
         * @summary Add line items on an unmanaged document.
         * @request POST:/documents/{id}/line-items
         */
        lineItemsCreate: (id, data, params = {}) => this.request({
            path: `/documents/${id}/line-items`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This will delete all matching line items regardless of status.
         *
         * @tags Document
         * @name LineItemsDelete
         * @summary Delete document line items.
         * @request DELETE:/documents/{id}/line-items
         */
        lineItemsDelete: ({ id, ...query }, params = {}) => this.request({
            path: `/documents/${id}/line-items`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description Specifying a managed document or document type that has a custom line item type will return an error. Use dedicated APIs for those instead.
         *
         * @tags Document
         * @name LineItemsCloseUpdate
         * @summary Close line items on an unmanaged document.
         * @request PUT:/documents/{id}/line-items/close
         */
        lineItemsCloseUpdate: (id, data, params = {}) => this.request({
            path: `/documents/${id}/line-items/close`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Document
         * @name LineItemsReopenUpdate
         * @summary Reopen line items on a document.
         * @request PUT:/documents/{id}/line-items/reopen
         */
        lineItemsReopenUpdate: (id, data, params = {}) => this.request({
            path: `/documents/${id}/line-items/reopen`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Document
         * @name LineItemsList
         * @summary Search document line items.
         * @request GET:/documents/line-items
         */
        lineItemsList: (query, params = {}) => this.request({
            path: `/documents/line-items`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Document
         * @name InitializeCreate
         * @summary Creates a COSMIC_DOCUMENT_INITIALIZATION change set.
         * @request POST:/documents/{id}/initialize
         */
        initializeCreate: (id, data, params = {}) => this.request({
            path: `/documents/${id}/initialize`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Document
         * @name ReopenUpdate
         * @summary Reopens a managed document allowing it to be updated.
         * @request PUT:/documents/{id}/reopen
         */
        reopenUpdate: (id, data, params = {}) => this.request({
            path: `/documents/${id}/reopen`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DocumentDeviation
         * @name DeviationsDetail
         * @summary Get a list of document deviations by document id.
         * @request GET:/documents/{documentId}/deviations
         */
        deviationsDetail: (documentId, params = {}) => this.request({
            path: `/documents/${documentId}/deviations`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DocumentDeviation
         * @name DeviationsCreate
         * @summary Create a new document deviation.
         * @request POST:/documents/{documentId}/deviations
         */
        deviationsCreate: (documentId, data, params = {}) => this.request({
            path: `/documents/${documentId}/deviations`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DocumentDeviation
         * @name DeviationsDelete
         * @summary Delete a document deviation by deviation id.
         * @request DELETE:/documents/deviations/{deviationId}
         */
        deviationsDelete: (deviationId, params = {}) => this.request({
            path: `/documents/deviations/${deviationId}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags DocumentDeviation
         * @name DeviationsUpdate
         * @summary Update document deviation details by deviation id.
         * @request PUT:/documents/deviations/{deviationId}
         */
        deviationsUpdate: (deviationId, data, params = {}) => this.request({
            path: `/documents/deviations/${deviationId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags TaskPerformanceSheet
         * @name PostDocuments2
         * @summary Create a new Task Performance Sheet (TPS) document.
         * @request POST:/documents/tps
         * @originalName postDocuments
         * @duplicate
         */
        postDocuments2: (data, params = {}) => this.request({
            path: `/documents/tps`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags TaskPerformanceSheet
         * @name GetDocuments3
         * @summary Search TPS documents.
         * @request GET:/documents/tps
         * @originalName getDocuments
         * @duplicate
         */
        getDocuments3: (query, params = {}) => this.request({
            path: `/documents/tps`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags TaskPerformanceSheet
         * @name GetDocuments4
         * @summary Get a Task Performance Sheet (TPS) document by ID.
         * @request GET:/documents/tps/{id}
         * @originalName getDocuments
         * @duplicate
         */
        getDocuments4: (id, params = {}) => this.request({
            path: `/documents/tps/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags TaskPerformanceSheet
         * @name PutDocuments2
         * @summary Update a Task Performance Sheet (TPS) document.
         * @request PUT:/documents/tps/{id}
         * @originalName putDocuments
         * @duplicate
         */
        putDocuments2: (id, data, params = {}) => this.request({
            path: `/documents/tps/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags TaskPerformanceSheet
         * @name DeleteDocuments2
         * @summary Delete a Task Performance Sheet (TPS) document.
         * @request DELETE:/documents/tps/{id}
         * @originalName deleteDocuments
         * @duplicate
         */
        deleteDocuments2: (id, params = {}) => this.request({
            path: `/documents/tps/${id}`,
            method: 'DELETE',
            ...params
        })
    };
    library = {
        /**
         * No description
         *
         * @tags DigitalLibraryGeneralAccess
         * @name RecordsList
         * @summary Get all digital library records, sorted by created date.
         * @request GET:/library/records
         * @secure
         */
        recordsList: (query, params = {}) => this.request({
            path: `/library/records`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * @description Record number must be unique.
         *
         * @tags DigitalLibraryRecord
         * @name RecordsCreate
         * @summary Create a new digital library record.
         * @request POST:/library/records
         * @secure
         */
        recordsCreate: (data, params = {}) => this.request({
            path: `/library/records`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryRecord
         * @name RecordsDetail
         * @summary Get a single digital library record by id.
         * @request GET:/library/records/{id}
         * @secure
         */
        recordsDetail: (id, params = {}) => this.request({
            path: `/library/records/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryRecord
         * @name RecordsUpdate
         * @summary Update a digital library record by id.
         * @request PUT:/library/records/{id}
         * @secure
         */
        recordsUpdate: (id, data, params = {}) => this.request({
            path: `/library/records/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This will also delete associated revisions.
         *
         * @tags DigitalLibraryRecord
         * @name RecordsDelete
         * @summary Delete a single digital library record by id.
         * @request DELETE:/library/records/{id}
         * @secure
         */
        recordsDelete: (id, params = {}) => this.request({
            path: `/library/records/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryRecord
         * @name RecordsRelatedDetail
         * @summary Get related records for a single record by id.
         * @request GET:/library/records/{id}/related
         * @secure
         */
        recordsRelatedDetail: (id, params = {}) => this.request({
            path: `/library/records/${id}/related`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryRecord
         * @name RecordsIsProprietaryUpdate
         * @summary Change the isProprietary status of a digital library record.
         * @request PUT:/library/records/{id}/is-proprietary
         * @secure
         */
        recordsIsProprietaryUpdate: (id, data, params = {}) => this.request({
            path: `/library/records/${id}/is-proprietary`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This replaces the existing author list with the new list of authors.
         *
         * @tags DigitalLibraryRecord
         * @name RecordsAuthorsUpdate
         * @summary Set the authors for a DigitalLibraryRecord.
         * @request PUT:/library/records/{id}/authors
         * @secure
         */
        recordsAuthorsUpdate: (id, data, params = {}) => this.request({
            path: `/library/records/${id}/authors`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryRecord
         * @name RecordsRevisionsDraftCreate
         * @summary Create a draft digital library revision for a record.
         * @request POST:/library/records/{recordId}/revisions/draft
         * @secure
         */
        recordsRevisionsDraftCreate: (recordId, data, params = {}) => this.request({
            path: `/library/records/${recordId}/revisions/draft`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryRecord
         * @name RecordsRevisionsPublishedCreate
         * @summary Create a published digital library revision for a record.
         * @request POST:/library/records/{recordId}/revisions/published
         * @secure
         */
        recordsRevisionsPublishedCreate: (recordId, data, params = {}) => this.request({
            path: `/library/records/${recordId}/revisions/published`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryRecord
         * @name RevisionsDraftUpdate
         * @summary Update a single draft digital library revision by id.
         * @request PUT:/library/revisions/draft/{id}
         * @secure
         */
        revisionsDraftUpdate: (id, data, params = {}) => this.request({
            path: `/library/revisions/draft/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryRecord
         * @name RevisionsPublishedUpdate
         * @summary Update a single published digital library revision by id.
         * @request PUT:/library/revisions/published/{id}
         * @secure
         */
        revisionsPublishedUpdate: (id, data, params = {}) => this.request({
            path: `/library/revisions/published/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This will also delete the associated attachment.
         *
         * @tags DigitalLibraryRecord
         * @name RevisionsDelete
         * @summary Delete a single digital library revision by id. Also deletes its attachment.
         * @request DELETE:/library/revisions/{id}
         * @secure
         */
        revisionsDelete: (id, params = {}) => this.request({
            path: `/library/revisions/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        }),
        /**
         * @description You can't create an inverse relationship (*_FOR relation types). To create an inverse relation swap the recordId and relatedRecordId and specify the non-inverse relation type instead.
         *
         * @tags DigitalLibraryRecord
         * @name RecordsRelatedCreate
         * @summary Create a relationship between two digital library records.
         * @request POST:/library/records/{recordId}/related
         * @secure
         */
        recordsRelatedCreate: (recordId, data, params = {}) => this.request({
            path: `/library/records/${recordId}/related`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description You can't update existing computed inverse relationships (*_FOR relation types) and you can't update existing relationships to those types. Instead update the non-_FOR relation with the record IDs swapped.
         *
         * @tags DigitalLibraryRecord
         * @name RecordsRelatedUpdate
         * @summary Update a relationship between two digital library records.
         * @request PUT:/library/records/{recordId}/related/{relatedRecordId}
         * @secure
         */
        recordsRelatedUpdate: (recordId, relatedRecordId, data, params = {}) => this.request({
            path: `/library/records/${recordId}/related/${relatedRecordId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description You can't remove an inverse relationship (*For relation types). To remove an inverse relation swap the recordId and relatedRecordId instead.
         *
         * @tags DigitalLibraryRecord
         * @name RecordsRelatedDelete
         * @summary Remove a relationship between two digital library records.
         * @request DELETE:/library/records/{recordId}/related/{relatedRecordId}
         * @secure
         */
        recordsRelatedDelete: (recordId, relatedRecordId, params = {}) => this.request({
            path: `/library/records/${recordId}/related/${relatedRecordId}`,
            method: 'DELETE',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryReviewItem
         * @name ReviewItemsList
         * @summary Get all digital library review items, sorted by created date.
         * @request GET:/library/review-items
         * @secure
         */
        reviewItemsList: (query, params = {}) => this.request({
            path: `/library/review-items`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags DigitalLibraryReviewItem
     * @name RecordsSimilarReviewItemsDetail
     * @summary Find rids for a given DigitalLibraryRecord that are similar to the provided
    title, description, and changeFrom
     * @request GET:/library/records/{id}/similar-review-items
     * @secure
     */
        recordsSimilarReviewItemsDetail: ({ id, ...query }, params = {}) => this.request({
            path: `/library/records/${id}/similar-review-items`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryReviewItem
         * @name ReviewItemsDetail
         * @summary Get a single review item by id.
         * @request GET:/library/review-items/{id}
         * @secure
         */
        reviewItemsDetail: (id, params = {}) => this.request({
            path: `/library/review-items/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryReviewItem
         * @name ReviewItemsUpdate
         * @summary Update a review item.
         * @request PUT:/library/review-items/{id}
         * @secure
         */
        reviewItemsUpdate: (id, data, params = {}) => this.request({
            path: `/library/review-items/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags DigitalLibraryReviewItem
         * @name ReviewItemsDelete
         * @summary Delete a single digital library review item by id.
         * @request DELETE:/library/review-items/{id}
         * @secure
         */
        reviewItemsDelete: (id, params = {}) => this.request({
            path: `/library/review-items/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        }),
        /**
         * @description All existing user group access rules will be replaced with the ones specified. There must be at least one group with non-view-only access.
         *
         * @tags GroupAccessDigitalLibrary
         * @name RecordsGroupAccessUpdate
         * @summary Update which groups have access to a proprietary digital library record.
         * @request PUT:/library/records/{id}/group-access
         * @secure
         */
        recordsGroupAccessUpdate: (id, data, params = {}) => this.request({
            path: `/library/records/${id}/group-access`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    meetings = {
        /**
     * @description All existing user group access rules will be replaced with the ones specified. Setting IsRestricted to true means that members of that group will never have access even if they are members of additional groups that would otherwise grant them access.
     *
     * @tags GroupAccessHorizons
     * @name TemplatesGroupAccessUpdate
     * @summary Update which groups have access to a Meeting Template and all of its Meetings and
    Meeting Items.
     * @request PUT:/meetings/templates/{id}/group-access
     * @secure
     */
        templatesGroupAccessUpdate: (id, data, params = {}) => this.request({
            path: `/meetings/templates/${id}/group-access`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags GroupAccessHorizons
     * @name GroupAccessTemplatesList
     * @summary Get protected group access templates that will be automatically applied to new
    Meeting Templates when created, and cannot be modified by non-Horizons Admins.
     * @request GET:/meetings/group-access-templates
     * @secure
     */
        groupAccessTemplatesList: (query, params = {}) => this.request({
            path: `/meetings/group-access-templates`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags GroupAccessHorizons
         * @name GroupAccessTemplatesCreate
         * @summary Create a Horizons Group Access template.
         * @request POST:/meetings/group-access-templates
         * @secure
         */
        groupAccessTemplatesCreate: (data, params = {}) => this.request({
            path: `/meetings/group-access-templates`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags GroupAccessHorizons
         * @name GroupAccessTemplatesDelete
         * @summary Delete a Horizons Group Access template.
         * @request DELETE:/meetings/group-access-templates/{id}
         * @secure
         */
        groupAccessTemplatesDelete: (id, params = {}) => this.request({
            path: `/meetings/group-access-templates/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        }),
        /**
         * No description
         *
         * @tags Meeting
         * @name MeetingsDetail
         * @summary Get a single Meeting by ID.
         * @request GET:/meetings/{id}
         * @secure
         */
        meetingsDetail: (id, params = {}) => this.request({
            path: `/meetings/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Meeting
         * @name MeetingsUpdate
         * @summary Update a Meeting.
         * @request PUT:/meetings/{id}
         * @secure
         */
        meetingsUpdate: (id, data, params = {}) => this.request({
            path: `/meetings/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This will fail if the Meeting has any Meeting Items. Meeting Items must be manually deleted first. Deleting a Meeting will also delete all comments and links on the Meeting.
         *
         * @tags Meeting
         * @name MeetingsDelete
         * @summary Delete a Meeting.
         * @request DELETE:/meetings/{id}
         * @secure
         */
        meetingsDelete: (id, params = {}) => this.request({
            path: `/meetings/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        }),
        /**
         * No description
         *
         * @tags Meeting
         * @name SeriesDetail
         * @summary Get a single Meeting Series by ID.
         * @request GET:/meetings/series/{id}
         * @secure
         */
        seriesDetail: (id, params = {}) => this.request({
            path: `/meetings/series/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Meeting
         * @name SeriesUpdate
         * @summary Update a Meeting Series.
         * @request PUT:/meetings/series/{id}
         * @secure
         */
        seriesUpdate: (id, data, params = {}) => this.request({
            path: `/meetings/series/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This will fail if the Meeting Series has any Meetings. Meetings must be manually deleted first. Deleting a Meeting Series will also delete all comments and links on the Meeting Series.
         *
         * @tags Meeting
         * @name SeriesDelete
         * @summary Delete a Meeting Series.
         * @request DELETE:/meetings/series/{id}
         * @secure
         */
        seriesDelete: (id, params = {}) => this.request({
            path: `/meetings/series/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        }),
        /**
         * No description
         *
         * @tags Meeting
         * @name SeriesList
         * @summary Search Meeting Series.
         * @request GET:/meetings/series
         * @secure
         */
        seriesList: (query, params = {}) => this.request({
            path: `/meetings/series`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * @description The new series will automatically have the global Horizons group access rules for the specified MeetingSeriesType applied, in addition to any rules specified in the InitialGroupAccess if provided. Any rules that contradict the global rules will cause an error.
         *
         * @tags Meeting
         * @name SeriesCreate
         * @summary Create a new Meeting Series, with optional Meetings.
         * @request POST:/meetings/series
         * @secure
         */
        seriesCreate: (data, params = {}) => this.request({
            path: `/meetings/series`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Meeting
         * @name MeetingsList
         * @request GET:/meetings
         */
        meetingsList: (query, params = {}) => this.request({
            path: `/meetings`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Meeting
         * @name ItemsCreate
         * @summary Create a new Meeting Item for a Meeting.
         * @request POST:/meetings/{id}/items
         * @secure
         */
        itemsCreate: (id, data, params = {}) => this.request({
            path: `/meetings/${id}/items`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Meeting
         * @name SeriesMeetingsCreate
         * @summary Create a new Meeting for a Meeting Series, with optional MeetingItems.
         * @request POST:/meetings/series/{id}/meetings
         * @secure
         */
        seriesMeetingsCreate: (id, data, params = {}) => this.request({
            path: `/meetings/series/${id}/meetings`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Meeting
         * @name ItemsUpdate
         * @summary Update a Meeting Item.
         * @request PUT:/meetings/items/{id}
         * @secure
         */
        itemsUpdate: (id, data, params = {}) => this.request({
            path: `/meetings/items/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Also deletes all comments and links on the Meeting Item.
         *
         * @tags Meeting
         * @name ItemsDelete
         * @summary Delete a Meeting Item.
         * @request DELETE:/meetings/items/{id}
         * @secure
         */
        itemsDelete: (id, params = {}) => this.request({
            path: `/meetings/items/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
    };
    hardware = {
        /**
         * No description
         *
         * @tags Hardware
         * @name ItemDrawingsDetail
         * @summary Get a single Item Drawing by drawing number.
         * @request GET:/hardware/item-drawings/{drawingNumber}
         */
        itemDrawingsDetail: (drawingNumber, params = {}) => this.request({
            path: `/hardware/item-drawings/${drawingNumber}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description The following properties may create change sets when updated:<br><br>`request.Sizes`: `ITEM_DRAWING_VALID_SIZES_CHANGE` when Item Drawing has initialized Masters.
         *
         * @tags Hardware
         * @name ItemDrawingsUpdate
         * @summary Update an existing Item Drawing.
         * @request PUT:/hardware/item-drawings/{drawingNumber}
         */
        itemDrawingsUpdate: (drawingNumber, data, params = {}) => this.request({
            path: `/hardware/item-drawings/${drawingNumber}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Hardware
         * @name ItemMastersDetail
         * @summary Get a single Item Master by drawing number, as-built, and side.
         * @request GET:/hardware/item-masters/{drawingNumber}/{asBuiltNumber}/{side}
         */
        itemMastersDetail: (drawingNumber, asBuiltNumber, side, params = {}) => this.request({
            path: `/hardware/item-masters/${drawingNumber}/${asBuiltNumber}/${side}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description The following properties may create change sets when updated:<br><br>`request.IsApprovedAsDesign`: `ITEM_MASTER_APPROVAL_CHANGE` when Item Master initialization is open or approved.<br>`request.SubType`: `ITEM_MASTER_EQUIPMENT_GROUP_CHANGE` when Item Master initialization is open or approved.<br>`request.IsRefurbishable`: `ITEM_MASTER_REFURB_CHANGE` when Item Master initialization is open or approved.<br>`request.FederalSupplyClass`: `ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE` when Item Master initialization is open or approved.<br>`request.PropertyClass`: `ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE` when Item Master initialization is open or approved.<br>`request.InventoryMethod`: `ITEM_MASTER_INVENTORY_METHOD_CHANGE` when Item Master initialization is open or approved.<br>`request.LimitedLife`: `ITEM_MASTER_LIMITED_LIFE_CHANGE` when Item Master initialization is open or approved.
         *
         * @tags Hardware
         * @name ItemMastersUpdate
         * @summary Update an existing Item Master.
         * @request PUT:/hardware/item-masters/{drawingNumber}/{asBuiltNumber}/{side}
         */
        itemMastersUpdate: (drawingNumber, asBuiltNumber, side, data, params = {}) => this.request({
            path: `/hardware/item-masters/${drawingNumber}/${asBuiltNumber}/${side}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Hardware
         * @name ItemInstancesDetail
         * @summary Get a single Item Instance by ID.
         * @request GET:/hardware/item-instances/{id}
         */
        itemInstancesDetail: (id, params = {}) => this.request({
            path: `/hardware/item-instances/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description The following properties may create change sets when updated:<br><br>`request.AsBuiltNumber`: `ITEM_INSTANCE_CONFIGURATION_CHANGE`.<br>`request.Side`: `ITEM_INSTANCE_CONFIGURATION_CHANGE`.<br>`request.SerialNumber`: `ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE`.<br>`request.LotNumber`: `ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE`.<br>`request.AsDesignNumber`: `ITEM_INSTANCE_CONFIGURATION_CHANGE`.<br>`request.InheritLimitedLifeFromMaster`: `ITEM_INSTANCE_LIMITED_LIFE_CHANGE` when Item Master initialization is open or approved.<br>`request.LimitedLife`: `ITEM_INSTANCE_LIMITED_LIFE_CHANGE` when Item Master initialization is open or approved.
         *
         * @tags Hardware
         * @name ItemInstancesUpdate
         * @summary Update an existing Item Instance.
         * @request PUT:/hardware/item-instances/{id}
         */
        itemInstancesUpdate: (id, data, params = {}) => this.request({
            path: `/hardware/item-instances/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description The following properties may create change sets when updated:<br><br>`request.Sizes`: `ITEM_DRAWING_VALID_SIZES_CHANGE` when Item Drawing has initialized Masters.
         *
         * @tags Hardware
         * @name ItemDrawingsCreate
         * @summary Create a new Item Drawing.
         * @request POST:/hardware/item-drawings
         */
        itemDrawingsCreate: (data, params = {}) => this.request({
            path: `/hardware/item-drawings`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description See general hardware search for parameter documentation.
         *
         * @tags HardwareSearch
         * @name ItemDrawingsList
         * @summary Search item drawings.
         * @request GET:/hardware/item-drawings
         */
        itemDrawingsList: (query, params = {}) => this.request({
            path: `/hardware/item-drawings`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description If the Item Drawing does not exist yet, then createItemDrawing must be populated with the item drawing details to create. If the Item Drawing already exists then createItemDrawing must not be populated.<br><br>The following properties may create change sets when updated:<br><br>`request.IsApprovedAsDesign`: `ITEM_MASTER_APPROVAL_CHANGE` when Item Master initialization is open or approved.<br>`request.SubType`: `ITEM_MASTER_EQUIPMENT_GROUP_CHANGE` when Item Master initialization is open or approved.<br>`request.IsRefurbishable`: `ITEM_MASTER_REFURB_CHANGE` when Item Master initialization is open or approved.<br>`request.FederalSupplyClass`: `ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE` when Item Master initialization is open or approved.<br>`request.PropertyClass`: `ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE` when Item Master initialization is open or approved.<br>`request.InventoryMethod`: `ITEM_MASTER_INVENTORY_METHOD_CHANGE` when Item Master initialization is open or approved.<br>`request.LimitedLife`: `ITEM_MASTER_LIMITED_LIFE_CHANGE` when Item Master initialization is open or approved.
         *
         * @tags Hardware
         * @name ItemMastersCreate
         * @summary Create an uninitialized Item Master.
         * @request POST:/hardware/item-masters
         */
        itemMastersCreate: (data, params = {}) => this.request({
            path: `/hardware/item-masters`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description See general hardware search for parameter documentation.
         *
         * @tags HardwareSearch
         * @name ItemMastersList
         * @summary Search item masters.
         * @request GET:/hardware/item-masters
         */
        itemMastersList: (query, params = {}) => this.request({
            path: `/hardware/item-masters`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description createItemDrawing and createItemMaster must be populated if the item drawing and/or item master do not exist yet. The relevent property must not be populated if the item master and/or item drawing do already exist.<br><br>The following properties may create change sets when updated:<br><br>`request.AsBuiltNumber`: `ITEM_INSTANCE_CONFIGURATION_CHANGE`.<br>`request.Side`: `ITEM_INSTANCE_CONFIGURATION_CHANGE`.<br>`request.SerialNumber`: `ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE`.<br>`request.LotNumber`: `ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE`.<br>`request.AsDesignNumber`: `ITEM_INSTANCE_CONFIGURATION_CHANGE`.<br>`request.InheritLimitedLifeFromMaster`: `ITEM_INSTANCE_LIMITED_LIFE_CHANGE` when Item Master initialization is open or approved.<br>`request.LimitedLife`: `ITEM_INSTANCE_LIMITED_LIFE_CHANGE` when Item Master initialization is open or approved.
         *
         * @tags Hardware
         * @name ItemInstancesCreate
         * @summary Create an Item Instance.
         * @request POST:/hardware/item-instances
         */
        itemInstancesCreate: (data, params = {}) => this.request({
            path: `/hardware/item-instances`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description See general hardware search for parameter documentation.
         *
         * @tags HardwareSearch
         * @name ItemInstancesList
         * @summary Search item instances.
         * @request GET:/hardware/item-instances
         */
        itemInstancesList: (query, params = {}) => this.request({
            path: `/hardware/item-instances`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Hardware
         * @name ItemMastersInitializeCreate
         * @summary Create an Item Master initalization Change Set.
         * @request POST:/hardware/item-masters/{drawingNumber}/{asBuiltNumber}/{side}/initialize
         */
        itemMastersInitializeCreate: (drawingNumber, asBuiltNumber, side, data, params = {}) => this.request({
            path: `/hardware/item-masters/${drawingNumber}/${asBuiltNumber}/${side}/initialize`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Hardware
         * @name ItemDrawingsExistsDetail
         * @summary Checks if an item drawing exists
         * @request GET:/hardware/item-drawings/{drawingNumber}/exists
         */
        itemDrawingsExistsDetail: (drawingNumber, params = {}) => this.request({
            path: `/hardware/item-drawings/${drawingNumber}/exists`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Hardware
         * @name ItemMastersExistsDetail
         * @summary Checks if an item master exists.
         * @request GET:/hardware/item-masters/{drawingNumber}/{asBuiltNumber}/{side}/exists
         */
        itemMastersExistsDetail: (drawingNumber, asBuiltNumber, side, params = {}) => this.request({
            path: `/hardware/item-masters/${drawingNumber}/${asBuiltNumber}/${side}/exists`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags Hardware
     * @name HardwareCreate
     * @summary Check if item instances matching a specific master and serial/lot number already exist.
    Serial number checks for existing items with the same serial number regardless of the
    existing item's lot number. Lot number checks for existing items with the same lot
    number, but only if the existing item does not also have a serial number.
     * @request POST:/hardware
     */
        hardwareCreate: (data, params = {}) => this.request({
            path: `/hardware`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareSearch
         * @name HardwareList
         * @summary Search item instances, item masters, item drawings, and inventory.
         * @request GET:/hardware
         */
        hardwareList: (query, params = {}) => this.request({
            path: `/hardware`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareSearch
         * @name QuickSearchList
         * @summary Quick minimal query-based search API.
         * @request GET:/hardware/quick-search
         */
        quickSearchList: (query, params = {}) => this.request({
            path: `/hardware/quick-search`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description See general hardware search for parameter documentation.
         *
         * @tags HardwareSearch
         * @name InventoryList
         * @summary Search inventory.
         * @request GET:/hardware/inventory
         */
        inventoryList: (query, params = {}) => this.request({
            path: `/hardware/inventory`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        })
    };
    hardwareLists = {
        /**
         * No description
         *
         * @tags HardwareList
         * @name HardwareListsList
         * @summary Search HardwareLists
         * @request GET:/hardware-lists
         */
        hardwareListsList: (query, params = {}) => this.request({
            path: `/hardware-lists`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareList
         * @name HardwareListsCreate
         * @summary Create a new HardwareList.
         * @request POST:/hardware-lists
         */
        hardwareListsCreate: (data, params = {}) => this.request({
            path: `/hardware-lists`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareList
         * @name HardwareListsDetail
         * @summary Get a single HardwareList by id.
         * @request GET:/hardware-lists/{id}
         */
        hardwareListsDetail: (id, params = {}) => this.request({
            path: `/hardware-lists/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareList
         * @name HardwareListsUpdate
         * @summary Update a HardwareList.
         * @request PUT:/hardware-lists/{id}
         */
        hardwareListsUpdate: (id, data, params = {}) => this.request({
            path: `/hardware-lists/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareList
         * @name HardwareListsDelete
         * @summary Delete a HardwareList.
         * @request DELETE:/hardware-lists/{id}
         */
        hardwareListsDelete: ({ id, ...query }, params = {}) => this.request({
            path: `/hardware-lists/${id}`,
            method: 'DELETE',
            query: query,
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareList
         * @name PartsCreate
         * @summary Add an assembly to a hardware list.
         * @request POST:/hardware-lists/{id}/parts
         */
        partsCreate: (id, data, params = {}) => this.request({
            path: `/hardware-lists/${id}/parts`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareList
         * @name PartsUpdate
         * @summary Update a hardware list part.
         * @request PUT:/hardware-lists/{listId}/parts/{partId}
         */
        partsUpdate: (listId, partId, data, params = {}) => this.request({
            path: `/hardware-lists/${listId}/parts/${partId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareList
         * @name PartsDelete
         * @summary Delete a hardware list part.
         * @request DELETE:/hardware-lists/{listId}/parts/{partId}
         */
        partsDelete: ({ listId, partId, ...query }, params = {}) => this.request({
            path: `/hardware-lists/${listId}/parts/${partId}`,
            method: 'DELETE',
            query: query,
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareList
         * @name ExistsCreate
         * @summary Checks if a hardware list exists
         * @request POST:/hardware-lists/exists
         */
        existsCreate: (data, params = {}) => this.request({
            path: `/hardware-lists/exists`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    hardwareListTemplates = {
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name HardwareListTemplatesList
         * @summary Search hardware list templates.
         * @request GET:/hardware-list-templates
         */
        hardwareListTemplatesList: (query, params = {}) => this.request({
            path: `/hardware-list-templates`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name HardwareListTemplatesCreate
         * @summary Create a new HardwareListTemplate.
         * @request POST:/hardware-list-templates
         */
        hardwareListTemplatesCreate: (data, params = {}) => this.request({
            path: `/hardware-list-templates`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name HardwareListTemplatesDetail
         * @summary Get a single HardwareListTemplate by id.
         * @request GET:/hardware-list-templates/{templateId}
         */
        hardwareListTemplatesDetail: (templateId, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name HardwareListTemplatesUpdate
         * @summary Update a HardwareListTemplate.
         * @request PUT:/hardware-list-templates/{templateId}
         */
        hardwareListTemplatesUpdate: (templateId, data, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name HardwareListTemplatesDelete
         * @summary Delete a HardwareListTemplate.
         * @request DELETE:/hardware-list-templates/{templateId}
         */
        hardwareListTemplatesDelete: (templateId, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name SlotsDetail
         * @summary Gets a list of Hardware List Template Slots.
         * @request GET:/hardware-list-templates/{templateId}/slots
         */
        slotsDetail: (templateId, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}/slots`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name SlotsCreate
         * @summary Create a new HardwareListTemplateSlot.
         * @request POST:/hardware-list-templates/{templateId}/slots
         */
        slotsCreate: (templateId, data, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}/slots`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name SlotsDetail2
         * @summary Get a single HardwareListTemplateSlot by id.
         * @request GET:/hardware-list-templates/{templateId}/slots/{slotId}
         * @originalName slotsDetail
         * @duplicate
         */
        slotsDetail2: (templateId, slotId, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}/slots/${slotId}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name SlotsUpdate
         * @summary Update a HardwareListTemplateSlot.
         * @request PUT:/hardware-list-templates/{templateId}/slots/{slotId}
         */
        slotsUpdate: (templateId, slotId, data, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}/slots/${slotId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name SlotsDelete
         * @summary Delete a HardwareListTemplateSlot.
         * @request DELETE:/hardware-list-templates/{templateId}/slots/{slotId}
         */
        slotsDelete: (templateId, slotId, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}/slots/${slotId}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name SlotsPartsCreate
         * @summary Create a new HardwareListTemplatePart.
         * @request POST:/hardware-list-templates/{templateId}/slots/{slotId}/parts
         */
        slotsPartsCreate: (templateId, slotId, data, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}/slots/${slotId}/parts`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name SlotsPartsUpdate
         * @summary Update a HardwareListTemplatePart.
         * @request PUT:/hardware-list-templates/{templateId}/slots/{slotId}/parts/{partId}
         */
        slotsPartsUpdate: (templateId, slotId, partId, data, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}/slots/${slotId}/parts/${partId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags HardwareListTemplate
         * @name SlotsPartsDelete
         * @summary Delete a HardwareListTemplatePart.
         * @request DELETE:/hardware-list-templates/{templateId}/slots/{slotId}/parts/{partId}
         */
        slotsPartsDelete: (templateId, slotId, partId, params = {}) => this.request({
            path: `/hardware-list-templates/${templateId}/slots/${slotId}/parts/${partId}`,
            method: 'DELETE',
            ...params
        })
    };
    inventory = {
        /**
         * No description
         *
         * @tags Inventory
         * @name InventoryDetail
         * @summary Get a single inventory by ID.
         * @request GET:/inventory/{id}
         */
        inventoryDetail: (id, params = {}) => this.request({
            path: `/inventory/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description Changing expected return details to or from another support context will fail. That can only be done when doing cross-context issues.
         *
         * @tags Inventory
         * @name InventoryUpdate
         * @summary Update an inventory.
         * @request PUT:/inventory/{id}
         */
        inventoryUpdate: (id, data, params = {}) => this.request({
            path: `/inventory/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Inventory
         * @name InitialReceiptCreate
         * @summary Create new inventories with InInventory status at a provided internal location.
         * @request POST:/inventory/initial-receipt
         */
        initialReceiptCreate: (data, params = {}) => this.request({
            path: `/inventory/initial-receipt`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Inventory
         * @name IssuedCreate
         * @summary Create new inventories as issued to the provided non-context organization.
         * @request POST:/inventory/issued
         */
        issuedCreate: (data, params = {}) => this.request({
            path: `/inventory/issued`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags InventoryReport
         * @name ReportProjectCodesList
         * @summary Show filtered inventory counts by project code.
         * @request GET:/inventory/report/project-codes
         */
        reportProjectCodesList: (query, params = {}) => this.request({
            path: `/inventory/report/project-codes`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags InventoryReport
         * @name ReportIssueDueBacksList
         * @summary Returns inventory issue due back data.
         * @request GET:/inventory/report/issue-due-backs
         */
        reportIssueDueBacksList: (query, params = {}) => this.request({
            path: `/inventory/report/issue-due-backs`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        })
    };
    inventoryQuota = {
        /**
         * No description
         *
         * @tags InventoryQuota
         * @name InventoryQuotaCreate
         * @summary Create or update an inventory quota for a given Item Master and Inventory Class.
         * @request POST:/inventory-quota
         */
        inventoryQuotaCreate: (data, params = {}) => this.request({
            path: `/inventory-quota`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags InventoryQuota
         * @name InventoryQuotaList
         * @summary Get inventory quotas.
         * @request GET:/inventory-quota
         */
        inventoryQuotaList: (query, params = {}) => this.request({
            path: `/inventory-quota`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags InventoryQuota
         * @name InventoryQuotaDetail
         * @summary Get a single inventory quota by ID.
         * @request GET:/inventory-quota/{id}
         */
        inventoryQuotaDetail: (id, params = {}) => this.request({
            path: `/inventory-quota/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags InventoryQuota
         * @name InventoryQuotaDelete
         * @summary Delete an inventory quota.
         * @request DELETE:/inventory-quota/{id}
         */
        inventoryQuotaDelete: (id, params = {}) => this.request({
            path: `/inventory-quota/${id}`,
            method: 'DELETE',
            ...params
        })
    };
    links = {
        /**
         * No description
         *
         * @tags Link
         * @name LinksCreate
         * @summary Create a link.
         * @request POST:/links
         */
        linksCreate: (query, data, params = {}) => this.request({
            path: `/links`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Link
         * @name LinksList
         * @summary Get links.
         * @request GET:/links
         */
        linksList: (query, params = {}) => this.request({
            path: `/links`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Link
         * @name LinksUpdate
         * @summary Update an existing link.
         * @request PUT:/links/{id}
         */
        linksUpdate: (id, data, params = {}) => this.request({
            path: `/links/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Link
         * @name LinksDelete
         * @summary Delete a link.
         * @request DELETE:/links/{id}
         */
        linksDelete: (id, params = {}) => this.request({
            path: `/links/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags Link
         * @name LinksDetail
         * @summary Get a single Link by ID.
         * @request GET:/links/{id}
         */
        linksDetail: (id, params = {}) => this.request({
            path: `/links/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Link
         * @name ItemInstancesDetail
         * @summary Get a collection of Hardware Item Instance links by Item Instance ID.
         * @request GET:/links/item-instances/{id}
         */
        itemInstancesDetail: (id, sort, skip, take, params = {}) => this.request({
            path: `/links/item-instances/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Link
         * @name ItemMastersDetail
         * @summary Get a collection of Hardware Item Master links by Drawing Number, AsBuilt Number and Side.
         * @request GET:/links/item-masters/{drawingNumber}/{asBuiltNumber}/{side}
         */
        itemMastersDetail: (drawingNumber, asBuiltNumber, side, sort, skip, take, params = {}) => this.request({
            path: `/links/item-masters/${drawingNumber}/${asBuiltNumber}/${side}`,
            method: 'GET',
            format: 'json',
            ...params
        })
    };
    locations = {
        /**
         * No description
         *
         * @tags Location
         * @name LocationsCreate
         * @summary Create an internally managed location.
         * @request POST:/locations
         */
        locationsCreate: (data, params = {}) => this.request({
            path: `/locations`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description External organization locations (GENERAL or IN_TRANSIT) will only be returned if they have inventory at them. Empty external locations should never be referenced directly.
         *
         * @tags Location
         * @name LocationsList
         * @summary Get all locations.
         * @request GET:/locations
         */
        locationsList: (query, params = {}) => this.request({
            path: `/locations`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description External locations cannot change building, stock, bin, or contract number. Also, changing building or contract number is not allowed for locations with inventory.
         *
         * @tags Location
         * @name LocationsUpdate
         * @summary Update a location.
         * @request PUT:/locations/{id}
         */
        locationsUpdate: (id, data, params = {}) => this.request({
            path: `/locations/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Location
         * @name LocationsDetail
         * @summary Get a single location by id.
         * @request GET:/locations/{id}
         */
        locationsDetail: (id, params = {}) => this.request({
            path: `/locations/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description This will fail with a 400/Bad Request if the location cannot be deleted because it is being referenced (by inventory or transactions, for example).
         *
         * @tags Location
         * @name LocationsDelete
         * @summary Delete a location if possible.
         * @request DELETE:/locations/{id}
         */
        locationsDelete: (id, params = {}) => this.request({
            path: `/locations/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags Location
         * @name AutocompleteList
         * @summary Return autocomplete lists based on provided filters.
         * @request GET:/locations/autocomplete
         */
        autocompleteList: (query, params = {}) => this.request({
            path: `/locations/autocomplete`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        })
    };
    logistics = {
        /**
         * No description
         *
         * @tags LogisticsReport
         * @name AllocationMatrixList
         * @request GET:/logistics/allocation-matrix
         */
        allocationMatrixList: (params = {}) => this.request({
            path: `/logistics/allocation-matrix`,
            method: 'GET',
            format: 'json',
            ...params
        })
    };
    organizations = {
        /**
         * No description
         *
         * @tags Organization
         * @name OrganizationsCreate
         * @summary Create a cross-context organization.
         * @request POST:/organizations
         */
        organizationsCreate: (data, params = {}) => this.request({
            path: `/organizations`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Organization
         * @name OrganizationsList
         * @summary Search organizations.
         * @request GET:/organizations
         */
        organizationsList: (query, params = {}) => this.request({
            path: `/organizations`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Organization
         * @name OrganizationsUpdate
         * @summary Update a cross-context organization.
         * @request PUT:/organizations/{code}
         */
        organizationsUpdate: (code, data, params = {}) => this.request({
            path: `/organizations/${code}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Organization
         * @name OrganizationsDetail
         * @summary Get a single organization by code.
         * @request GET:/organizations/{code}
         */
        organizationsDetail: (code, params = {}) => this.request({
            path: `/organizations/${code}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description If the organization is in use and cannot be deleted this will fail with 400/Bad Request.
         *
         * @tags Organization
         * @name OrganizationsDelete
         * @summary Delete an organization if possible.
         * @request DELETE:/organizations/{code}
         */
        organizationsDelete: (code, params = {}) => this.request({
            path: `/organizations/${code}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * @description Requires Admin role. Does not seed any additional data (users, groups, etc.)
         *
         * @tags Organization
         * @name SupportContextsCreate
         * @summary Create a new support context organization.
         * @request POST:/organizations/support-contexts
         * @secure
         */
        supportContextsCreate: (data, params = {}) => this.request({
            path: `/organizations/support-contexts`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Requires Admin role.
         *
         * @tags Organization
         * @name SupportContextsUpdate
         * @summary Update a support context organization.
         * @request PUT:/organizations/support-contexts/{code}
         * @secure
         */
        supportContextsUpdate: (code, data, params = {}) => this.request({
            path: `/organizations/support-contexts/${code}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Will fail if any data has been added to the support context. Requires Admin role.
         *
         * @tags Organization
         * @name SupportContextsDelete
         * @summary Delet a support context organization, if possible.
         * @request DELETE:/organizations/support-contexts/{code}
         * @secure
         */
        supportContextsDelete: (code, params = {}) => this.request({
            path: `/organizations/support-contexts/${code}`,
            method: 'DELETE',
            secure: true,
            ...params
        }),
        /**
         * No description
         *
         * @tags Organization
         * @name ExistsDetail
         * @summary Checks if a organization exists
         * @request GET:/organizations/{code}/exists
         */
        existsDetail: (code, params = {}) => this.request({
            path: `/organizations/${code}/exists`,
            method: 'GET',
            format: 'json',
            ...params
        })
    };
    outages = {
        /**
         * No description
         *
         * @tags Outage
         * @name OutagesCreate
         * @summary Create an outage.
         * @request POST:/outages
         * @secure
         */
        outagesCreate: (data, params = {}) => this.request({
            path: `/outages`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Outage
         * @name OutagesList
         * @summary Get a list of outages.
         * @request GET:/outages
         */
        outagesList: (query, params = {}) => this.request({
            path: `/outages`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Outage
         * @name OutagesUpdate
         * @summary Update an existing outage by ID.
         * @request PUT:/outages/{id}
         * @secure
         */
        outagesUpdate: (id, data, params = {}) => this.request({
            path: `/outages/${id}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Outage
         * @name OutagesDelete
         * @summary Delete an outage by ID.
         * @request DELETE:/outages/{id}
         * @secure
         */
        outagesDelete: (id, params = {}) => this.request({
            path: `/outages/${id}`,
            method: 'DELETE',
            secure: true,
            ...params
        }),
        /**
         * No description
         *
         * @tags Outage
         * @name OutagesDetail
         * @summary Get a single outage by ID.
         * @request GET:/outages/{id}
         */
        outagesDetail: (id, params = {}) => this.request({
            path: `/outages/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        })
    };
    partReferences = {
        /**
         * No description
         *
         * @tags PartReference
         * @name PartReferencesList
         * @summary Search part references.
         * @request GET:/part-references
         */
        partReferencesList: (query, params = {}) => this.request({
            path: `/part-references`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags PartReference
         * @name PartReferencesCreate
         * @summary Create a non-actualized Part Reference.
         * @request POST:/part-references
         */
        partReferencesCreate: (data, params = {}) => this.request({
            path: `/part-references`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags PartReference
         * @name PartReferencesDetail
         * @summary Get a single Part Reference by ID.
         * @request GET:/part-references/{id}
         */
        partReferencesDetail: (id, params = {}) => this.request({
            path: `/part-references/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description This will fail with an error if the specified part reference points to an actualized item instance. For actualized hardware the item instance itself should be updated.
         *
         * @tags PartReference
         * @name PartReferencesUpdate
         * @summary Update a non-actualized item instance Part Reference.
         * @request PUT:/part-references/{id}
         */
        partReferencesUpdate: (id, data, params = {}) => this.request({
            path: `/part-references/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This will fail if the part reference is referenced by any other entities. This will also fail if the specified part reference points to an actualized item instance.
         *
         * @tags PartReference
         * @name PartReferencesDelete
         * @summary Delete a non-actualized item instance Part Reference.
         * @request DELETE:/part-references/{id}
         */
        partReferencesDelete: (id, params = {}) => this.request({
            path: `/part-references/${id}`,
            method: 'DELETE',
            ...params
        })
    };
    physicalInventoryAudits = {
        /**
         * No description
         *
         * @tags PhysicalInventoryAudit
         * @name PhysicalInventoryAuditsList
         * @summary Search for PhysicalInventoryAudits
         * @request GET:/physical-inventory-audits
         */
        physicalInventoryAuditsList: (query, params = {}) => this.request({
            path: `/physical-inventory-audits`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags PhysicalInventoryAudit
         * @name PhysicalInventoryAuditsCreate
         * @summary Create a physical inventory audit.
         * @request POST:/physical-inventory-audits
         */
        physicalInventoryAuditsCreate: (data, params = {}) => this.request({
            path: `/physical-inventory-audits`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags PhysicalInventoryAudit
         * @name ItemsUpdate
         * @summary Update a physical inventory audit item
         * @request PUT:/physical-inventory-audits/{auditId}/items/{itemId}
         */
        itemsUpdate: (auditId, itemId, data, params = {}) => this.request({
            path: `/physical-inventory-audits/${auditId}/items/${itemId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    risks = {
        /**
         * No description
         *
         * @tags Risk
         * @name RisksCreate
         * @summary Create a new Risk.
         * @request POST:/risks
         */
        risksCreate: (query, data, params = {}) => this.request({
            path: `/risks`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Risk
         * @name RisksList
         * @summary Search risks.
         * @request GET:/risks
         */
        risksList: (query, params = {}) => this.request({
            path: `/risks`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description Automatically creates a new comment and change log.
         *
         * @tags Risk
         * @name RisksUpdate
         * @summary Update a Risk.
         * @request PUT:/risks/{id}
         */
        risksUpdate: ({ id, ...query }, data, params = {}) => this.request({
            path: `/risks/${id}`,
            method: 'PUT',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description This will error if the risk already has mitigations or comments associated with it.
         *
         * @tags Risk
         * @name RisksDelete
         * @summary Delete a risk.
         * @request DELETE:/risks/{id}
         */
        risksDelete: (id, params = {}) => this.request({
            path: `/risks/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags Risk
         * @name RisksDetail
         * @summary Get a single risk by ID.
         * @request GET:/risks/{id}
         */
        risksDetail: ({ id, ...query }, params = {}) => this.request({
            path: `/risks/${id}`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description Using an existing sequence will re-sequence existing mitigations appropriately.
         *
         * @tags Risk
         * @name MitigationsCreate
         * @summary Create a new mitigation for a risk.
         * @request POST:/risks/{id}/mitigations
         */
        mitigationsCreate: ({ id, ...query }, data, params = {}) => this.request({
            path: `/risks/${id}/mitigations`,
            method: 'POST',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Updating to an existing sequence will re-sequence all other mitigations appropriately.
         *
         * @tags Risk
         * @name MitigationsUpdate
         * @summary Update an existing risk mitigation.
         * @request PUT:/risks/{id}/mitigations/{mitigationId}
         */
        mitigationsUpdate: ({ id, mitigationId, ...query }, data, params = {}) => this.request({
            path: `/risks/${id}/mitigations/${mitigationId}`,
            method: 'PUT',
            query: query,
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description If the mitigation was ever updated in a way that affected the risk, such as modifying likelihood, consequence, or status, then this will fail.
         *
         * @tags Risk
         * @name MitigationDelete
         * @summary Delete a risk mitigation.
         * @request DELETE:/risks/{id}/mitigation/{mitigationId}
         */
        mitigationDelete: (id, mitigationId, params = {}) => this.request({
            path: `/risks/${id}/mitigation/${mitigationId}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * @description All stakeholders will be replaced by the new list of contacts.
         *
         * @tags Risk
         * @name StakeholdersUpdate
         * @summary Set the stakeholder contacts for a Risk.
         * @request PUT:/risks/{id}/stakeholders
         */
        stakeholdersUpdate: (id, data, params = {}) => this.request({
            path: `/risks/${id}/stakeholders`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags RiskReport
         * @name ReportTopScoredList
         * @summary Return top risks by score with aggregate historical data.
         * @request GET:/risks/report/top-scored
         */
        reportTopScoredList: (query, params = {}) => this.request({
            path: `/risks/report/top-scored`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        })
    };
    telemetry = {
        /**
         * No description
         *
         * @tags Telemetry
         * @name TelemetryCreate
         * @summary Create a log entry.
         * @request POST:/telemetry
         */
        telemetryCreate: (data, params = {}) => this.request({
            path: `/telemetry`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Requires the Admin role.
         *
         * @tags Telemetry
         * @name TelemetryList
         * @summary Get error logs sorted by created date.
         * @request GET:/telemetry
         * @secure
         */
        telemetryList: (query, params = {}) => this.request({
            path: `/telemetry`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Telemetry
         * @name AccessLogList
         * @summary Get access logs for a specific url.
         * @request GET:/telemetry/access-log
         */
        accessLogList: (query, params = {}) => this.request({
            path: `/telemetry/access-log`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description Requires the Admin role.
         *
         * @tags Telemetry
         * @name ReportList
         * @summary Telemetry aggregates report.
         * @request GET:/telemetry/report
         * @secure
         */
        reportList: (query, params = {}) => this.request({
            path: `/telemetry/report`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    };
    transactions = {
        /**
         * No description
         *
         * @tags Transaction
         * @name TransactionsList
         * @summary Get transactions.
         * @request GET:/transactions
         */
        transactionsList: (query, params = {}) => this.request({
            path: `/transactions`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description Does not
         *
         * @tags Transaction
         * @name ReportProjectCodesList
         * @summary Show filtered transaction counts by project code.
         * @request GET:/transactions/report/project-codes
         */
        reportProjectCodesList: (query, params = {}) => this.request({
            path: `/transactions/report/project-codes`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags Transaction
     * @name ReportBuildingInventoryClassList
     * @summary Summary of inventory costs grouped by object class that moved in and out of each building.
    Also referred to as the 1489 report.
     * @request GET:/transactions/report/building-inventory-class
     */
        reportBuildingInventoryClassList: (query, params = {}) => this.request({
            path: `/transactions/report/building-inventory-class`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags Transaction
     * @name ReportBuildingItemInstancesList
     * @summary Count of distinct item instances that moved in and out of each building.
    Also referred to as the 1324 report.
     * @request GET:/transactions/report/building-item-instances
     */
        reportBuildingItemInstancesList: (query, params = {}) => this.request({
            path: `/transactions/report/building-item-instances`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags Transaction
     * @name ReportNoActivityList
     * @summary Count of distinct item instances and total inventory value in each building that
    was not issued or received during the report period.
     * @request GET:/transactions/report/no-activity
     */
        reportNoActivityList: (query, params = {}) => this.request({
            path: `/transactions/report/no-activity`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Transaction
         * @name ReportItemInstanceSnapshotList
         * @summary Get per-building snapshots of item instance quantities and total value at a specific date.
         * @request GET:/transactions/report/item-instance-snapshot
         */
        reportItemInstanceSnapshotList: (query, params = {}) => this.request({
            path: `/transactions/report/item-instance-snapshot`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        })
    };
    users = {
        /**
         * @description The user groups returned reflect the user's current context only.
         *
         * @tags User
         * @name CurrentList
         * @summary Get details about the currently authenticated user.
         * @request GET:/users/current
         */
        currentList: (params = {}) => this.request({
            path: `/users/current`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description Update the current user's attributes.
         *
         * @tags User
         * @name CurrentAttributesUpdate
         * @request PUT:/users/current/attributes
         */
        currentAttributesUpdate: (data, params = {}) => this.request({
            path: `/users/current/attributes`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags User
         * @name UsersList
         * @summary Search users, returning full support context and group membership details.
         * @request GET:/users
         */
        usersList: (query, params = {}) => this.request({
            path: `/users`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * @description The new user will be created with the User role and added to the current context. Changing the role or adding more contexts should be done through the appropriate APIs.
         *
         * @tags User
         * @name UsersCreate
         * @summary Create a new user and associated contact.
         * @request POST:/users
         */
        usersCreate: (data, params = {}) => this.request({
            path: `/users`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags User
         * @name UsersDetail
         * @summary Get a single user by id.
         * @request GET:/users/{id}
         */
        usersDetail: (id, params = {}) => this.request({
            path: `/users/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description This will fail with a 403/Bad Request if the user (or contact when also deleting contact details) has been associated with other entities in the database.
         *
         * @tags User
         * @name UsersDelete
         * @summary Delete a user.
         * @request DELETE:/users/{id}
         */
        usersDelete: ({ id, ...query }, params = {}) => this.request({
            path: `/users/${id}`,
            method: 'DELETE',
            query: query,
            ...params
        }),
        /**
         * @description Can only be called by a UserRole.Admin user.
         *
         * @tags User
         * @name RoleUpdate
         * @summary Set a user's role.
         * @request PUT:/users/{id}/role
         * @secure
         */
        roleUpdate: (id, data, params = {}) => this.request({
            path: `/users/${id}/role`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Can only be called by a UserRole.Admin user.
         *
         * @tags User
         * @name ContextRolesUpdate
         * @summary Add context-level roles to a user for the current context.
         * @request PUT:/users/{id}/context-roles
         * @secure
         */
        contextRolesUpdate: (id, data, params = {}) => this.request({
            path: `/users/${id}/context-roles`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Can only be called by a UserRole.Admin user. This will remove a user from any contexts not specified in the request, update permissions for any contexts the user is already a member of, and add the user to any specified contexts that the user isn't a member of.
         *
         * @tags User
         * @name SupportContextsUpdate
         * @summary Manage all of a user's contexts at once.
         * @request PUT:/users/{id}/support-contexts
         * @secure
         */
        supportContextsUpdate: (id, data, params = {}) => this.request({
            path: `/users/${id}/support-contexts`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * @description Can only be called by a UserRole.Admin user.
         *
         * @tags User
         * @name ContextRolesDelete
         * @summary Remove a context-level role from a user for the current context.
         * @request DELETE:/users/{id}/context-roles/{role}
         * @secure
         */
        contextRolesDelete: (id, role, params = {}) => this.request({
            path: `/users/${id}/context-roles/${role}`,
            method: 'DELETE',
            secure: true,
            format: 'json',
            ...params
        }),
        /**
     * No description
     *
     * @tags User
     * @name SupportContextUpdate
     * @summary Add a new user to support the current context, or update a user that already supports
    the current context with the provided details.
     * @request PUT:/users/{id}/support-context
     */
        supportContextUpdate: (id, data, params = {}) => this.request({
            path: `/users/${id}/support-context`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
     * @description If the user is ever added back to the current context, their existing delegate group will be re-used.
     *
     * @tags User
     * @name SupportContextDelete
     * @summary Remove a user from supporting the current context. This will not delete their delegate
    group, but will prevent the user from calling any APIs that operate on this context.
     * @request DELETE:/users/{id}/support-context
     */
        supportContextDelete: (id, params = {}) => this.request({
            path: `/users/${id}/support-context`,
            method: 'DELETE',
            format: 'json',
            ...params
        }),
        /**
         * @description Roles are categorized into domains. A given role within a domain inherits all roles in that same domain with a lower weight. Weights may change if new roles are introduced.
         *
         * @tags User
         * @name RoleListList
         * @summary Get a list of all context-specific roles available to a user.
         * @request GET:/users/role-list
         */
        roleListList: (params = {}) => this.request({
            path: `/users/role-list`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * @description The new user will be created with the User role and added to the current context. Changing the role or adding more contexts should be done through the appropriate APIs.
         *
         * @tags User
         * @name FromContactCreate
         * @summary Create a new user from an existing contact.
         * @request POST:/users/from-contact
         */
        fromContactCreate: (data, params = {}) => this.request({
            path: `/users/from-contact`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    userGroups = {
        /**
         * No description
         *
         * @tags UserGroup
         * @name UserGroupsList
         * @summary Search user groups.
         * @request GET:/user-groups
         */
        userGroupsList: (query, params = {}) => this.request({
            path: `/user-groups`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags UserGroup
         * @name UserGroupsCreate
         * @summary Create a new user-managed group.
         * @request POST:/user-groups
         */
        userGroupsCreate: (data, params = {}) => this.request({
            path: `/user-groups`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags UserGroup
         * @name UserGroupsUpdate
         * @summary Update a user group.
         * @request PUT:/user-groups/{code}
         */
        userGroupsUpdate: (code, data, params = {}) => this.request({
            path: `/user-groups/${code}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags UserGroup
         * @name UserGroupsDetail
         * @summary Get a user group by code.
         * @request GET:/user-groups/{code}
         */
        userGroupsDetail: (code, params = {}) => this.request({
            path: `/user-groups/${code}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags UserGroup
         * @name UserGroupsDelete
         * @summary Delete a user group.
         * @request DELETE:/user-groups/{code}
         */
        userGroupsDelete: (code, params = {}) => this.request({
            path: `/user-groups/${code}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags UserGroup
         * @name DelegateDetail
         * @summary Get a user's delegate group in the current context.
         * @request GET:/user-groups/delegate/{userId}
         */
        delegateDetail: (userId, params = {}) => this.request({
            path: `/user-groups/delegate/${userId}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags UserGroup
         * @name UsersUpdate
         * @summary Add users to a user group.
         * @request PUT:/user-groups/{code}/users
         */
        usersUpdate: (code, data, params = {}) => this.request({
            path: `/user-groups/${code}/users`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags UserGroup
         * @name UsersDelete
         * @summary Remove a user from a user group.
         * @request DELETE:/user-groups/{code}/users/{userId}
         */
        usersDelete: (code, userId, params = {}) => this.request({
            path: `/user-groups/${code}/users/${userId}`,
            method: 'DELETE',
            format: 'json',
            ...params
        })
    };
    vehicles = {
        /**
         * No description
         *
         * @tags Vehicle
         * @name VehiclesList
         * @summary Search Vehicles
         * @request GET:/vehicles
         */
        vehiclesList: (query, params = {}) => this.request({
            path: `/vehicles`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Vehicle
         * @name VehiclesCreate
         * @summary Create a new vehicle.
         * @request POST:/vehicles
         */
        vehiclesCreate: (data, params = {}) => this.request({
            path: `/vehicles`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Vehicle
         * @name VehiclesDetail
         * @summary Get a vehicle by id.
         * @request GET:/vehicles/{id}
         */
        vehiclesDetail: (id, params = {}) => this.request({
            path: `/vehicles/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags Vehicle
         * @name VehiclesDelete
         * @summary Delete a vehicle.
         * @request DELETE:/vehicles/{id}
         */
        vehiclesDelete: (id, params = {}) => this.request({
            path: `/vehicles/${id}`,
            method: 'DELETE',
            ...params
        }),
        /**
         * No description
         *
         * @tags Vehicle
         * @name VehiclesUpdate
         * @summary Update vehicle details.
         * @request PUT:/vehicles/{id}
         */
        vehiclesUpdate: (id, data, params = {}) => this.request({
            path: `/vehicles/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    };
    workOrderSteps = {
        /**
         * No description
         *
         * @tags WorkOrderStep
         * @name WorkOrderStepsCreate
         * @summary Create a new Work Order Step.
         * @request POST:/work-order-steps
         */
        workOrderStepsCreate: (data, params = {}) => this.request({
            path: `/work-order-steps`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags WorkOrderStep
         * @name WorkOrderStepsList
         * @summary Search Work Order Steps.
         * @request GET:/work-order-steps
         */
        workOrderStepsList: (query, params = {}) => this.request({
            path: `/work-order-steps`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags WorkOrderStep
         * @name WorkOrderStepsUpdate
         * @summary Update a Work Order Step.
         * @request PUT:/work-order-steps/{id}
         */
        workOrderStepsUpdate: (id, data, params = {}) => this.request({
            path: `/work-order-steps/${id}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            format: 'json',
            ...params
        }),
        /**
         * No description
         *
         * @tags WorkOrderStep
         * @name WorkOrderStepsDetail
         * @summary Get a single WorkOrderStep by ID.
         * @request GET:/work-order-steps/{id}
         */
        workOrderStepsDetail: (id, params = {}) => this.request({
            path: `/work-order-steps/${id}`,
            method: 'GET',
            format: 'json',
            ...params
        })
    };
}
