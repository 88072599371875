var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Side } from '~/db_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
/**
 * This component either shows an AHD passing the nodeId directly to the AHD
 */
let ModalAppHardwareDisplay = class ModalAppHardwareDisplay extends Mixins(BaseModal, BaseCosmic) {
    asBuiltNumber;
    drawingNumber;
    id; // ItemInstanceId
    inventoryId;
    nodeId;
    side;
};
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalAppHardwareDisplay.prototype, "asBuiltNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalAppHardwareDisplay.prototype, "drawingNumber", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ModalAppHardwareDisplay.prototype, "id", void 0);
__decorate([
    Prop({
        type: String,
        required: false
    }),
    __metadata("design:type", String)
], ModalAppHardwareDisplay.prototype, "inventoryId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", Object)
], ModalAppHardwareDisplay.prototype, "nodeId", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", typeof (_a = typeof Side !== "undefined" && Side) === "function" ? _a : Object)
], ModalAppHardwareDisplay.prototype, "side", void 0);
ModalAppHardwareDisplay = __decorate([
    Component
], ModalAppHardwareDisplay);
export default ModalAppHardwareDisplay;
