import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { AssemblyTemplateTypeDisplay, SideDisplay } from '~/nasa_ui/types';
import { addDateDisplays, addNameDisplay, addPbsItemIdDisplay, addSubTypeDisplay, addUserDisplays } from '../displayMappers';
import { numberDisplay } from '../displays';
import { transformCosmicEventResponses } from './events';
import { transformPartReferenceResponses } from './hardware';
export var MegaAllocationType;
(function (MegaAllocationType) {
    MegaAllocationType["GROUND"] = "GROUND";
    MegaAllocationType["ON_ORBIT"] = "ON_ORBIT";
    MegaAllocationType["TRADE_STUDY"] = "TRADE_STUDY";
})(MegaAllocationType || (MegaAllocationType = {}));
export const MegaAllocationTypeDisplay = new Map([
    [MegaAllocationType.GROUND, 'Ground'],
    [MegaAllocationType.ON_ORBIT, 'On Orbit'],
    [MegaAllocationType.TRADE_STUDY, 'Trade study']
]);
// Old transform
export const transformAssemblyTemplates = (items) => {
    return items
        .map(addDateDisplays)
        .map(addSubTypeDisplay(AssemblyTemplateTypeDisplay))
        .map(addUserDisplays)
        .map(addNameDisplay)
        .map(addPbsItemIdDisplay)
        .map((at) => {
        const _at = { ...at };
        _at._autoHardwareListName = _at.autoHardwareListName || DEFAULT_DASH;
        _at._autoHardwareListDescription = _at.autoHardwareListDescription || DEFAULT_DASH;
        _at._documentNumber = _at.documentNumber || DEFAULT_DASH;
        _at._installedOn = _at.installedOn ? SideDisplay.get(_at.installedOn) : DEFAULT_DASH;
        _at._sequence = _at.sequence.toString();
        _at._maxQuantity = numberDisplay(_at.maxQuantity);
        _at._minQuantity = numberDisplay(_at.minQuantity);
        _at._waiverNumber = _at.waiverNumber || DEFAULT_DASH;
        return _at;
    });
};
export const transformAssemblyTemplateResponses = (items) => {
    return transformAssemblyTemplates(items);
};
export const transformAssemblyTemplateResponseWithDepths = (items) => {
    return transformAssemblyTemplates(items).map((item) => {
        const _item = { ...item };
        _item._depth = numberDisplay(_item.depth);
        _item._parts =
            _item.parts?.map((part) => ({
                ...part,
                _partReference: transformPartReferenceResponses([part.partReference])[0]
            })) ?? [];
        return _item;
    });
};
export const transformAssemblyTemplateResponseWithParts = (items) => {
    return transformAssemblyTemplates(items).map((item) => {
        const _item = { ...item };
        _item._parts =
            _item.parts?.map((part) => ({
                ...part,
                _partReference: transformPartReferenceResponses([part.partReference])[0]
            })) ?? [];
        return _item;
    });
};
export const transformAssemblyTemplateResponseWithChildrens = (items) => {
    return transformAssemblyTemplates(items).map((item) => {
        const _item = { ...item };
        _item._childrenCount = numberDisplay((_item.children || []).length);
        _item._children = _item.children ? transformAssemblyTemplateResponseWithDepths(_item.children) : [];
        _item._cosmicEvents = _item.cosmicEvents ? transformCosmicEventResponses(_item.cosmicEvents) : [];
        _item._megaAllocationType = _item.attributes?.megaAllocationType
            ? MegaAllocationTypeDisplay.get(_item.attributes.megaAllocationType) || DEFAULT_DASH
            : DEFAULT_DASH;
        _item._waiverNumber = _item.waiverNumber || DEFAULT_DASH;
        return _item;
    });
};
