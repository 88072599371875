import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import BaseCosmic from './BaseCosmic';
let BaseView = class BaseView extends Mixins(BaseCosmic) {
    isFetching = false;
    isLoading = false;
    isSaving = false;
    manageUrlBasePath = null;
    mounted() {
        this.scrollToTop();
    }
    get browserZoomLevel() {
        return window.devicePixelRatio;
    }
    get isBrowserZoomedIn() {
        return this.browserZoomLevel !== 1;
    }
    // navigates from search to manage page in the current window
    onClickOfSearchResultUsingId(result) {
        if (!result) {
            return;
        }
        this.$router.push(`${this.manageUrlBasePath}/manage/${result.id}#information`);
    }
    // opens the search result in a new tab
    onMetaClickOfSearchResult(result) {
        if (!result || !this.manageUrlBasePath) {
            return;
        }
        window.open(`${this.manageUrlBasePath}/manage/${result.nodeId}#information`, result.nodeId);
    }
    // opens the search result in a new tab
    onMetaClickOfSearchResultUsingId(result) {
        if (!result || !this.manageUrlBasePath) {
            return;
        }
        window.open(`${this.manageUrlBasePath}/manage/${result.id}#information`, result.id);
    }
    scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
};
BaseView = __decorate([
    Component
], BaseView);
export default BaseView;
